<div class="container-fluid" style="padding-top: 5px;">
    <div class="row">
        <div class="col-sm-12">
            <div style="float: right;">
                <button mat-raised-button class="pad-btn" [matMenuTriggerFor]="menu">
                    <mat-icon>more_vert</mat-icon>
                </button>
                <mat-menu #menu="matMenu">
                    <button *ngIf="canAdd" mat-menu-item (click)="add()" title="Add Template">
                        <mat-icon aria-hidden="false" aria-label="Add Template">add</mat-icon>
                        <span>Add Template</span>
                    </button>
                    <button *ngIf="canAdd" mat-menu-item id="template_import_btn" title="Upload Template" (click)="import()">
                        <mat-icon aria-hidden="false" aria-label="Upload Template">file_upload</mat-icon>
                        <span>Upload Template</span>
                    </button>
                    <button mat-menu-item title="Export as CSV" (click)="exportAsCSV('secondary')">
                        <mat-icon aria-hidden="false" aria-label="Export as CSV">file_download</mat-icon>
                        <span>Export as CSV</span>
                    </button>
                    <button *ngIf="canDelete" mat-menu-item title="Delete Template(s)" (click)="templateWorkflowDelete()">
                        <mat-icon aria-hidden="false" aria-label="Delete Template">delete</mat-icon>
                        <span>Delete Template(s)</span>
                    </button>
                </mat-menu>
            </div>
        </div>
    </div>
    <app-template-grid 
        #secondaryList
        [templates]="secondaryTemplates"
        gridTitle="Workflows" 
        selectedTemplate="secondary"
        tooltipContent="Workflows supplement existing Harvest schedules, or Task Groups. They cannot be scheduled as their own standalone set of Events in the Calendar. They can only be applied to an existing active Harvest schedule, or Task Group."
        [displayHarvestsCreated]="false">
    </app-template-grid>
</div>
