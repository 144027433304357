import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';

import * as services from './services';
import { MatIconRegistry } from '@angular/material/icon';
import { NavigationStart, Router } from '@angular/router';
import { Subscription } from 'rxjs';

export let isBrowserRefresh: boolean = false;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class AppComponent implements OnInit, OnDestroy {

  routerSub: Subscription;
  
  constructor(
    private gaService: services.GoogleAnalyticsService
    , private matIconReg: MatIconRegistry
    , private router: Router
  ) {
    this.routerSub = router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        isBrowserRefresh = !router.navigated;
      }
    });
  }

  async ngOnInit() {
    this.gaService.setupGoogleAnalytics();
    this.matIconReg.setDefaultFontSetClass('material-symbols-outlined');
  }

  ngOnDestroy(): void {
    this.routerSub.unsubscribe();
  }
}
