import { EventInput } from "@fullcalendar/core";
import { Event } from "app/models/event";

export interface EventSubscription {
    id: string;
    eventListId?: string;
    //sub: Subscription;
    events: EventInput[]; //filtered events - to display
    allEvents: EventInput[]; //all events in calendar format - unfiltered
    eventList?: Array<Event>; //all events in the model of what is in the system
    completedInitialGet?: boolean;
  }