import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MenuPositionX, MenuPositionY } from '@angular/material/menu';

import * as models from 'app/models';
import { NgxMaterialPopoverComponent } from 'app/modules/ngx-material-popover/components';

@Component({
  selector: 'app-single-select-dropdown-btn',
  templateUrl: './single-select-dropdown-btn.component.html',
  styleUrls: ['./single-select-dropdown-btn.component.scss']
})
export class SingleSelectDropdownBtnComponent implements OnInit {
  @ViewChild('popover', { static: true })
  readonly popover!: NgxMaterialPopoverComponent;

  xPosition: MenuPositionX = 'after';
  yPosition: MenuPositionY = 'below';

  get title() {
    return this.selectedItem?.name || this.label;
  }

  @Input() label: string = 'Select';
  @Input() items: models.GenericItem[] = [];

  selectedItemValue: models.GenericItem;
  @Input() 
  get selectedItem(): models.GenericItem {
    return this.selectedItemValue;
  };

  @Output() selectedItemChange = new EventEmitter();
  set selectedItem(val: models.GenericItem) {
    this.selectedItemValue = val;  
    this.selectedItemChange.emit(this.selectedItemValue);
  }

  @Output() onClosed = new EventEmitter();

  isSelectOpen: boolean = false;
  
  constructor(
  ) { }
  
  ngOnInit(): void {
    
  }

  ngOnDestroy(): void {

  }

  closedEvent(event: any) {
    this.onClosed.emit();
  }
}
