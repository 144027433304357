
<mat-form-field appearance="outline" class="table-editor">
    <input
        matInput
        class="time-input"
        (focus)="focus()"
        [(ngModel)]="internalTime"
        type="time"
    />
    <!-- <button mat-icon-button matSuffix (click)="clear()">
        <mat-icon>cancel</mat-icon>
    </button> -->
</mat-form-field>