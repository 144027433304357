import { Component, OnDestroy, OnInit } from '@angular/core';
import { ComponentCanDeactivate } from 'app/services/guards/component-can-deactivate';

import * as models from 'app/models';
import * as services from 'app/services';
import * as viewmodels from 'app/viewmodels';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { AppStorage } from 'app/services';
import { HarvestSelectDialogComponent, RestrictionNotificationComponent, StepAddFromLibraryDialogComponent, TemplateAddComponent, TemplateUploadComponent } from 'app/dialogs';
import { MatDialog } from '@angular/material/dialog';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { ExportToCsv, Options } from 'export-to-csv';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatSelectChange } from '@angular/material/select';

@Component({
  selector: 'app-template-detail',
  templateUrl: './template-detail.component.html',
  styleUrls: ['./template-detail.component.scss']
})
export class TemplateDetailComponent extends ComponentCanDeactivate implements OnInit, OnDestroy {

  companySub: Subscription;
  role: models.Role;

  //variables
  templateId: string;
  template: models.Template;
  initialTemplate: models.Template;
  totalTemplates: number = 0;

  selectedTab: number = 0;

  weekDays: Array<string> = models.Contstants.daysOfWeek;
  startDayOfWeek: string = null;
  startDayOfWeekChange(e: MatSelectChange) {
    this.templateStepListHelperService.sub.next(e.value);
    this.replant();
  }

  get saveBtnEnabled() {
    return this.templateDirty;// || this.eventListComponent?.hasPendingChanges;
  }

  get templateDirty() {
    let x = JSON.stringify(this.template);
    let y = JSON.stringify(this.initialTemplate);
    return x != y;
  }

  get canAdd() : boolean {
    if(this.helperService.company_freeVersion && this.totalTemplates >= this.appStorage.freeTemplates) {
      return false;
    }

    return this.role?.permissions?.includes(models.Permissions.addTemplate);
  }

  get canDelete(): boolean {
    return this.role?.permissions?.includes(models.Permissions.deleteTemplate);
  }

  get allTags() {
    let list = [];
    this.template.phases.forEach(phase => {
      phase.steps.forEach(step => {
        if(step.tags != null){
          list.push(...step.tags);
        }
      });
    });
    
    return [...new Set(list)].sort();
  }

  get indexOfHarvestEnd(){
    return this.template.phases.findIndex(i => i.harvestAtEnd);
  }

  compliancePhases: string[] = [
    'Clone',
    'Propagation',
    'Vegetation',
    'Flowering',
    'Harvested'
  ]

  constructor(
    private helperService: services.HelperService
    , private activatedRoute: ActivatedRoute
    , private templateService: services.TemplateService
    , private appStorage: AppStorage
    , private router: Router
    , private dialog: MatDialog
    , private notifierService: services.NotifierService
    , private templateStepListHelperService: services.TemplateStepListHelperService
    , private harvestService: services.HarvestService
    , private claimsService: services.ClaimsService
  ) {
    super();
  }

  async ngOnInit(): Promise<void> {
    //get companyId
    let companyId = this.helperService.currentCompanyId;
    //if we have it then load
    if(companyId != null){
      this.findTemplateId();
    }
    //set up subscription to listen to company id changes
    this.companySub = this.helperService.getCurrentCompanyId().subscribe(data => {
      this.router.navigate(['/console', 'templates']);
    })

    this.totalTemplates = await this.templateService.getNumberOfTemplates();

    this.role = await this.claimsService.getRoleAwait();
  }

  canDeactivate(): boolean {
    return !this.templateDirty;// && !this.eventListComponent.hasPendingChanges;
  }

  ngOnDestroy(): void {
    if(this.companySub != null){
      this.companySub.unsubscribe();
    }
  }

  saveClick() {
    if(this.templateDirty) {
      this.save();
    }
  }

  async save() {
    if(!this.validate()){
      return;
    }

    if(this.template.type == models.TemplateType.Secondary){
      this.template.phases.forEach(phase => {
        phase.name = this.template.name
      });
    }
    
    let uid = await this.templateService.save(this.template);
    this.notifierService.success('Successfully saved template')
    this.getTemplate();

    this.checkConnectedHarvests(uid);
    if(this.startDayOfWeek){
      await this.helperService.wait(500);
      this.startDayOfWeekChange({value: this.startDayOfWeek, source: null})
    }
  }

  validate(): boolean {
    //validate template
    let commaSeperated = /^([0-9]+,?\s*)+$/;
    let justNumbers = /^\d+$/;
    let dash = /^(\d+-?)+\d+$/;
    let slash = /^(\d+\/?)+\d+$/;

    let invalidDayValues: Array<string> = [];
    this.template.phases.forEach(phase => {
      phase.steps.forEach(step => {
        let dayString: string = step.day.toString();
        if(step.day == null){
          invalidDayValues.push('null');
          return;
        }

        if(!commaSeperated.test(dayString) && !justNumbers.test(dayString) && !dash.test(dayString) && !slash.test(dayString)){
          invalidDayValues.push(dayString);
          return;
        }
      });
    });

    let valid: boolean = invalidDayValues.length == 0;

    if(!valid){
      let invalidValues = '(' + invalidDayValues.join(')(') + ')'
      this.notifierService.error(`Cannot save Template.  There are ${invalidDayValues.length} day values that is not valid.  It can either by a number, numbers seperated by commas (,), numbers seperated by a dash (-), or numbers seperated by a slash (/).  The invalid values are - ` + invalidValues);
    }

    const emptyPhases = this.template.phases.filter(i => i.steps == null || i.steps.length == 0);
    if(emptyPhases.length != 0) {
      this.notifierService.error('Template cannot be saved.  Please make sure each Phase has at least one Event added.')
      return false;
    }

    return valid;
  }

  undoClick() {
    if(this.templateDirty) {
      this.getTemplate();
    }
  }

  findTemplateId() {
    this.activatedRoute.params.subscribe( params => {
      this.templateId = params.templateId;
      this.getTemplate();
    });
  }

  async getTemplate() {
    this.template = await this.templateService.get(this.templateId);
    this.recalcDayTotals();
    this.initialTemplate = this.helperService.deepCopy(this.template);
  }

  endpointChange(event: MatCheckboxChange, phase: models.Phase){
    if(event.checked){
      this.template.phases.forEach(p => {
        if(p != phase){
          p.harvestAtEnd = false;
        }
      });
    }
  }

  recalcDayTotals() {
    this.templateService.setDayTotal(this.template);
    this.templateService.setDayOfWeek(this.template, this.startDayOfWeek)
  }

  replant() {
    this.recalcDayTotals();

    this.template.phases.forEach(phase => {
      let steps = this.helperService.deepCopy(this.helperService.sortArrayByStringIntField(phase.steps, 'day'));
      phase.steps = steps;
    });
  }

  daySinceChange(e){
    this.replant();
  }

  copy() {
    if(this.canAdd){
      this.openTemplateAddComp(true, this.template);
    } else {
      let restrictionDialog = this.dialog.open(RestrictionNotificationComponent, {
        width: '50%',
        data: 'templateAdd'
      });
    }
  }

  openTemplateAddComp(isCopy: boolean, templateToCopy: models.Template){
    let dialogRef = this.dialog.open(TemplateAddComponent, {
      panelClass: 'med-width-dialog',
      data: {isCopy: isCopy, templateToCopy: templateToCopy}
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result){
        this.router.navigate(['/console', 'template', result]);
      }
    })
  }

  csvExporter: ExportToCsv;
  export() {
    if(!this.canAdd){
      let restrictionDialog = this.dialog.open(RestrictionNotificationComponent, {
        width: '50%',
        data: 'templateAdd'
      });
      return;
    }

    let templateSteps: Array<viewmodels.TemplateExport> = [];
    this.template.phases.forEach(phase => {
      phase.steps.forEach(step => {
        let templateStep: viewmodels.TemplateExport = {
          phase: phase.name,
          daysSinceLastPhase: this.getDaysSinceLastPhase(templateSteps, phase),
          dayInPhase: step.day,
          dayTotal: step.dayTotal,
          taskDescription: step.description,
          highPriority: step.highPriority || false,
          anytime: step.anyTime,
          startTime: !step.anyTime ? step.startTime : 'N/A',
          cloudAttachments: step.cloudAttachments != null ? step.cloudAttachments.join('|') : '',
          notes: step.notes != null ? step.notes.join('|') : '',
          tags: step.tags != null ? step.tags.join('|') : '',
          subtasks: step.subtasks != null ? JSON.stringify(step.subtasks) : '',
          dataCollectionDefinitions: step.dataCollectionDefinitions != null ? JSON.stringify(step.dataCollectionDefinitions) : '',
        }

        templateSteps.push(templateStep);
      })
    })

    let options: Options = { 
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true, 
      showTitle: false,
      title: this.template.name,
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: true,
      filename: this.template.name,
      //headers: ['Phase', 'Day in Phase', 'Task Description', 'High Priority', "Anytime", "Start Time", "Cloud Attachments", "Notes"]
    };
  
    this.csvExporter = new ExportToCsv(options);

    this.csvExporter.generateCsv(templateSteps);
  }

  getDaysSinceLastPhase(templateSteps: Array<viewmodels.TemplateExport>, phase: models.Phase) : string {
    let retVal = '';

    //only add this to first line of export
    if(templateSteps.find(i => i.phase == phase.name) == null){
      retVal = phase.daysSinceLastPhase != null ? phase.daysSinceLastPhase.toString() : '0';
    }

    return retVal;
  }

  async delete() {
    let response = confirm('Are you sure you want to delete the selected Template?')

    if(response){
      await this.templateService.delete(this.template);
      this.notifierService.success('Successfully Deleted Template')
      this.router.navigate(['/console', 'templates']);
    }
  }

  dropTab(event: CdkDragDrop<string[]>) {
    if(event.container == event.previousContainer){
      console.log('didnt reorder')
    }
    else{
      var previousIndex = parseInt(event.previousContainer.id.replace("phase",""));
      var currentIndex = parseInt(event.container.id.replace("phase",""));

      let phases = this.helperService.deepCopy(this.template?.phases);
      moveItemInArray(phases, previousIndex, currentIndex);
      
      this.template.phases = phases;
      this.selectedTab = currentIndex;
    }
  }

  addPhase() {
    let name: string = this.template.type === models.TemplateType.Secondary ? this.template.name : 'New Phase';
    if(this.template.phases.find(i => i.harvestAtEnd == true) != null) {
      name = 'Post Harvest'
    }
    let newPhase: models.Phase = {
      name: name,
      daysSinceLastPhase: 0,
      steps: []
    };
    this.template.phases.push(newPhase);
  }

  deletePhaseClick(tab: number) {
    this.notifierService.confirm('Delete Phase?', 'Are you sure you want to delete this phase?',
    () => {this.deletePhase(tab)}, () => {})
  }

  deletePhase(tab: number) {
    this.template.phases.splice(tab, 1);
  }

  addStep(phase: models.Phase) {
    let step: models.Step = {
      day: '0',
      description: 'New Task',
      highPriority: false,
      anyTime: true,
      startTime: null,
      cloudAttachments: [],
      notes: [],
      tags: []
    }
    phase.steps.push(step);

    this.replant();
  }

  addStepFromLibrary(phase: models.Phase) {
    let dialogRef = this.dialog.open(StepAddFromLibraryDialogComponent);

    dialogRef.afterClosed().subscribe(result => {
      if(result){
        phase.steps.push(result);
        this.replant();
      }
    })
  }

  async checkConnectedHarvests(templateId: string) {
    let harvestsSS = (await this.harvestService.getAllForTemplate(templateId).get().toPromise());
    
    if(harvestsSS.size == 0){
      return;
    }

    let harvests: Array<models.Harvest> = [];
    harvestsSS.forEach(doc => {
      let harvest: models.Harvest = doc.data()
      harvest.uid = doc.id;
      harvests.push(harvest);
    })
    harvests = this.helperService.sortArrayByStringField(harvests, 'name');

    let inModel = {
      header: 'Template Saved.  Would you like to update Harvests?',
      negativeBtn: 'No',
      affirmativeBtn: 'Update',
      templateId: templateId,
      harvests: harvests
    }

    let dialogRef = this.dialog.open(HarvestSelectDialogComponent, {
      data: inModel
    });
  
    dialogRef.afterClosed().subscribe(async (dialogResult: models.Harvest[]) => {
      if(dialogResult){
        this.notifierService.success(`Harvest Update kicked off.  Go to processes screen to view.`);
        let response = await this.harvestService.updateHarvestsFromTemplate(templateId, dialogResult.map(i => i.uid));
        let result: models.Result<number> = response.data;

        if(result.success){
          this.notifierService.success(`Successfully updated ${result.value} Harvests`);
        }
      }
    })
  }
}
