<ngx-material-popover [popoverContent]="popoverContent" [clickOutToClose]="true" [xPosition]="xPosition"
    [yPosition]="yPosition" [clickOutToClose]="true" #popover="ngxMaterialPopover" (onClose)="closedEvent($event)"
    mode="toggle">
    <button mat-raised-button>
        {{ title }}
        <mat-icon class="expand">expand_more</mat-icon>
    </button>
</ngx-material-popover>

<ng-template #popoverContent>
    <mat-form-field style="width: 100%;">
        <mat-label>{{label}}</mat-label>
        <mat-select [(ngModel)]="selectedItem">
            <mat-option [value]="item" *ngFor="let item of items">
                {{item.name}}
            </mat-option>
        </mat-select>
    </mat-form-field>
</ng-template>
