<div mat-dialog-title>
    <h3>Save Filter Set</h3>
</div>

<mat-dialog-content>
    <div class="row">
        <div class="col-12">
            <mat-form-field>
                <input matInput [(ngModel)]="filterSet.name" placeholder="Filter Set Name">
            </mat-form-field>
        </div>
    </div>
    <!-- <div class="row">
        <div class="col-12">
            <mat-checkbox [(ngModel)]="includeHarvests">
              Include Harvests
            </mat-checkbox>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <mat-checkbox [(ngModel)]="includeGroups">
              Include Task Groups
            </mat-checkbox>
        </div>
    </div> -->
    <div class="row filter-content">
        <div class="col-12">
            <h5>Filters in Set</h5>
            <mat-card>
                <mat-card-content>
                    <p class="filter-summary-item" (click)="groupShowmore=!groupShowmore">
                        <span #groupContent [class.truncate]="!groupShowmore"><b style="font-weight: bolder;">Task Groups:</b> {{groupSummary}} </span>
                        <span *ngIf="!groupShowmore && isEllipsisActive(groupContent)" class="show-more"><mat-icon>expand_more</mat-icon></span>
                        <span *ngIf="groupShowmore" class="show-more"><mat-icon>expand_less</mat-icon></span>
                    </p>
                    <p *ngIf="!forAnalytics" class="filter-summary-item" (click)="zoneShowmore=!zoneShowmore">
                        <span #zoneContent [class.truncate]="!zoneShowmore"><b style="font-weight: bolder;">Zones:</b> {{zoneSummary}} </span>
                        <span *ngIf="!zoneShowmore && isEllipsisActive(zoneContent)" class="show-more"><mat-icon>expand_more</mat-icon></span>
                        <span *ngIf="zoneShowmore" class="show-more"><mat-icon>expand_less</mat-icon></span>
                    </p>
                    <p class="filter-summary-item" (click)="phaseShowmore=!phaseShowmore">
                        <span #phaseContent [class.truncate]="!phaseShowmore"><b style="font-weight: bolder;">Phases:</b> {{phaseSummary}} </span>
                        <span *ngIf="!phaseShowmore && isEllipsisActive(phaseContent)" class="show-more"><mat-icon>expand_more</mat-icon></span>
                        <span *ngIf="phaseShowmore" class="show-more"><mat-icon>expand_less</mat-icon></span>
                    </p>
                    <p class="filter-summary-item" (click)="teamShowmore=!teamShowmore">
                        <span #teamContent [class.truncate]="!teamShowmore"><b style="font-weight: bolder;">Teams:</b> {{teamSummary}} </span>
                        <span *ngIf="!teamShowmore && isEllipsisActive(teamContent)" class="show-more"><mat-icon>expand_more</mat-icon></span>
                        <span *ngIf="teamShowmore" class="show-more"><mat-icon>expand_less</mat-icon></span>
                    </p>
                    <p class="filter-summary-item" (click)="userShowmore=!userShowmore">
                        <span #userContent [class.truncate]="!userShowmore"><b style="font-weight: bolder;">Users:</b> {{userSummary}} </span>
                        <span *ngIf="!userShowmore && isEllipsisActive(userContent)" class="show-more"><mat-icon>expand_more</mat-icon></span>
                        <span *ngIf="userShowmore" class="show-more"><mat-icon>expand_less</mat-icon></span>
                    </p>
                    <p *ngIf="!forAnalytics" class="filter-summary-item" (click)="tagShowmore=!tagShowmore">
                        <span #tagContent [class.truncate]="!tagShowmore"><b style="font-weight: bolder;">Tags:</b> {{tagSummary}} </span>
                        <span *ngIf="!tagShowmore && isEllipsisActive(tagContent)" class="show-more"><mat-icon>expand_more</mat-icon></span>
                        <span *ngIf="tagShowmore" class="show-more"><mat-icon>expand_less</mat-icon></span>
                    </p>
                </mat-card-content>
            </mat-card>
            </div>
    </div>  
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>Cancel</button>
    <button mat-button (click)="save()" [disabled]="saving">
        <mat-spinner *ngIf="saving" diameter=25 strokeWidth=4></mat-spinner>
        <span *ngIf="!saving">Save</span>
    </button>
</mat-dialog-actions>