import { ColDef } from "ag-grid-community";
import { DataCollectionDefinition, GenericItem } from "app/models";
import { Timestamp } from "firebase/firestore";

export interface DataSubmissionExport {
    harvestId?: string;
    harvestName?: string;
    groupId?: string;
    groupName?: string;
    userId?: string;
    userName?: string;
    eventDescription?: string;
    eventDate?: Timestamp;
    data?: any;
}
  
export interface ReportDefinition {
    task: ReportTask;
    dataCollectionDefinitions: DataCollectionDefinition[];
    columnDefs?: Array<ColDef>;
    dataSubmissions?: DataSubmissionExport[];
}

export interface ReportTask {
    dataCollectionDefinitions?: DataCollectionDefinition[];
    templatedTaskId?: string;
    stepId?: string;
    subStepIds?: string[];
    name?: string;
}