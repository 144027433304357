<div class="container-fluid" style="padding-top: 5px;">
    <div class="row">
        <div class="col-sm-12">
            <div style="float: right;">
                <button mat-raised-button class="pad-btn" [matMenuTriggerFor]="menu">
                    <mat-icon>more_vert</mat-icon>
                </button>
                <mat-menu #menu="matMenu">
                    <button *ngIf="canAdd" mat-menu-item (click)="add()" title="Add Template">
                        <mat-icon aria-hidden="false" aria-label="Add Template">add</mat-icon>
                        <span>Add Template</span>
                    </button>
                    <button *ngIf="canAdd" mat-menu-item id="template_import_btn" title="Upload Template" (click)="import()">
                        <mat-icon aria-hidden="false" aria-label="Upload Template">file_upload</mat-icon>
                        <span>Upload Template</span>
                    </button>
                    <button mat-menu-item title="Export as CSV" (click)="exportAsCSV('primary')">
                        <mat-icon aria-hidden="false" aria-label="Export as CSV">file_download</mat-icon>
                        <span>Export as CSV</span>
                    </button>
                    <button *ngIf="canDelete" mat-menu-item title="Delete Template(s)" (click)="templateWorkflowDelete()">
                        <mat-icon aria-hidden="false" aria-label="Delete Template">delete</mat-icon>
                        <span>Delete Template(s)</span>
                    </button>
                </mat-menu>
            </div>
        </div>
    </div>
    <app-template-grid
        #primaryList 
        [templates]="primaryTemplates"
        gridTitle="Primary Templates" 
        selectedTemplate="primary"
        tooltipContent="Primary Templates are designed to capture your unique plant treatment schedule in the cultivation. We recommend recording all steps that it takes to get a group of plants from clone, all the way to the end of Post-Harvest, in each Template."
     >
    </app-template-grid>
</div>