import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '../app.material.module';
import { NgxAsideModule } from 'ngx-aside';
import { NgxMaterialPopoverModule } from 'app/modules/ngx-material-popover/ngx-material-popover.module'

import { HarvestDeleteBtnComponent } from './harvest-delete-btn/harvest-delete-btn.component';
import { HarvestEditBtnComponent } from './harvest-edit-btn/harvest-edit-btn.component';
import { HarvestShiftBtnComponent } from './harvest-shift-btn/harvest-shift-btn.component';
import { StripePortalBtnComponent } from './stripe-portal-btn/stripe-portal-btn.component';
import { UpgradeBtnComponent } from './upgrade-btn/upgrade-btn.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ManageBillingBtnComponent } from './manage-billing-btn/manage-billing-btn.component';
import { AddCardBtnComponent } from './add-card-btn/add-card-btn.component';
import { HarvestSaveBtnComponent } from './harvest-save-btn/harvest-save-btn.component';
import { HarvestUndoBtnComponent } from './harvest-undo-btn/harvest-undo-btn.component';
import { TaskGroupDeleteBtnComponent } from './task-group-delete-btn/task-group-delete-btn.component';
import { AttachmentBtnComponent } from './attachment-btn/attachment-btn.component';
import { LeaflogixHarvestSyncBtnComponent } from "./leaflogix-harvest-sync-btn/leaflogix-harvest-sync-btn.component";
import { MultiSelectBtnComponent } from './multi-select-btn/multi-select-btn.component';
import { HarvestSelectMultiBtnComponent } from './harvest-select-multi-btn/harvest-select-multi-btn.component';
import { TaskGroupSelectMultiBtnComponent } from './task-group-select-multi-btn/task-group-select-multi-btn.component';
import { ZoneSelectMultiBtnComponent } from './zone-select-multi-btn/zone-select-multi-btn.component';
import { PhaseSelectMultiBtnComponent } from './phase-select-multi-btn/phase-select-multi-btn.component';
import { TeamSelectMultiBtnComponent } from './team-select-multi-btn/team-select-multi-btn.component';
import { UserSelectMultiBtnComponent } from './user-select-multi-btn/user-select-multi-btn.component';
import { TagSelectMultiBtnComponent } from './tag-select-multi-btn/tag-select-multi-btn.component';
import { OtherFilterOptionsBtnComponent } from './other-filter-options-btn/other-filter-options-btn.component';
import { FilterSetSelectBtnComponent } from './filter-set-select-btn/filter-set-select-btn.component';
import { TemplateSelectMultiBtnComponent } from './template-select-multi-btn/template-select-multi-btn.component';
import { LeaflogixSyncBtnComponent } from './leaflogix-sync-btn/leaflogix-sync-btn.component';
import { AddMenuBtnComponent } from './add-menu-btn/add-menu-btn.component';
import { CalendarMoreMenuBtnComponent } from './calendar-more-menu-btn/calendar-more-menu-btn.component';
import { ApplyWorkflowBtnComponent } from './apply-workflow-btn/apply-workflow-btn.component';
import { DateFiltersBtnComponent } from './date-filters-btn/date-filters-btn.component';
import { IntervalSelectBtnComponent } from './interval-select-btn/interval-select-btn.component';
import { DutchieBtnComponent } from './dutchie-btn/dutchie-btn.component';
import { FilterSetChipListComponent } from './filter-set-chip-list/filter-set-chip-list.component';
import { MultiSelectDropdownBtnComponent } from './multi-select-dropdown-btn/multi-select-dropdown-btn.component';
import { SingleSelectDropdownBtnComponent } from './single-select-dropdown-btn/single-select-dropdown-btn.component';
import { TaskTypeSelectMultipleBtnComponent } from './task-type-select-multiple-btn/task-type-select-multiple-btn.component';

const buttons = [
  HarvestDeleteBtnComponent,
  HarvestEditBtnComponent,
  HarvestShiftBtnComponent,
  StripePortalBtnComponent,
  UpgradeBtnComponent,
  ManageBillingBtnComponent,
  AddCardBtnComponent,
  HarvestSaveBtnComponent,
  HarvestUndoBtnComponent,
  TaskGroupDeleteBtnComponent,
  AttachmentBtnComponent,
  MultiSelectBtnComponent,
  HarvestSelectMultiBtnComponent,
  TaskGroupSelectMultiBtnComponent,
  ZoneSelectMultiBtnComponent,
  PhaseSelectMultiBtnComponent,
  TeamSelectMultiBtnComponent,
  UserSelectMultiBtnComponent,
  TagSelectMultiBtnComponent,
  OtherFilterOptionsBtnComponent,
  FilterSetSelectBtnComponent,
  TemplateSelectMultiBtnComponent,
  LeaflogixHarvestSyncBtnComponent,
  LeaflogixSyncBtnComponent,
  AddMenuBtnComponent,
  CalendarMoreMenuBtnComponent,
  ApplyWorkflowBtnComponent,
  DateFiltersBtnComponent,
  IntervalSelectBtnComponent,
  DutchieBtnComponent,
  FilterSetChipListComponent,
  MultiSelectDropdownBtnComponent,
  SingleSelectDropdownBtnComponent,
  TaskTypeSelectMultipleBtnComponent,
]
@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    NgxAsideModule,
    NgxMaterialPopoverModule
  ],
  declarations: [
    ...buttons,
  ],
  exports: [
    ...buttons
  ]
})
export class ButtonsModule { }
