<ngx-material-popover [popoverContent]="popoverContent" [clickOutToClose]="true" [xPosition]="xPosition"
    [yPosition]="yPosition" [clickOutToClose]="true" #popover="ngxMaterialPopover" (onClose)="closedEvent($event)"
    mode="toggle">
    <button mat-raised-button>
        {{ title }}
        <mat-icon class="expand">expand_more</mat-icon>
    </button>
</ngx-material-popover>

<ng-template #popoverContent>
    <mat-form-field style="width: 100%;">
        <mat-label>{{label}}</mat-label>
        <mat-select [(ngModel)]="selectedItems" multiple>
            <mat-option disabled="disabled" class="filter-option">
                <button mat-raised-button class="mat-primary fill text-sm" (click)="toggleAllSelection(true)">
                    Select All
                </button>
                <button mat-raised-button class="mat-accent fill text-sm" style="margin-left: 10px;" (click)="toggleAllSelection(false)">
                    Deselect All
                </button>
            </mat-option>
            <mat-option [value]="item" *ngFor="let item of items">
                {{item.name}}
            </mat-option>
        </mat-select>
    </mat-form-field>
</ng-template>
