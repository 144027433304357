import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { Timestamp } from 'firebase/firestore';
import { ColDef, CsvExportParams, GridOptions } from 'ag-grid-community';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MatTabsModule } from '@angular/material/tabs';

import * as models from "../../../models";
import * as services from "../../../services";
import * as cells from 'app/components/cell-components';
import * as viewmodels from 'app/viewmodels';
import { report } from 'process';
import { ExportToCsv, Options } from 'export-to-csv';

@Component({
  selector: 'app-data-submission-analytics',
  templateUrl: './data-submission-analytics.component.html',
  styleUrls: ['./data-submission-analytics.component.scss']
})
export class DataSubmissionAnalyticsComponent implements OnInit {

  dataSubmissions: viewmodels.DataSubmissionExport[] = [];

  harvestIds: string[] = [];
  harvests: viewmodels.MultiSelect[] = [];
  
  groupIds: string[] = [];
  groups: viewmodels.MultiSelect[] = [];

  userIds: string[] = [];
  selectedUsers: viewmodels.MultiSelect[] = [];

  dateRange: models.DateRanger;

  selectedTaskTemplateIds: string[] = [];
  selectedTaskTemplates: viewmodels.MultiSelect[] = [];

  dataCollectionDefinitions: models.DataCollectionDefinition[] = [];
  // dataCollectionDefinitionTasks: models.GenericItem[] = [];
  // selectedDataCollectionDefinitions: models.GenericItem[] = [];

  reports: viewmodels.ReportDefinition[] = [];

  users: viewmodels.MultiSelect[] = [];

  running: boolean = false;
  displayReport: boolean = false;

  csvExporter: ExportToCsv;
  
  permanentColDefs: Array<ColDef> = [
    {
      headerName: "Harvest",
      field: "harvestName",
    },
    {
      headerName: "Task Group",
      field: "groupName",
    },
    {
      headerName: "Event",
      field: "eventDescription",
    },
    {
      headerName: "Event Date",
      field: "eventDate",
      cellRendererFramework: cells.TimeStampComponent
    },
    {
      headerName: "User",
      field: "userName",
    },
  ];

  //columnDefs: Array<ColDef> = [];

  // Add new properties for tab handling
  activeTabIndex: number = 0;
  displayTabs: boolean = false;

  get harvestSummary() {
    if(this.harvests.length == 0) {
      return 'None';
    }
    return this.harvests.map(i => i.displayValue).join(', ');
  }

  get groupSummary() {
    if(this.groups.length == 0) {
      return 'None';
    }
    return this.groups.map(i => i.displayValue).join(', ');
  }

  get userSummary() {
    if(this.selectedUsers.length == 0) {
      return 'All';
    }
    return this.selectedUsers.map(i => i.displayValue).join(', ');
  }

  get duration() {
    return this.dateRange.startDate.toDate().toLocaleDateString() + ' - ' + this.dateRange.endDate.toDate().toLocaleDateString();
  }

  get taskTypesSummary() {
    if(this.selectedTaskTemplates.length == 0) {
      return 'None';
    }
    return this.selectedTaskTemplates.map(i => i.displayValue).join(', ');
  }

  get hasReportData(): boolean {
    return this.reports.some(report => report.dataSubmissions && report.dataSubmissions.length > 0);
  }

  constructor(
    private helperService: services.HelperService
    , private templatedTasksService: services.TemplatedTasksService
    , private templateService: services.TemplateService
    , private dataLibraryService: services.DataLibraryService
    , private notifierService: services.NotifierService
    , private eventService: services.EventService
    , private deviceService: DeviceDetectorService
    , private userService: services.UserService
    , private changeDetectorRef: ChangeDetectorRef
  ) {
    this.dateRange = {
      rangeType: 'last_month',
      datesInRange: this.helperService.computeDatesRange('last_month'),
      defaultViewSetTo: 'Day'
    };
  }

  ngOnInit(): void {
    // Initialize with current company's data
    const companyId = this.helperService.currentCompanyId;
    if (companyId) {
      this.filter();
    }
    this.load();
  }

  filter() {
    // Filter logic will be implemented based on selected date range
    if (this.dateRange && this.dateRange.datesInRange) {
      // Use the date range for filtering
      console.log('Filtering with date range:', this.dateRange);
    }
  }

  load() {
    this.getDataLibrary();
    this.getUsers();
  }

  async getDataLibrary() {
    let dataCollectionDefinitions = await this.dataLibraryService.getAll();
    this.dataCollectionDefinitions = this.helperService.sortArrayByStringField(dataCollectionDefinitions, 'name');

    // this.dataCollectionDefinitionTasks = this.dataCollectionDefinitions.map(data => {
    //   return {
    //     id: data.id,
    //     name: data.name
    //   };
    // });
  }

  async getUsers() {
    const companyId: string = this.helperService.currentCompanyId;
    let users = await this.userService.getUsersForCompanyWithCaching(companyId, true, true)
    let list = users.map(i => {
      let h: viewmodels.MultiSelect = {
        id: i.id,
        displayValue: i.displayName,
        selected: false // this.selected.includes(i.uid)
      }
      return h;
    })

    this.users = list;
  }

  clearTemplatedTask() {
    this.selectedTaskTemplates = [];
  }

  setReports() {
    this.reports = [];
    this.selectedTaskTemplates.forEach(selected => {

      // let dataDefs;
      // let selectedTask = this.templatedTasks.find(i => i.id == selected.id);
      // if(selectedTask != null) {
      //   dataDefs = selectedTask.dataCollectionDefinitions || [];
      // } else {
      //   let selectedTemplatedTask = this.tasksInTemplate.find(i => i.uid == selected.id);
      //   dataDefs = selectedTemplatedTask.dataCollectionDefinitions || [];
      // }

      // let defs = this.dataCollectionDefinitions.filter(i => i.id != null && dataDefs.map(i => i.id).includes(i.id))

      // let task = this.templatedTaskItems.find(i => i.id == selected.id);
      let genericTask: viewmodels.ReportTask = {
        dataCollectionDefinitions: selected.dataCollectionDefinitions,
        templatedTaskId: selected.templatedTaskId,
        stepId: selected.stepId,
        subStepIds: selected.subStepIds,
        name: selected.displayValue
      };
      let report: viewmodels.ReportDefinition = {
        task: genericTask,
        dataCollectionDefinitions: selected.dataCollectionDefinitions
      };
      this.reports.push(report);
    });
  }

  async runReport() {
    this.setReports();
    if(this.reports.length == 0) {
      this.notifierService.error('Please select at least one Task Type');
      return;
    }

    this.running = true;
    this.displayReport = false; // Reset display state while loading
    this.displayTabs = false;

    let harvestEvents = await this.eventService.getByHarvestIdList(this.harvestIds);
    let groupEvents = await this.eventService.getByGroupIdList(this.groupIds);

    let events = harvestEvents.concat(groupEvents);

    let startDate = this.dateRange.startDate.toDate();
    startDate.setHours(0, 0, 0, 0);

    let endDate = this.dateRange.endDate.toDate();
    endDate.setHours(23, 59, 59, 999);

    events = events.filter(event => {
      return event.dataSubmissions != null && event.dataSubmissions.length > 0;
    });
      
    events = events.filter(event => {
      return event.startDateTime != null &&
        (this.dateRange?.startDate == null || event.startDateTime.toDate() >= startDate) &&
        (this.dateRange?.endDate == null || event.startDateTime.toDate() <= endDate);
    });

    // Clear existing data
    this.reports.forEach(report => {
      report.columnDefs = [];
      report.dataSubmissions = [];
    });

    // Process each report (task type) separately
    this.reports.forEach(report => {
      let reportColumns: Array<ColDef> = [];
      let dataSubmissions: viewmodels.DataSubmissionExport[] = [];

      let reportEvents: models.Event[] = this.helperService.deepCopy(events);

      if(report.task.stepId != null) {
        reportEvents = reportEvents.filter(i => i.stepId == report.task.stepId && i.templatedTaskId == report.task.templatedTaskId);
      } else {
        reportEvents = reportEvents.filter(i => i.templatedTaskId == report.task.templatedTaskId || report.task.subStepIds.includes(i.stepId));
      }

      reportEvents.forEach(event => {
        // if(!event.dataSubmissions || event.description !== report.task.name) {
        //   return;
        // }

        let selectedDefIds = report.dataCollectionDefinitions.map(i => i.id);
        let idsInEvent = event.dataCollectionDefinitions.map(i => i.id);

        if(!this.areListsEqual(selectedDefIds, idsInEvent)) {
          return;
        }

        event.dataSubmissions.forEach(submission => {
          if(this.userIds.length > 0 && !this.userIds.includes(submission.userId)) {
            return;
          }

          let dataSubmission: viewmodels.DataSubmissionExport = {
            harvestId: event.isInGroup == false ? event.parentId : null,
            harvestName: this.harvests.find(i => i.id == event.parentId)?.displayValue,
            groupId: event.isInGroup == true ? event.parentId : null,
            groupName: this.groups.find(i => i.id == event.parentId)?.displayValue,
            userId: submission.userId,
            userName: this.users.find(i => i.id == submission.userId)?.displayValue,
            eventDescription: event.description,
            eventDate: event.startDateTime,
            data: submission.data,
          };

          // Build columns for this report
          Object.keys(submission.data).forEach(key => {
            let def = event.dataCollectionDefinitions.find(i => i.id == key);
            let field = 'data.' + key;
            if(!reportColumns.some(col => col.headerName === def.name)) {
              reportColumns.push({
                headerName: def.name,
                field: field,
              });
            }
          });

          dataSubmissions.push(dataSubmission);
        });
      });

      report.columnDefs = this.permanentColDefs.concat(reportColumns);
      report.dataSubmissions = dataSubmissions;
    });

    // Only show if we have data
    this.displayReport = true;
    this.displayTabs = true;
    this.activeTabIndex = 0;
    this.running = false;

    // Force change detection if needed
    this.changeDetectorRef.detectChanges();
  }

  // Add method to handle tab changes
  onTabChange(index: number) {
    this.activeTabIndex = index;
  }
  
  export(allOne: boolean) {
    const formattedDate = this.formatDateForFilename(new Date());
    if (allOne) {
      const allData: any[] = [];
      
      // Combine data from all reports
      this.reports.forEach(report => {
        const exportObjects = this.convertReportToExportObjects(report);
        allData.push(...exportObjects);
      });

      // Normalize the data before exporting
      const normalizedData = this.normalizeData(allData);
      this.exportCsv(normalizedData, `all_reports_${formattedDate}.csv`);
    } else {
      // Download separate CSV for each report
      this.reports.forEach((report, index) => {
        if (report.dataSubmissions.length > 0) {
          const exportObjects = this.convertReportToExportObjects(report);
          const normalizedData = this.normalizeData(exportObjects);
          this.exportCsv(normalizedData, `${report.task.name}_${formattedDate}.csv`);
        }
      });
    }
  }

  private convertReportToExportObjects(report: viewmodels.ReportDefinition): any[] {
    let exportObjects = []
    report.dataSubmissions.forEach(submission => {
      let exportObject = {}
      report.columnDefs.forEach(columnDef => {
        let data = null;
        if(columnDef.field.startsWith('data.')) {
          let field = columnDef.field.replace('data.', '');
          data = submission.data[field] || '';
        } else {
          data = submission[columnDef.field] || '';
        }
        //if is timestamp, convert to date
        if(data instanceof Timestamp) {
          data = this.helperService.timestampToDateString(data);
        }
        exportObject[columnDef.headerName] = (data === undefined || data === null) ? '' : data;
      });
      exportObjects.push(exportObject);
    });
    return exportObjects;
  }

  private exportCsv(data: any[], exportName: string) {

    let headers = [];
    data.forEach(row => {
      Object.keys(row).forEach(key => {
        if(!headers.includes(key)) {
          headers.push(key);
        }
      });
    });

    let options: Options = { 
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true, 
      showTitle: false,
      title: exportName,
      useTextFile: false,
      useBom: true,
      //useKeysAsHeaders: true,
      filename: exportName,
      headers: headers
    };
  
    this.csvExporter = new ExportToCsv(options);

    this.csvExporter.generateCsv(data);

    //if (data.length === 0) return '';

    // Get all unique headers across all objects
    // const headers = Array.from(new Set(
    //   data.map(row => Object.keys(row)).flat().filter((value, index, self) => self.indexOf(value) === index), []).sort((a, b) => {
    //     return allHeaders.concat(Object.keys(row));
    //   }, [])
    // ));

    // const csvRows = [headers.join(',')];

    // for (const row of data) {
    //   const values = headers.map(header => {
    //     const value = row[header];
    //     // Handle values that contain commas or quotes
    //     if (value === null || value === undefined) return '';
    //     const stringValue = String(value);
    //     if (stringValue.includes(',') || stringValue.includes('"')) {
    //       return `"${stringValue.replace(/"/g, '""')}"`;
    //     }
    //     return stringValue;
    //   });
    //   csvRows.push(values.join(','));
    // }

    //return 'fuck';// csvRows.join('\n');
  }

  // private downloadCSV(csvContent: string, filename: string) {
  //   const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
  //   const link = document.createElement('a');
  //   if (link.download !== undefined) {
  //     const url = URL.createObjectURL(blob);
  //     link.setAttribute('href', url);
  //     link.setAttribute('download', filename);
  //     link.style.visibility = 'hidden';
  //     document.body.appendChild(link);
  //     link.click();
  //     document.body.removeChild(link);
  //   }
  // }

  areListsEqual(list1: string[], list2: string[]): boolean {
    if (list1.length !== list2.length) {
        return false;
    }

    const sortedList1 = [...list1].sort();
    const sortedList2 = [...list2].sort();

    return sortedList1.every((value, index) => value === sortedList2[index]);
  }

  private formatDateForFilename(date: Date): string {
    const yy = date.getFullYear().toString().slice(-2);
    const mm = String(date.getMonth() + 1).padStart(2, '0');
    const dd = String(date.getDate()).padStart(2, '0');
    const hh = String(date.getHours()).padStart(2, '0');
    const min = String(date.getMinutes()).padStart(2, '0');
    return `${yy}-${mm}-${dd}:${hh}-${min}`;
  }

  private normalizeData(data: any[]): any[] {
    // Get all unique field names from all objects
    const allFields = new Set<string>();
    data.forEach(obj => {
      Object.keys(obj).forEach(key => {
        allFields.add(key);
      });
    });

    // Add missing fields with empty strings to each object
    return data.map(obj => {
      const normalizedObj = { ...obj };
      allFields.forEach(field => {
        if (!(field in normalizedObj)) {
          normalizedObj[field] = '';
        }
      });
      return normalizedObj;
    });
  }

}
