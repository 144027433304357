import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MenuPositionX, MenuPositionY } from '@angular/material/menu';
import { NgxMaterialPopoverComponent } from 'app/modules/ngx-material-popover/components';
import * as models from "../../models";
import * as services from "../../services";
import { Subscription } from 'rxjs';
import { Timestamp } from 'firebase/firestore';
@Component({
  selector: 'app-date-filters-btn',
  templateUrl: './date-filters-btn.component.html',
  styleUrls: ['./date-filters-btn.component.scss'],
})
export class DateFiltersBtnComponent implements OnInit {
  @ViewChild('popover', { static: true })
  readonly popover!: NgxMaterialPopoverComponent;

  xPosition: MenuPositionX = 'after';
  yPosition: MenuPositionY = 'below';

  dateRangeTitle: string = 'Date Range';

  selectedDateRangeValue: models.DateRanger;

  @Input() 
  get selectedDateRange(): models.DateRanger {
    return this.selectedDateRangeValue;
  };

  @Output() selectedDateRangeChange = new EventEmitter();
  set selectedDateRange(val: models.DateRanger) {
    if(val && val != null){
      this.selectedDateRangeValue = val;  
      this.selectedDateRangeChange.emit(this.selectedDateRangeValue);
    }
  }

  @Output() onClosed = new EventEmitter();
  @Output() dateOptionChange: EventEmitter<models.DateRanger> = new EventEmitter<models.DateRanger>();

  isSelectOpen: boolean = false;
  selectedDateFilterOption: string = "last_month";

  companySub: Subscription;
  
  constructor(
    private helperService: services.HelperService
    ) { }

  setDefaultDateRange() {
    this.dateRangeTitle = 'Date Range';
    this.selectedDateFilterOption = 'last_month';

    let datesInRange = this.helperService.computeDatesRange('last_month');
    this.selectedDateRange = {
      rangeType: 'last_month',
      datesInRange: datesInRange,
      defaultViewSetTo: 'Day',
      startDate: Timestamp.fromDate(new Date(datesInRange[0])),
      endDate: Timestamp.fromDate(new Date(datesInRange[datesInRange.length - 1]))
    }
    this.dateRangeTitle = this.helperService.getDateRangeTitle(this.selectedDateFilterOption);
  }
  
  ngOnInit(): void {
    // initially set to last month;
    let companyId = this.helperService.currentCompanyId;
    if (companyId != null) {
      this.setDefaultDateRange();
    }
    this.companySub = this.helperService.getCurrentCompany().subscribe(data => {
      this.setDefaultDateRange();
    })
    
  }

  ngOnDestroy(): void {
    if (this.companySub){
      this.companySub.unsubscribe();
    }
  }

  onCustomDateRangeChange(startDate: string, endDate: string): void {
    this.setCustomDateRange(startDate, endDate);
  }

  setDateRange() {
    const dates = this.helperService.computeDatesRange(this.selectedDateFilterOption);
    const defaultView = this.helperService.getDefaultInterval(this.selectedDateFilterOption);

    this.selectedDateRange = {
      rangeType: this.selectedDateFilterOption,
      datesInRange: dates,
      defaultViewSetTo: defaultView,
      startDate: Timestamp.fromDate(new Date(dates[0])),
      endDate: Timestamp.fromDate(new Date(dates[dates.length - 1]))
    }
  }

  setCustomDateRange(startDate: string, endDate: string) {
    const dates = this.helperService.computeDatesRange(this.selectedDateFilterOption, startDate, endDate);
    const defaultView = this.helperService.getDefaultInterval(this.selectedDateFilterOption);
    this.selectedDateRange = {
      rangeType: this.selectedDateFilterOption,
      datesInRange: dates,
      defaultViewSetTo: defaultView,
      startDate: Timestamp.fromDate(new Date(dates[0])),
      endDate: Timestamp.fromDate(new Date(dates[dates.length - 1]))
    }
  }

  onDateFilterOptionChange() {
    if (this.selectedDateFilterOption !== 'custom') {
      this.setDateRange();
    } 
  }

  closedEvent(event: any) {
    this.dateRangeTitle = this.helperService.getDateRangeTitle(this.selectedDateFilterOption);
    this.onClosed.emit();
  }

}
