<ul class="sessions">
    <li *ngFor="let h of listDisplay; let i = index">
        <div class="flex">
            <div>
                <div class="time">
                    {{h.date | date:'M/d/yyyy'}}
                    <mat-icon *ngIf="h.harvestAtEnd" class="harvest-at-end">content_cut</mat-icon>
                </div>
                <p>
                    {{h.description}}
                </p>
            </div>
        </div>
    </li>
</ul>

<!-- <app-timeline [dataList]="timelineData"></app-timeline> -->
