<h2 mat-dialog-title>
    <span style="display: inline-block">Data Submission</span>
    <button style="float: right" mat-icon-button (click)="add()" [disabled]="!canAddSubmission">
      <mat-icon>add</mat-icon>
    </button>
</h2>
<mat-dialog-content style="max-width:98vw">
 <div class="row">
    <div class="col-12">
        <p-table class="data-sub-table" [value]="dataSubmissionDisplays" responsiveLayout="default" dataKey="id" editMode="row" [tableStyle]="{'min-width': '50rem', 'border-collapse': 'separate'}">
            <ng-template pTemplate="header">
                <tr>
                    <th>User</th>
                    <th *ngFor="let def of dataCollectionDefinitions">
                        {{def.name}} <span *ngIf="getUnitViewModel(def.unit).suffix != null">({{getUnitViewModel(def.unit).suffix}})</span>
                    </th>
                    <th></th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-submission let-ri="rowIndex">
                <tr [pEditableRow]="submission" [className]="getClass(submission)">
                    <td class="table-cell">
                        <!-- <p-cellEditor> -->
                            <ng-container *ngIf="submission.editing">
                                <app-user-select
                                    [(userId)]="submission.dataSubmission.userId"
                                    [includeNullValue]="true"
                                    (userIdChange)="userChange($event)">
                                </app-user-select>
                                <!-- //[excludedUserIds]="currentSelectedUserIds" -->

                                <!-- <p-dropdown 
                                    [options]="users" 
                                    appendTo="body" 
                                    [(ngModel)]="submission.dataSubmission.userId"
                                    optionLabel="displayName" 
                                    [style]="{'width':'100%'}"></p-dropdown> -->
                            </ng-container>
                            <ng-container *ngIf="!submission.editing">
                                {{getSelectedUsersName(submission.dataSubmission.userId)}}
                            </ng-container>
                        <!-- </p-cellEditor> -->
                    </td>
                    <td class="table-cell" *ngFor="let def of dataCollectionDefinitions">
                        <!-- <p-cellEditor> -->
                            <ng-container *ngIf="submission.editing">
                                <ng-container *ngIf="getUnitViewModel(def.unit).inputType == 'text'">
                                    <mat-form-field appearance="outline" class="table-editor">
                                        <input matInput type="text" [(ngModel)]="submission.dataSubmission.data[def.id]" />
                                        <span matSuffix *ngIf="getUnitViewModel(def.unit).suffix != null">{{getUnitViewModel(def.unit).suffix}}</span>
                                    </mat-form-field>
                                </ng-container>
                                <ng-container *ngIf="getUnitViewModel(def.unit).inputType == 'number'">
                                    <mat-form-field appearance="outline" class="table-editor">
                                        <input matInput type="number" [(ngModel)]="submission.dataSubmission.data[def.id]" />
                                        <span matSuffix *ngIf="getUnitViewModel(def.unit).suffix != null">{{getUnitViewModel(def.unit).suffix}}</span>
                                        <button mat-icon-button matSuffix class="mat-suffix-add" *ngIf="getUnitViewModel(def.unit).sumMultiple" (click)="increment(submission, def)">
                                            <mat-icon>add</mat-icon>
                                        </button>
                                    </mat-form-field>
                                </ng-container>
                                <ng-container *ngIf="getUnitViewModel(def.unit).inputType == 'date'">
                                    <mat-form-field appearance="outline" class="table-editor">
                                        <!-- <input matInput type="text" [placeholder]="def.name" [(ngModel)]="submission.dataSubmission.data[def.id]" />
                                        <span matSuffix *ngIf="def.unit.suffix != null">{{def.unit.suffix}}</span> -->

                                        <input matInput [matDatepicker]="datePicker" [(ngModel)]="submission.dataSubmission.data[def.id]"
                                        disabled="true" class="workflow-start-date">
                                        <mat-datepicker-toggle matSuffix [for]="datePicker"></mat-datepicker-toggle>
                                        <mat-datepicker #datePicker disabled="false"></mat-datepicker>
                                    </mat-form-field>
                                    
                                </ng-container>
                                <ng-container *ngIf="getUnitViewModel(def.unit).inputType == 'time'">
                                    <app-time-input
                                        [setCurrentTimeOnClick]="true"
                                        [(time)]="submission.dataSubmission.data[def.id]">
                                    </app-time-input>

                                </ng-container>
                                <!-- <input 
                                    pInputText type="text" 
                                    [(ngModel)]="submission.dataSubmission.data[def.id]" /> -->
                            </ng-container>
                            <ng-container *ngIf="!submission.editing">
                                {{submission.dataSubmission.data[def.id]}}
                                <!-- {{product.price | currency: 'USD'}} -->
                            </ng-container>
                        <!-- </p-cellEditor> -->
                    </td>
                    <td class="table-cell" style="min-width: 110px;">
                        <div class="flex align-items-center justify-content-center gap-2">
                            <button 
                                *ngIf="!submission.editing"
                                pButton 
                                pRipple 
                                type="button" 
                                pInitEditableRow 
                                icon="pi pi-pencil" 
                                (click)="onRowEditInit(submission)" 
                                class="p-button-rounded p-button-text">
                            </button>
                            <button 
                                *ngIf="!submission.editing"
                                pButton 
                                pRipple 
                                type="button" 
                                pInitEditableRow 
                                icon="pi pi-trash" 
                                (click)="onRowDelete(submission)" 
                                class="p-button-rounded p-button-text">
                            </button>
                            <button 
                                *ngIf="submission.editing"
                                pButton 
                                pRipple 
                                type="button" 
                                pSaveEditableRow 
                                icon="pi pi-check" 
                                (click)="onRowEditSave(submission)" 
                                class="p-button-rounded p-button-text p-button-success mr-2">
                            </button>
                            <button 
                                *ngIf="submission.editing"
                                pButton pRipple 
                                type="button" 
                                pCancelEditableRow 
                                icon="pi pi-times" 
                                (click)="onRowEditCancel(submission, ri)" 
                                class="p-button-rounded p-button-text p-button-danger">
                            </button>
                        </div>
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </div>
 </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button (click)="close()">Cancel</button>
    <!-- The mat-dialog-close directive optionally accepts a value as a result for the dialog. -->
    <button mat-button (click)="save()">Ok</button>
</mat-dialog-actions>