import { Component, OnInit, ViewChild } from '@angular/core';
import { ComponentCanDeactivate } from 'app/services/guards/component-can-deactivate';
import * as services from "../../../services";
import * as models from "../../../models";
import { onSnapshot, Unsubscribe } from '@angular/fire/firestore';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { EventsCompletedStackedChartComponent } from '../../charts/events-completed-stacked-chart/events-completed-stacked-chart.component';
import { UsersTeamsPerformanceChartComponent } from '../../charts/users-teams-performance-chart/users-teams-performance-chart.component';
import { UsersActualAndEstimatedTimeChartComponent } from '../../charts/users-actual-and-estimated-time-chart/users-actual-and-estimated-time-chart.component';
import { EventVolumeComboChartComponent } from '../../charts/event-volume-combo-chart/event-volume-combo-chart.component';

@Component({
  selector: 'app-analytics',
  templateUrl: './analytics.component.html',
  styleUrls: ['./analytics.component.scss']
})
export class AnalyticsComponent extends ComponentCanDeactivate implements OnInit {

  @ViewChild(EventsCompletedStackedChartComponent) eventsCompletedStackedChartComponent: EventsCompletedStackedChartComponent;
  @ViewChild(UsersTeamsPerformanceChartComponent) usersTeamsPerformanceChartComponent: UsersTeamsPerformanceChartComponent;
  @ViewChild(UsersActualAndEstimatedTimeChartComponent) usersActualAndEstimatedTimeChartComponent: UsersActualAndEstimatedTimeChartComponent;
  @ViewChild(EventVolumeComboChartComponent) eventVolumeComboChartComponent: EventVolumeComboChartComponent;

  harvestIds: string[] = [];
  groupIds: string[] = [];
  phaseIds: string[] = [];
  teamIds: string[] = [];
  filterUserIds: string[];
  filterTeamIds: string[];
  phases: string[] = [];

  isEventsCompletedChartExpanded: boolean = false;
  isUserTeamPerformanceChartExpanded: boolean = false;
  isUsersActualExpectedChartExpanded: boolean = false;
  isEventVolumnChartExpanded: boolean = false;

  filteredEvents: models.ChartEventInput[] = [];
  allEvents: models.ChartEventInput[] = []; // for Performace cards

  collectionGroupSub: Unsubscribe

  gettingEvents: boolean = false;

  retrievedUsers: boolean = false;
  users: Array<models.User> = [];
  
  retrievedTeams: boolean = false;
  teams: Array<models.Team> = [];

  eventSubs: EventSubscription[] = [];

  // default position for every chart
  chartsPositions =  [{
    chart: 'events', position: 1,
  }, {
    chart: 'users_teams', position: 2,
  }, {
    chart: 'time_taken', position: 3,
  }, {
    chart: 'labor_load', position: 4,
  }];

  eventsChartClasses: string = 'col-md-6';
  usersAndTeamsChartClasses: string = 'col-md-6';
  timeTakenChartClasses: string = 'col-md-6';
  laborLoadChartClasses: string = 'col-md-6';

  get chartData() {
    this.eventSubs.forEach(list => {
      this.filterEventList(list);
    });
    return this.eventSubs.flatMap(i => i.events);
  }

  filterEventList(list: EventSubscription) {
    let events = list.allEvents;
    //filter by harvests
    if(this.harvestIds != null){
      events = events.filter(i => i.type != 'harvests' || this.harvestIds.includes(i.parentId));
    }

    //filter by task groups
    if(this.groupIds != null){
      events = events.filter(i => i.type != 'groups' || this.groupIds.includes(i.parentId));
    }

    //filter by user
    if (this.filterUserIds != null && this.filterUserIds.length > 0) {
      events = events.filter(i =>
        (i.eventObj.assignedUserIds != null &&
          i.eventObj.assignedUserIds.filter(value => this.filterUserIds.includes(value)).length > 0) ||
        (i.eventObj.assignedTeamIds != null &&
          i.eventObj.assignedTeamIds.filter(value => this.filterTeamIds.includes(value)).length > 0)
      )
    }

    //filter by team
    if (this.filterTeamIds != null && this.filterTeamIds.length > 0) {
      events = events.filter(i =>
      (i.eventObj.assignedTeamIds != null &&
        i.eventObj.assignedTeamIds.filter(value => this.filterTeamIds.includes(value)).length > 0)
      )
    }

    //filter by phases
    if (this.phases != null && this.phases.length > 0) {
      events = events.filter(i => i.eventObj.phase == null || i.eventObj.phase == '' || this.phases.includes(i.eventObj.phase))
    }

    list.events = events;
  }

  constructor(
    private eventService: services.EventService
    , private firestore: AngularFirestore
    , private helperService: services.HelperService
    , private userService: services.UserService
    , private teamService: services.TeamService
  ) {
    super(); // call to default constructor
  }

  onChartViewChange(event: any) {
    switch(event.chart){
      case('events'): {
        this.isEventsCompletedChartExpanded = !this.isEventsCompletedChartExpanded;
        break;
      }
      case('users_teams'): {
        this.isUserTeamPerformanceChartExpanded = !this.isUserTeamPerformanceChartExpanded;
        break;
      }
      case('time_taken'): {
        this.isUsersActualExpectedChartExpanded = !this.isUsersActualExpectedChartExpanded;
        break;
      }
      case('labor_load'): {
        this.isEventVolumnChartExpanded = !this.isEventVolumnChartExpanded;
        break;
      }
    }

    // sort charts based on clicked chart - the clicked chart comes on the top 
    this.chartsPositions.sort((inp, out) => {
      if (inp.chart === event.chart) {
        return -1;
      } 

      if (out.chart === event.chart) {
        return 1;
      }

      return inp.position - out.position
    })
    this.getChartsClasses();
  }

  getChartsClasses() {
    this.eventsChartClasses = '';
    this.eventsChartClasses = this.isEventsCompletedChartExpanded ? `col-sm-12 order-${this.chartsPositions.findIndex(elem => elem.chart === 'events') + 1}` : `col-md-6 order-${this.chartsPositions.findIndex(elem => elem.chart === 'events') + 1}`;
    this.usersAndTeamsChartClasses = '';
    this.usersAndTeamsChartClasses = this.isUserTeamPerformanceChartExpanded ? `col-sm-12 order-${this.chartsPositions.findIndex(elem => elem.chart === 'users_teams') + 1}` : `col-md-6 order-${this.chartsPositions.findIndex(elem => elem.chart === 'events') + 1}`;
    this.timeTakenChartClasses = '';
    this.timeTakenChartClasses = this.isUsersActualExpectedChartExpanded ? `col-sm-12 order-${this.chartsPositions.findIndex(elem => elem.chart === 'time_taken') + 1}` : `col-md-6 order-${this.chartsPositions.findIndex(elem => elem.chart === 'events') + 1}`;
    this.laborLoadChartClasses = '';
    this.laborLoadChartClasses = this.isEventVolumnChartExpanded ? `col-sm-12 order-${this.chartsPositions.findIndex(elem => elem.chart === 'labor_load') + 1}` : `col-md-6 order-${this.chartsPositions.findIndex(elem => elem.chart === 'events') + 1}`;
    const element = document.querySelector('#chartSection');
    element.scrollIntoView();
  }

  ngOnInit(): void {

  }

  ngOnDestory(): void {
    if(this.collectionGroupSub != null){
      this.collectionGroupSub();
    }
  }

  canDeactivate(): boolean {
    return true;
  }

  // method to filter and plot events for the default range of last month by default, 
  async setChartEvents() {
    this.gettingEvents = true;
    this.filteredEvents = this.chartData; // all events 
    this.gettingEvents = false;
  }

  onFilter(result: any) {
    this.harvestIds = result.harvestIds;
    this.groupIds = result.groupIds;
    this.phases = result.phases;
    this.filterTeamIds = result.teamIds;
    this.filterUserIds = result.userIds;
    this.setChartEvents();
  }

  async initialLoadFromFilter(result: any) {
    await this.helperService.wait(10);
    this.eventSubs = [];
    this.harvestIds = result.harvestIds;
    this.groupIds = result.groupIds;
    this.initialLoad();
  }

  async initialLoad() {
    let companyId = this.helperService.currentCompanyId;
    this.users = await this.userService.getUsersForCompanyWithCaching(companyId, true, true)
    this.retrievedUsers = true;

    this.teams = await this.getTeams()
    this.retrievedTeams = true;

    if(this.collectionGroupSub){
      this.collectionGroupSub();
    }

    this.loadEventsFromColGroup();
  }

  loadEventsFromColGroup() {
    if(this.collectionGroupSub){
      this.collectionGroupSub();
    }
    this.gettingEvents = true;
    this.collectionGroupSub = onSnapshot(this.eventService.getColGroup(), (response) => {
      this.gettingEvents = true;
      this.eventSubs = this.eventSubs.filter(i => response.docs.map(j => j.id).includes(i.eventListId))
      response.forEach(element => {
        let dbEventObj: models.DbEvent = element.data();
        let list: Array<models.Event> = dbEventObj.list;

        let type: string = element.ref.parent.parent.parent.id;
        //type = type.substring(type.lastIndexOf('/'));

        let parentId: string = element.ref.parent.parent.id;
        //type = type.substring(type.lastIndexOf('/'));

        let eventSub: EventSubscription;
        eventSub = this.eventSubs.find(i => i.eventListId == element.id);

        let addEventSub: boolean = false;
        if(eventSub == null) {
          addEventSub = true;
          eventSub = {id: parentId, events: [], allEvents: []};
        }

        eventSub.eventListId = element.id;
        eventSub.eventList = list;

        let chartEvents: Array<any> = []
        list.forEach((dbevent, index) => {
          if(dbevent.id == null) {
            dbevent.id = this.firestore.createId()
          }

          let event: models.ChartEventInput = {
            id: dbevent.id,
            date: dbevent.startDateTime.toDate(),
            eventObj: dbevent,
            type: type,
            parentId: parentId,
            completed: dbevent.completed, // adding completed event for filters later 
            assignedUserIds: dbevent.assignedUserIds ? dbevent.assignedUserIds : [],
            assignedTeamIds: dbevent.assignedTeamIds ? dbevent.assignedTeamIds : []
          }
          chartEvents.push(event);
        });

        eventSub.events = chartEvents;
        eventSub.allEvents = chartEvents;
        eventSub.completedInitialGet = true;

        if(addEventSub){
          this.eventSubs.push(eventSub)
        }
      })
      
      // for performance or labor load cards
      this.allEvents = this.eventSubs.flatMap(i => i.allEvents);
      this.setChartEvents();
    })
  }

  async getTeams(): Promise<models.Team[]> {
    let teams: models.Team[] = [];
    let teamResponse = await (await this.teamService.getAll().get()).toPromise()
    teamResponse.docs.forEach(doc => {
      let team: models.Team = doc.data();
      team.uid = doc.id;
      teams.push(team);
    })
    return teams;
  }

  resetChartView(event: any) {
    this.isEventsCompletedChartExpanded = event.expand;
    this.isUserTeamPerformanceChartExpanded = event.expand;
    this.isUsersActualExpectedChartExpanded = event.expand;
    this.isEventVolumnChartExpanded = event.expand;
    this.getChartsClasses();

    this.eventsCompletedStackedChartComponent.resetFilters();
    this.usersTeamsPerformanceChartComponent.resetFilters();
    this.usersActualAndEstimatedTimeChartComponent.resetFilters();
    this.eventVolumeComboChartComponent.resetFilters();
  }
}

interface EventSubscription {
  id: string;
  eventListId?: string;
  events: Array<models.ChartEventInput>; //filtered events - to plot on chart
  allEvents: models.ChartEventInput[]; //all events in calendar format - unfiltered
  eventList?: Array<models.Event>; //all events in the model of what is in the system
  completedInitialGet?: boolean;
}