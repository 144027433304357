
import { NgModule } from '@angular/core';
//services
import { 
  AuthService
  , GoogleAnalyticsService
  , UserService
  , ClaimsService
  , TemplateService
  , HelperService
  , HarvestService
  , EventService
  , CompanyService
  , RoleService
  , NotificationService
  , DemoRequestService
  , GroupService
  , FileService
  , CanDeactivateGuard
  , CanActivateGuard
  , TourService
  , StripeService
  , AppStorage
  , SquareService
  , QuestionService
  , NotifierService,
  TeamService,
  SystemSettingsService,
  ZoneService,
  StrainService,
  ProcessService,
  PostHarvestService,
  AgGridPinningService,
  SensorService,
  GridHeaderService,
  CalendarService,
  TemplateStepListHelperService,
  AttachmentService,
  LeaflogixService,
  FilterSetService,
  StopwatchHelperService,
  HarvestDocumentService,
  MigrationService,
  CalendarEventService,
  AlertService,
  TemplatedTasksService,
  DataLibraryService,
  SidebarHelperService
} from './services';

@NgModule({
  declarations: [
  ],
  imports: [
  ],
  exports: [
  ],
  providers: [
    AuthService,
    GoogleAnalyticsService,
    UserService,
    ClaimsService,
    HelperService,
    TemplateService,
    HarvestService
    , EventService,
    CompanyService,
    RoleService,
    NotificationService,
    DemoRequestService,
    GroupService,
    FileService,
    CanDeactivateGuard,
    CanActivateGuard,
    TourService,
    StripeService,
    SquareService,
    QuestionService,
    NotifierService,
    TeamService,
    SystemSettingsService,
    ZoneService,
    StrainService,
    ProcessService,
    PostHarvestService,
    AgGridPinningService,
    CalendarService,
    SensorService,
    GridHeaderService,
    TemplateStepListHelperService,
    AppStorage,
    AttachmentService,
    LeaflogixService,
    FilterSetService,
    StopwatchHelperService,
    HarvestDocumentService,
    MigrationService,
    CalendarEventService,
    AlertService,
    TemplatedTasksService,
    DataLibraryService,
    SidebarHelperService
    //{ provide: HTTP_INTERCEPTORS, useClass: LoadingInterceptor, multi: true }
  ],
  bootstrap: []
})
export class ServiceModule { }
