import { Injectable } from '@angular/core';
import { LocalStorageService, SessionStorageService } from 'angular-web-storage';
import * as moment from 'moment-timezone';
import { Subject, Observable } from 'rxjs';
import 'firebase/firestore';
import * as models from 'app/models';
import { Timestamp } from 'firebase/firestore';
import { ActivatedRoute, Router } from '@angular/router';

@Injectable()
export class HelperService {

  constructor(
    private storage: SessionStorageService
    , private localStorage: LocalStorageService
    , private route: ActivatedRoute
    , private router: Router
  ) {
  }

  delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  timestampToDateString(timestamp: Timestamp): string {
    let date = new Date(timestamp.seconds * 1000);
    return date.toDateString()
  }

  timestampToDateTimeString(timestamp: Timestamp): string {
    let date = new Date(timestamp.seconds * 1000);
    return date.toLocaleDateString() + ' ' + date.toLocaleTimeString()
  }

  timestampToDate(timestamp: Timestamp): Date {
    return new Date(timestamp.seconds * 1000);
  }

  timestampToFormattedDateString(timestamp: Timestamp): string {
    if(timestamp == null){
      return ''
    }
    let date = new Date(timestamp.seconds * 1000);
    // return date.toLocaleDateString('en-US')
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(1);
    const year = date.getFullYear().toString().slice(-2);
    const formattedDate = `${month}/${day}/${year}`;
    return formattedDate;
  }

  dateStringToTimestamp(dateString: string) {
    const date = new Date(dateString); // Create a Date object from the string
    const timestamp = date.getTime(); // Get the timestamp in milliseconds
    let firebaseTimestamp = new Timestamp(Math.floor(timestamp / 1000), 0); // Return the timestamp in seconds
    return firebaseTimestamp;
  }

  calculateCurrentDayForHarvest(startDate: Timestamp, endDate: Timestamp): string {
    if(startDate == null || endDate == null){
      return ''
    }
    let currentDate = new Date();
    let diffTime = currentDate.getTime() - this.timestampToDate(startDate).getTime() 
    const currentDayOfHarvest = Math.floor(diffTime / (1000 * 60 * 60 * 24));
    const start2endDiff = this.timestampToDate(endDate).getTime() - this.timestampToDate(startDate).getTime()
    const totalDays = Math.floor(start2endDiff / (1000 * 60 * 60 * 24));
    const past_due = currentDayOfHarvest > totalDays;
    const yet_to_start = currentDayOfHarvest < 1;
    return past_due ? 'PAST DUE' : (yet_to_start ? `0/${totalDays}` : `${currentDayOfHarvest}/${totalDays}`);
  }

  calculateHarvestDurationInDays(startDate: Timestamp, endDate: Timestamp): string {
    const start2endDiff = this.timestampToDate(endDate).getTime() - this.timestampToDate(startDate).getTime()
    const totalDays = Math.floor(start2endDiff / (1000 * 60 * 60 * 24));
    return `${totalDays}`
  }

  calculateDaysBetweenDates(startDate: Date, endDate: Date): number {
    // Convert JavaScript Date objects to Moment objects
    const start = moment(startDate);
    const end = moment(endDate);
    
    // Use Moment's diff method to calculate the difference in days
    return end.diff(start, 'days');
  }

  removeDuplicatesFromPhases(phases: Array<any>) {
    let precutPhases = [];
    let postcutPhases = [];
    let _phaseFound = false;
    phases.forEach((phase, index) => {
      if (phase.harvestAtEnd) {
        precutPhases.push(phase);
        _phaseFound = true;
      } else if (!_phaseFound) {
        _phaseFound = false;
        precutPhases.push(phase);
      } else {
        postcutPhases.push(phase);
      }
    })
    let allPhases = [...precutPhases, ...postcutPhases];
    let resultPhases: Array<any> = allPhases.reduce((acc, curr) => {
      const name: string = curr['name'];
      if(!acc[name] || curr.harvestAtEnd) {
        acc[name] = curr
      }
      return acc;
    }, {});
    return Object.values(resultPhases)
  }

  emailIsValid(email: string) {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
  }

  capitalizeFirstLetter(s: string) {
    return s.charAt(0).toUpperCase() + s.slice(1);
  }

  /**
   * should work for either timestamp or date object
   * @param dateField name of the field sorting by
   * @param array to sort
   */
  sortByDateField(dateField: string, array) {
    array = array.sort((obj1, obj2) => {
      let date1 = obj1[dateField];
      let date2 = obj2[dateField];
      if (date1 > date2) {
        return 1;
      }

      if (date1 < date2) {
        return -1;
      }

      return 0;
    });
    return array;
  }

  /**
   * should work for either timestamp or date object
   * @param dateField name of the field sorting by
   * @param array to sort
   */
  sortByDateFieldDesc(dateField: string, array) {
    array = array.sort((obj1, obj2) => {
      let date1 = obj1[dateField];
      let date2 = obj2[dateField];
      if (date1 < date2) {
        return 1;
      }

      if (date1 > date2) {
        return -1;
      }

      return 0;
    });
    return array;
  }

  sortDesc(field: string, array: any) {

    array = array.sort((obj1, obj2) => {
      let int1 = parseInt(obj1[field]);
      let int2 = parseInt(obj2[field]);
      if (int1 > int2) {
        return 1;
      }

      if (int1 < int2) {
        return -1;
      }

      return 0;
    });
    return array;
  }

  /**
   * will take in an array of any kind and will sort by the field passed in in descending order
   * @param inArray
   */
  sortArrayByStringField(inArray: Array<any>, field: string): Array<any> {

    let sortedArray = inArray.sort(function (a, b) {
      var nameA = a[field].toUpperCase(); // ignore upper and lowercase
      var nameB = b[field].toUpperCase(); // ignore upper and lowercase
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }

      // names must be equal
      return 0;
    });

    return sortedArray;
  }

  sortArrayByStringFieldDesc(inArray: Array<any>, field: string): Array<any> {

    let sortedArray = inArray.sort(function (a, b) {
      var nameA = a[field].toUpperCase(); // ignore upper and lowercase
      var nameB = b[field].toUpperCase(); // ignore upper and lowercase
      if (nameB < nameA) {
        return -1;
      }
      if (nameB > nameA) {
        return 1;
      }

      // names must be equal
      return 0;
    });

    return sortedArray;
  }

  /**
   * will take in an array of any kind and will sort by the field passed in in descending order
   * @param inArray
   */
  sortArrayByNumberField(inArray: Array<any>, field: string): Array<any> {

    let sortedArray = inArray.sort(function (a, b) {
      return a[field] - b[field];
    });

    return sortedArray;
  }

  /**
   * will take in an array of any kind and will sort by the field passed in in descending order
   * @param inArray
   */
  sortArrayByStringIntField(inArray: Array<any>, field: string): Array<any> {

    let sortedArray = inArray.sort(function (a, b) {
      return parseInt(a[field]) - parseInt(b[field]);
    });

    return sortedArray;
  }

  deepCopy(obj) {
    var copy;

    // Handle the 3 simple types, and null or undefined
    if (null == obj || "object" != typeof obj) return obj;

    // Handle Date
    if (obj instanceof Date) {
      copy = new Date();
      copy.setTime(obj.getTime());
      return copy;
    }

    // Handle Array
    if (obj instanceof Array) {
      copy = [];
      for (var i = 0, len = obj.length; i < len; i++) {
        copy[i] = this.deepCopy(obj[i]);
      }
      return copy;
    }

    // Handle Object
    if (obj instanceof Object) {
      copy = {};
      for (var attr in obj) {
        if (obj.hasOwnProperty(attr)) copy[attr] = this.deepCopy(obj[attr]);
      }
      return copy;
    }

    throw new Error("Unable to copy obj! Its type isn't supported.");
  }

  addDaysToDate(date: Date, days: number): Date {
    date.setDate(date.getDate() + days);
    return date;
  }

  addMonthToDate(date: Date, months: number): Date {
    var d = date.getDate();
    date.setMonth(date.getMonth() + +months);
    if (date.getDate() != d) {
      date.setDate(0);
    }
    return date;
  }

  addYearsToDate(date: Date, years: number): Date {
    var year = date.getFullYear();
    var month = date.getMonth();
    var day = date.getDate();
    var c = new Date(year + years, month, day)

    return c;
  }

  addYearsToToday(years: number): Date {
    var d = new Date();
    var year = d.getFullYear();
    var month = d.getMonth();
    var day = d.getDate();
    var c = new Date(year + years, month, day)

    return c;
  }

  removeEmptyFromList(list) {
    for (let i = 0; i < list.length; i++) {
      const element = list[i];
      list[i] = this.removeEmpty(element);
    }

    return list;
  }

  removeEmpty = (obj) => {
    Object.keys(obj).forEach(key => {
      if (obj[key] && typeof obj[key] === 'object') this.removeEmpty(obj[key]);
      else if (obj[key] === undefined) delete obj[key];
    });
    return obj;
  };

  concatCompanyIds(companyIds: Array<string>): string {
    if (companyIds == null) {
      return '';
    }
    return companyIds.join('|');
  }

  extractCompanyIds(companyString: string): Array<string> {
    return companyString.split('|');
  }

  /**
   * will take in the amount of time to wait and will return a promise that will wait that long
   * @param miliseconds 
   */
  wait(miliseconds): Promise<any> {
    return new Promise(res => setTimeout(res, miliseconds));
  }

  /**
   * will return the companyId reliably wait for 15*250 milliseconds
   */
  getCompanyIds(): Promise<Array<string>> {

    return new Promise<Array<string>>(async (resolve, reject) => {
      let tries = 15;
      let response: Array<string> = this.storage.get('companyIds');

      while (response == null && tries > 0) {
        await this.wait(250);
        response = this.storage.get('companyIds');
        tries--;
      }

      if (response != null) {
        resolve(response);
      } else {
        reject('An Error occurred, please refresh the page')
      }
    });
  }

  // /**
  //  * will return the companyId reliably wait for 15*250 milliseconds
  //  */
  // public getCompanyId(): Promise<string> {

  //   return new Promise<string>(async (resolve, reject) => {
  //     let tries = 15;
  //     let response: string = this.storage.get('companyId');

  //     while(response == null && tries > 0){
  //       await this.wait(250);
  //       response = this.storage.get('companyId');
  //       tries--;
  //     }

  //     if(response != null){
  //       resolve(response);
  //     }else {
  //       reject('An Error occurred, please refresh the page')
  //     }
  //   });
  // }


  /*
   * will return the companyId
   */
  private _currentCompanyId = new Subject<any>();
  getCurrentCompanyId(): Observable<any> {
    return this._currentCompanyId.asObservable();
  }
  set currentCompanyId(currentCompanyId: string) {
    if (currentCompanyId === null)
      sessionStorage.removeItem('companyId');
    else
      sessionStorage.setItem('companyId', currentCompanyId);
    this._currentCompanyId.next(currentCompanyId);
  }
  get currentCompanyId() {
    return sessionStorage.getItem('companyId');
  }

  private _company_freeVersion = new Subject<any>();
  getcompany_freeVersion(): Observable<any> {
    return this._company_freeVersion.asObservable();
  }
  set company_freeVersion(company_freeVersion: boolean) {
    if (company_freeVersion === null)
      sessionStorage.removeItem('company_freeVersion');
    else
      sessionStorage.setItem('company_freeVersion', JSON.stringify(company_freeVersion));
    this._company_freeVersion.next(company_freeVersion);
  }
  get company_freeVersion() {
    let value = sessionStorage.getItem('company_freeVersion') || 'false'
    return JSON.parse(value);
  }

  private _currentCompany = new Subject<models.Company>();
  getCurrentCompany(): Observable<models.Company> {
    return this._currentCompany.asObservable();
  }
  set currentCompany(currentCompany: models.Company) {
    if (currentCompany === null)
      sessionStorage.removeItem('currentCompany');
    else
      sessionStorage.setItem('currentCompany', JSON.stringify(currentCompany));
    this._currentCompany.next(currentCompany);
  }
  get currentCompany() {
    let value = sessionStorage.getItem('currentCompany')
    return JSON.parse(value);
  }

  // private _company_subscriptionCancelled = new Subject<any>();
  // getcompany_subscriptionCancelled(): Observable<any> {
  //   return this._company_subscriptionCancelled.asObservable();
  // }
  // set company_subscriptionCancelled(company_subscriptionCancelled: boolean) {
  //   if (company_subscriptionCancelled === null)
  //     sessionStorage.removeItem('company_subscriptionCancelled');
  //   else
  //     sessionStorage.setItem('company_subscriptionCancelled', JSON.stringify(company_subscriptionCancelled));
  //   this._company_subscriptionCancelled.next(company_subscriptionCancelled);
  // }
  // get company_subscriptionCancelled() {
  //   let value = sessionStorage.getItem('company_subscriptionCancelled') || 'false'
  //   return JSON.parse(value);
  // }

  randomString(length: number): string {
    return [...Array(length)].map(i => (~~(Math.random() * 36)).toString(36)).join('');
    //return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
  }


  getTimeZones() {
    // let timezones = [
    //   {
    //     key: 'America/Vancouver',
    //     display: 'Pacific'
    //   },
    //   {
    //     key: 'America/Vancouver',
    //     display: 'Pacific'
    //   },
    //   {
    //     key: 'America/Denver',
    //     display: 'Mountain'
    //   },
    //   {
    //     key: 'America/Chicago',
    //     display: 'Central'
    //   },
    //   {
    //     key: 'America/New_York',
    //     display: 'Eastern'
    //   }
    // ];

    let momentTimeZones = moment.tz.names();

    let timezones = momentTimeZones.map(i => { return { key: i, display: i } })

    return timezones;
  }

  concatToSentence(list: Array<string>) {
    if (list.length < 3) { return list.join(' and '); }
    return `${list.slice(0, - 1).join(', ')}, and ${list[list.length - 1]}`;
  }

  removeAllCacheStartsWith(prefix: string) {
    for (var key in localStorage) {
      if(key.startsWith(prefix)){
        this.localStorage.remove(key);
      }
    }
  }

  sum(array: Array<number>) {
    return array.reduce((sum, current) => sum + current, 0)
  }

  getCurrentDomain() {
    var currentLocation = window.location;
    let domain = `${currentLocation.protocol}//${currentLocation.hostname}`;
    if(domain.includes('localhost')){
      domain += `:${currentLocation.port}`;
    }
    return domain;
  }

  getDayOfWeek(date: Date) {
    if(date == null){
      return null
    }

    const weekdays = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
    const weekdayName = weekdays[date.getDay()];

    return weekdayName;
  }
  
  hasOverlap(list1: Array<any>, list2: Array<any>) {
    const set1 = new Set(list1);
    for (let i = 0; i < list2.length; i++) {
      if (set1.has(list2[i])) {
        return true;
      }
    }
    return false;
  }

  createDateRange(previousDate: Date, currentDate: Date) {
    const datesInRange = [];
    const date = new Date(previousDate.getTime());
    while (date <= currentDate) {
      datesInRange.push(new Date(date));
      date.setDate(date.getDate() + 1);
    }
    return datesInRange;
  }

  aggregateDataByDay(eventsMap: Map<string, number>): Map<string, number> {
    // Helper function to aggregate data by day
    const dailyDataMap: Map<string, number> = new Map();
    eventsMap.forEach((count, date) => {
      const key = date.slice(0, 10); // Extract the date part (e.g., '2022-07-30')
      const existingCount = dailyDataMap.get(key) || 0;
      dailyDataMap.set(key, existingCount + count);
    });
    return dailyDataMap;
  }
  
  aggregateDataByWeek(eventsMap: Map<string, number>): Map<string, number> {
    // Helper function to aggregate data by week
    const weeklyDataMap: Map<string, number> = new Map();
    eventsMap.forEach((count, date) => {
      const weekStart = this.getWeekStartDate(new Date(date));
      const weekStartDateStr = weekStart.toISOString().slice(0, 10);
      const weekEnd = new Date(weekStart);
      weekEnd.setDate(weekStart.getDate() + 6);
      const weekEndDateStr = weekEnd.toISOString().slice(0, 10);
      const key = `${weekStartDateStr} - ${weekEndDateStr}`;
      if (weeklyDataMap.has(key)) {
        weeklyDataMap.set(key, weeklyDataMap.get(key)! + count);
      } else {
        weeklyDataMap.set(key, count);
      }
    });
    return weeklyDataMap;
  }

  getWeekStartDate(date: Date): Date {
    // Helper function to get the start date of the week (Sunday)
    const weekStart = new Date(date);
    weekStart.setDate(date.getDate() - date.getDay());
    return weekStart;
  }
  

  aggregateDataByMonth(eventsMap: Map<string, number>) {
    const monthlyDataMap: Map<string, number> = new Map();
    eventsMap.forEach((count, date) => {
      const month = date.toString().slice(0, 7); // Extract the year and month part from the date (e.g., '2022-07')
      const existingCount = monthlyDataMap.get(month) || 0;
      monthlyDataMap.set(month, existingCount + count);
    });
    return monthlyDataMap;
  }

  aggregateDataByQuarter(eventsMap: Map<string, number>): Map<string, number> {
    // Helper function to aggregate data by quarter
    const quarterlyDataMap: Map<string, number> = new Map();
    eventsMap.forEach((count, date) => {
      const year = date.slice(0, 4); // Extract the year part from the date (e.g., '2022')
      const quarter = this.getQuarterFromDate(new Date(date));
      const key = `${year}-Q${quarter}`;
      const existingCount = quarterlyDataMap.get(key) || 0;
      quarterlyDataMap.set(key, existingCount + count);
    });
    // Sort the keys (quarter labels) based on the chronological order of quarters
    const sortedQuarterLabels = Array.from(quarterlyDataMap.keys()).sort();

    // Create a new Map with the sorted keys and their corresponding values
    const sortedQuarterlyDataMap: Map<string, number> = new Map();
    sortedQuarterLabels.forEach((label) => {
      sortedQuarterlyDataMap.set(label, quarterlyDataMap.get(label)!);
    });

    return sortedQuarterlyDataMap;
  }
  
  getQuarterFromDate(date: Date): number {
    // Helper function to get the quarter from a date
    return Math.ceil((date.getMonth() + 1) / 3);
  }

  aggregateDataByYear(eventsMap: Map<string, number>): Map<string, number> {
    const yearlyDataMap: Map<string, number> = new Map();
  
    eventsMap.forEach((count, date) => {
      const year = date.slice(0, 4); // Extract the year part from the date (e.g., '2022')
  
      if (yearlyDataMap.has(year)) {
        yearlyDataMap.set(year, yearlyDataMap.get(year)! + count);
      } else {
        yearlyDataMap.set(year, count);
      }
    });
  
    return yearlyDataMap;
  }

  computeDatesRange(option: string, startDate: any = null, endDate: any = null): Array<string> {
    let datesInRange = [];
    let currentDate = new Date();
    let previousDate = new Date();
    if (option === 'last_week') {  
      previousDate.setDate(currentDate.getDate() - 7);
      datesInRange = this.createDateRange(previousDate, currentDate);
    } else if (option === 'last_month') {
      previousDate.setDate(currentDate.getDate() - 30);
      datesInRange = this.createDateRange(previousDate, currentDate);
    } else if (option === 'last_quarter') {
      previousDate.setDate(currentDate.getDate() - 90);
      datesInRange = this.createDateRange(previousDate, currentDate);
    } else if (option === 'last_year') {
      previousDate.setDate(currentDate.getDate() - 365);
      datesInRange = this.createDateRange(previousDate, currentDate);
    } else if (option === 'all_time') {
      previousDate.setDate(currentDate.getDate() - 700);
      datesInRange = this.createDateRange(previousDate, currentDate);
    } else if (option === 'custom') {
      datesInRange = this.createDateRange(new Date(startDate), new Date(endDate));
    } else if (option === 'week') { // for Event Volume Chart filters - current date should be middle
      const startDateOfWeek = new Date(currentDate);
      startDateOfWeek.setDate(currentDate.getDate() - 3);

      const endDateOfWeek = new Date(currentDate);
      endDateOfWeek.setDate(currentDate.getDate() + 3);
      datesInRange = this.createDateRange(new Date(startDateOfWeek), new Date(endDateOfWeek));
    } else if (option === 'month') {
      const startDateOfMonth = new Date(currentDate);
      startDateOfMonth.setDate(currentDate.getDate() - 15);

      const endDateOfMonth = new Date(currentDate);
      endDateOfMonth.setDate(currentDate.getDate() + 15);
      datesInRange = this.createDateRange(new Date(startDateOfMonth), new Date(endDateOfMonth));
    } else if (option === 'year') {
      const startDateOfYear = new Date(currentDate);
      startDateOfYear.setDate(currentDate.getDate() - 182);

      const endDateOfYear = new Date(currentDate);
      endDateOfYear.setDate(currentDate.getDate() + 182);
      datesInRange = this.createDateRange(new Date(startDateOfYear), new Date(endDateOfYear));
    }
    return datesInRange;
  }

  computeDateWiseEventsCount(events: models.ChartEventInput[], dates: string[]): Map<string, number> {
    
    // make a map for events count on all the dates
    const dateEventsCountMap = new Map<string, number>();
    // count events on each date
    events.forEach(event => {
      const date = event.date.toISOString().split("T")[0];
      if(dateEventsCountMap.has(date)){
        dateEventsCountMap.set(date, dateEventsCountMap.get(date)! + 1);
      } else {
        dateEventsCountMap.set(date, 1);
      }
    })

    // map the events count on their dates for selected date range
    const filteredMap = new Map<string, number>();
    dates?.forEach((date) => {
      let _date = new Date(date).toISOString().split("T")[0];
      if (dateEventsCountMap.has(_date)) {
        filteredMap.set(_date, dateEventsCountMap.get(_date));
      } else {
        filteredMap.set(_date, null);
      }
    })
    return filteredMap;
  }

  monthNames: string[] = [
    'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
    'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
  ];

  formatDateForCharts(dates: string[], intervalType: string) {
    let dateRange = [];
    switch (intervalType) {
      case 'Day':
        dateRange = dates.map(date => {
          const parts = date.split('-');
          if (parts.length === 3) {
            return `${parts[1]}/${parts[2]}/${parts[0].slice(-2)}`;
          }
          return date;
        })
        return dateRange;
      case 'Week':
        dateRange = dates.map(weeklyDateRange => {
          let [startWeek, endWeek] = weeklyDateRange.split(" - ");
          const part1 = startWeek.split("-");
          if (part1.length === 3) {
            startWeek = `${part1[1]}/${part1[2]}/${part1[0].slice(-2)}`;
          }
          const part2 = endWeek.split("-");
          if (part2.length === 3) {
            endWeek = `${part2[1]}/${part2[2]}/${part2[0].slice(-2)}`;
          }
          return [startWeek, endWeek].join(" - ");
        })
        break;
      case 'Month':
        dateRange = dates.map(month => {
          let [yy, mm] = month.split("-");
          return `${this.monthNames[parseInt(mm, 10) - 1]}-${yy.slice(-2)}`
        })
        break;
      case 'Quarter':
        dateRange = dates.map(quarter => {
          let [yy, qq] = quarter.split("-");
          return `${qq}-${yy}`;
        })
        break;
      case 'Year': 
        dateRange = dates.map(year => year);
        break;
    }
    return dateRange;
  }
  
  removeSpacesAndLowercaseFirstLetter(inputString: string) {
    // Remove spaces from the string using regex
    const stringWithoutSpaces = inputString.replace(/\s/g, '');
  
    // Convert the first letter to lowercase
    const resultString = stringWithoutSpaces.charAt(0).toLowerCase() + stringWithoutSpaces.slice(1);
  
    return resultString;
  }
  
  getDivHeightByClass(className: string): number | null {
    const element = document.querySelector(`.${className}`) as HTMLElement | null;
    if (element) {
      const height = element.offsetHeight;
      return height;
    } else {
      return null;
    }
  }
  
  setHeightByClass(className: string, heightValue: string): void {
  }

  removeHeightFromClass(className: string): void {
  }

  getDefaultInterval(rangeType: string) {
    let intervalSelected = '';
    switch (rangeType) {
      case 'last_month':
        intervalSelected = 'Day';
        break;
      case 'last_quarter':
        intervalSelected = 'Month';
        break;
      case 'last_week':
        intervalSelected = 'Day';
        break;
      case 'last_year':
        intervalSelected = 'Month';
        break;
      case 'all_time':
        intervalSelected = 'Year';
        break;
      case 'custom': 
        intervalSelected = 'Day';
        break;
      case 'week':
        intervalSelected = 'Day';
        break;
      case 'month':
        intervalSelected = 'Day';
        break;
      case 'year':
        intervalSelected = 'Month';
        break;
      default:
        intervalSelected = 'Interval';
    }
    return intervalSelected;
  }

  getDateRangeTitle(rangeType: string) {
    let rangeTitle = '';
    switch (rangeType) {
      case 'last_month':
        rangeTitle = "Last Month";
        break;
      case 'last_quarter':
        rangeTitle = "Last Quarter";
        break;
      case 'last_week':
        rangeTitle = "Last Week";
        break;
      case 'last_year':
        rangeTitle = "Last Year";
        break;
      case 'all_time':
        rangeTitle = 'All Time';
        break;
      case 'custom':
        rangeTitle = 'Custom Range';
        break;
      case 'week':
        rangeTitle = "Week";
        break;
      case 'month':
        rangeTitle = "Month";
        break;
      case 'year':
        rangeTitle = "Year";
        break;
    }
    return rangeTitle;
  }

  computeUsersToEventsMap(eventsCountObject: any, users: models.User[], removeEmpty: boolean = false): Map<string, number>{
    const usersToEventsMap = new Map<string, number>();
    for (const user of users) {
      let value = eventsCountObject[user.id] ? eventsCountObject[user.id] : null;
      if(removeEmpty) {
        if(value != null) {
          usersToEventsMap.set(user.displayName, value);
        }
      } else {
        usersToEventsMap.set(user.displayName, value);
      }
    }
    return usersToEventsMap;
  }

  computeUsersToEventsUserMap(eventsCountObject: any, users: models.User[]): Map<string, {user, number}>{
    const usersToEventsMap = new Map<string, {user, number}>();
    for (const user of users) {
      usersToEventsMap.set(user.id, {number: eventsCountObject[user.id] ? eventsCountObject[user.id] : null, user: user} )
    }
    return usersToEventsMap;
  }

  computeTeamsToEventsMap(eventsCountObject: any, teams: models.Team[]): Map<string, number>{
    const teamsToEventsMap = new Map<string, number>();
    for (const team of teams) {
      teamsToEventsMap.set(team.name , eventsCountObject[team.uid] ? eventsCountObject[team.uid] : null)
    }
    return teamsToEventsMap;
  }

  // Function to calculate the ratio of completed events to total assigned events
  calculateRatio(completed, total) {
    if (completed === null || total === null || total === 0 || (completed === 1 && total === 1)) {
      return null; // Handle null values or divide by zero scenario
    }
    return completed / total;
  }

  addQueryStringParam(key:string, value:string){
    let query: any = {};
    query[key] = value;
    this.queryStringMergeNoReload(query)
  }
  
  queryStringMergeNoReload(queryParams: any, merge: boolean = true){
    let queryParamsHandling: any = "";
    if(merge){
      queryParamsHandling = "merge";
    }
    this.router.navigate(
      [],
      {
        relativeTo: this.route,
        queryParams: queryParams,
        queryParamsHandling: queryParamsHandling, // remove to replace all query params by provided
      });
  }
  
  isHTMLString(str) {
    // Create a regular expression to match HTML tags
    var htmlRegex = /<\/?[\w\s="/.':;#-\/\?]+>/g;
  
    // Test if the string contains any HTML tags
    return htmlRegex.test(str);
  }

  extractVisibleTextFromHTML(htmlString) {
    // Create a DOMParser instance
    const parser = new DOMParser();
  
    // Parse the HTML string
    const doc = parser.parseFromString(htmlString, 'text/html');
  
    // Function to recursively traverse the DOM tree and extract text
    function extractText(node, result = []) {
      if (node.nodeType === Node.TEXT_NODE) {
        result.push(node.textContent.trim());
      } else if (node.nodeType === Node.ELEMENT_NODE && node.nodeName !== 'SCRIPT' && node.nodeName !== 'STYLE') {
        for (let childNode of node.childNodes) {
          extractText(childNode, result);
        }
      }
      return result;
    }
  
    // Extract text from the parsed DOM
    const textNodes = extractText(doc.body);
  
    // Join the text nodes into a single string
    return textNodes.join(' ');
  }

  getTotalNumberOfWeeks(events: models.ChartEventInput[]) {
    // find the start and end dates
    const startDate = Math.min(...events.map((event) => event.date.getTime()));
    const endDate = Math.max(...events.map((event) => event.date.getTime()));

    // calculate the number of weeks
    const millisecondsInAWeek = 7 * 24 * 60 * 60 * 1000;
    const numberOfWeeks = Math.ceil((endDate - startDate) / millisecondsInAWeek);

    return numberOfWeeks;
  }

  getTotalNumberOfMonths(events: models.ChartEventInput[]) {
    const startDate = Math.min(...events.map((event) => event.date.getTime()));
    const endDate = Math.max(...events.map((event) => event.date.getTime()));

    const monthsBetween = (Number(new Date(endDate).getFullYear()) - Number(new Date(startDate).getFullYear())) * 12 + (Number(new Date(endDate).getMonth()) - Number(new Date(startDate).getMonth()));

    const numberOfMonths = monthsBetween + 1; // Add 1 to include the start month
    return numberOfMonths;
  }

  getTotalNumberOfYears(events: models.ChartEventInput[]) {
    // find the start and end dates
    const startDate = Math.min(...events.map((event) => event.date.getTime()));
    const endDate = Math.max(...events.map((event) => event.date.getTime()));

    const yearsBetween = Number(new Date(endDate).getFullYear()) - Number(new Date(startDate).getFullYear());

    const numberOfYears = yearsBetween + 1; // Add 1 to include the start year
    return numberOfYears;
  }

  // group events by days of week from all events
  groupEventsByDaysOfWeek(events: models.ChartEventInput[]) {
    let eventsByDayOfWeek = {};
    for (const event of events) { 
      let eventDate = new Date(event.date);
      
      // get total events count for each day of a week
      const dayOfWeek = eventDate.getDay();
      if (!eventsByDayOfWeek[dayOfWeek]) {
        eventsByDayOfWeek[dayOfWeek] = [];
      }
      
      eventsByDayOfWeek[dayOfWeek].push(event);
    }

    return eventsByDayOfWeek;
  }

  // compute daily average for the week date range
  computeDayWiseEventsAverageMap(eventsByDayOfWeek: any, dates: string[], numberOfWeeks: number) {

    let dayWiseEventsAverageMap = new Map<string, number>();
    for (const _date of dates) {
      // find day from the date
      let _day = new Date(_date).getDay();
      // map equivalent day average to this date
      let _average = eventsByDayOfWeek[_day] ? eventsByDayOfWeek[_day].length / numberOfWeeks : 0;

      dayWiseEventsAverageMap.set(_date, _average);
    }

    return dayWiseEventsAverageMap;
  }

  // group events by dates month wise from all events
  groupEventsByDatesForMonths(events: models.ChartEventInput[]) {
    let eventsByDates = {};
    for (const event of events) { 
      let eventDate = new Date(event.date);
      
      // get total events count for each date of a month over each the month of all events
      const _date = eventDate.getDate();
      if (!eventsByDates[_date]) {
        eventsByDates[_date] = [];
      }
      eventsByDates[_date].push(event);
    }
    return eventsByDates;
  }
  
  // group events by dates of year wise from all events
  groupEventsByDatesForYears(events: models.ChartEventInput[]) {
    let groupedEventsByDatesOverYears = {};
    for (const event of events) {
      let eventDate = new Date(event.date);
      const _date = eventDate.getDate();
      const _month = eventDate.getMonth() + 1;
      const _year = eventDate.getFullYear();
      
      const key = `${_date}-${_month}`;
      if (!groupedEventsByDatesOverYears[key]) {
          groupedEventsByDatesOverYears[key] = [];
      }

      groupedEventsByDatesOverYears[key].push(event);
    }
    return groupedEventsByDatesOverYears;
  }

  // only when selected interval is day,
  computeDateWiseEventsAverageMap(eventsByDates: any, dates: string[], eventSpanPeriod: number, forRange: string = 'monthly') {
    let dateWiseEventsAverageMap = new Map<string, number>();
    for (const _date of dates) {
      if (forRange === 'monthly') {
        // find day from the date
        let _eventDate = new Date(_date).getDate();
        // map equivalent date average to this date
        let _average = eventsByDates[_eventDate] ? eventsByDates[_eventDate].length / eventSpanPeriod : null;
        dateWiseEventsAverageMap.set(_date, _average);
      } else {
        let _eventDate = new Date(_date).getDate();
        let _eventMonth = new Date(_date).getMonth() + 1;

        let _average = eventsByDates[`${_eventDate}-${_eventMonth}`] ? eventsByDates[`${_eventDate}-${_eventMonth}`].length / eventSpanPeriod : null;
        dateWiseEventsAverageMap.set(_date, _average);
      }
    }
    return dateWiseEventsAverageMap;
  }

  // only when selected interval is month,
  computeMonthWiseEventsAverageMap(eventsByMonths: any, dates: string[], eventSpanPeriod: number,) {
    let monthWiseEventsAverageMap = new Map<string, number>();
    for (const _date of dates) {
        // find day from the date
        let _eventDate = new Date(_date);
        const year = _eventDate.getFullYear();
        const month = String(_eventDate.getMonth() + 1).padStart(2, '0'); 
        const monthYearKey = `${year}-${month}`;
        // map equivalent date average to this date
        let _average = eventsByMonths[monthYearKey] ? eventsByMonths[monthYearKey].length / eventSpanPeriod : null;
        monthWiseEventsAverageMap.set(monthYearKey, _average);
    }
    return monthWiseEventsAverageMap;
  }

  getWeekNumberOfMonth(date) {
    const monthStart = new Date(date.getFullYear(), date.getMonth(), 1);
    const diff = date.getDate() - monthStart.getDate();
    return Math.ceil((diff + 1 + monthStart.getDay()) / 7);
  }

  getWeekDates(year: number, month: number, weekNumber: number) {

    const firstDayOfMonth = new Date(year, month - 1, 1);
  
    // Calculate the day of the week (0 = Sunday, 1 = Monday, ..., 6 = Saturday) for the first day of the month
    const firstDayOfWeek = firstDayOfMonth.getDay();
  
    // Calculate the date of the first day of the target week
    const weekStartDate = new Date(year, month - 1, 1 + (weekNumber - 1) * 7 - firstDayOfWeek);
  
    // Calculate the date of the last day of the target week
    const weekEndDate = new Date(year, month - 1, weekStartDate.getDate() + 6);
  
    return { weekStartDate, weekEndDate };
  }

  // group events by weeks - for month of all events
  groupEventsByWeekOfMonth(events: models.ChartEventInput[]) {
    const groupedEventsForAWeekOfMonth = {};
  
    events.forEach((event) => {
      const eventDate = new Date(event.date);
      const weekNumberOfMonth = this.getWeekNumberOfMonth(eventDate);

      if (!groupedEventsForAWeekOfMonth[weekNumberOfMonth]) {
        groupedEventsForAWeekOfMonth[weekNumberOfMonth] = [];
      }
      groupedEventsForAWeekOfMonth[weekNumberOfMonth].push(event);
    });
    
    return groupedEventsForAWeekOfMonth;
  }

  computeWeekWiseEventsAverageMapForMonths(eventsByWeeks: any, dates: string[], numberOfWeeks: number) {
    let weeklyAverageMap = new Map<string, number>();
    for (const _date of dates) {
      let eventDate = new Date(_date);

      let weekNumberOfMonth = this.getWeekNumberOfMonth(eventDate);

      let _average = eventsByWeeks[weekNumberOfMonth] ? eventsByWeeks[weekNumberOfMonth].length / numberOfWeeks : null;

      const weekStart = this.getWeekStartDate(eventDate);
      const weekStartDateStr = weekStart.toISOString().slice(0, 10);
      const weekEnd = new Date(weekStart);
      weekEnd.setDate(weekStart.getDate() + 6);
      const weekEndDateStr = weekEnd.toISOString().slice(0, 10);
      const key = `${weekStartDateStr} - ${weekEndDateStr}`;

      weeklyAverageMap.set(key, _average);
    }
    return weeklyAverageMap;
  }

  groupEventsByMonthsOverYears(events: models.ChartEventInput[]) {
    const groupedEvents = {};

    events.forEach((event) => {
      const eventDate = new Date(event.date);
      const year = eventDate.getFullYear();
      const month = String(eventDate.getMonth() + 1).padStart(2, '0'); 
      const monthYearKey = `${year}-${month}`;
      if (!groupedEvents[monthYearKey]) {
        groupedEvents[monthYearKey] = [];
      }

      groupedEvents[monthYearKey].push(event);
    });

    return groupedEvents;
  }

  groupEventsByQuartersOverYears(events: models.ChartEventInput[]) {
    const groupedEvents = {};

    for (const event of events) {
      let eventDate = new Date(event.date);
      const year = eventDate.getFullYear();
      const quarter = this.getQuarterFromDate(new Date(eventDate));

      const key = `${year}-Q${quarter}`;
      if (!groupedEvents[key]) {
        groupedEvents[key] = [];
      }

      groupedEvents[key].push(event);
    }
    return groupedEvents;
  }

  getTotalNumberOfQuarters(events: models.ChartEventInput[]) {
    const startDate = Math.min(...events.map((event) => event.date.getTime()));
    const endDate = Math.max(...events.map((event) => event.date.getTime()));

    const startYear = new Date(startDate).getFullYear();
    const startQuarter = Math.ceil((new Date(startDate).getMonth() + 1) / 3); // Calculate the quarter for the start date
    const endYear = new Date(endDate).getFullYear();
    const endQuarter = Math.ceil((new Date(endDate).getMonth() + 1) / 3); // Calculate the quarter for the end date

    // Calculate the total number of quarters
    const totalQuarters = (endYear - startYear) * 4 + (endQuarter - startQuarter + 1);

    return totalQuarters;
  }

  computeQuartersWiseEventsAverageMap(eventsByQuarters: any, dates: string[], numberOfQuarters: number) {
    let quarterWiseEventsAverageMap = new Map<string, number>();
    for (const _date of dates) {
      // find day from the date
      let _eventDate = new Date(_date);
      const year = _eventDate.getFullYear();
      const quarter = this.getQuarterFromDate(new Date(_eventDate));

      const key = `${year}-Q${quarter}`;
      // map equivalent date average to this date
      let _average = eventsByQuarters[key] ? eventsByQuarters[key].length / numberOfQuarters : null;
      quarterWiseEventsAverageMap.set(key, _average);
  }
  return quarterWiseEventsAverageMap;
  }

  groupEventsByWeeksOverYears(events: models.ChartEventInput[]) {
    const groupedEvents = {};
    for (const event of events) {
      const weekStart = this.getWeekStartDate(new Date(event.date));
      const weekStartDateStr = weekStart.toISOString().slice(0, 10);
      const weekEnd = new Date(weekStart);
      weekEnd.setDate(weekStart.getDate() + 6);
      const weekEndDateStr = weekEnd.toISOString().slice(0, 10);
      const key = `${weekStartDateStr} - ${weekEndDateStr}`;

      if (!groupedEvents[key]) {
        groupedEvents[key] = [];
      }
      groupedEvents[key].push(event);
    }
    return groupedEvents;
  }

  computeWeeksWiseEventsAverageMap(eventsByWeeks: any, dates: string[], numberOfSpans: number, forInterval: string = 'monthly') {
    let weekWiseEventsAverageMap = new Map<string, number>();

    for (const _date of dates) {
      if (forInterval === 'monthly') {

      } else { // yearly
        const weekStart = this.getWeekStartDate(new Date(_date));
        const weekStartDateStr = weekStart.toISOString().slice(0, 10);
        const weekEnd = new Date(weekStart);
        weekEnd.setDate(weekStart.getDate() + 6);
        const weekEndDateStr = weekEnd.toISOString().slice(0, 10);
        const key = `${weekStartDateStr} - ${weekEndDateStr}`;
        
        const _averages = eventsByWeeks[key] ? eventsByWeeks[key].length / numberOfSpans : null;

        weekWiseEventsAverageMap.set(key, _averages);
      }
    }
    return weekWiseEventsAverageMap;
  }

  getDateForLastMonth() {
    const currentDate = new Date();
    const dateForLastMonth = new Date(currentDate);

    // Check if subtracting one month would result in a year change
    if (currentDate.getMonth() === 0) {
      dateForLastMonth.setFullYear(currentDate.getFullYear() - 1);
      dateForLastMonth.setMonth(11); // December
    } else {
      dateForLastMonth.setMonth(currentDate.getMonth() - 1);
    }

    return dateForLastMonth;
  }

  getDateFor3MonthsPriorLastMonth() {
    const currentDate = new Date();
    const date3MonthsPrior = new Date(currentDate);

    // Check if subtracting 4 months would result in a year change
    if (currentDate.getMonth() === 0) {
      date3MonthsPrior.setFullYear(currentDate.getFullYear() - 1);
      date3MonthsPrior.setMonth(8); // 3 months from next year
    } else {
      date3MonthsPrior.setMonth(currentDate.getMonth() - 4);
    }

    return date3MonthsPrior;
  }

  sortByLaborLoadAndPercentChange(loadWithPercentChange: Array<{name: string, count: number, percentChange: number}>) {

    const loadArray = loadWithPercentChange.filter(item => item.count !== null);

    loadArray.sort((a, b) => {
      // Compare counts
      if (b.count !== a.count) {
        return b.count - a.count;
      }

      // If counts are the same, compare percentIncrease values
      if (b.percentChange !== a.percentChange) {
        return b.percentChange - a.percentChange;
      }

      // If counts are the same, compare usernames alphabetically
      return a.name.localeCompare(b.name);
    });
    
    return loadArray;
  }
}
