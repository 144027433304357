import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MenuPositionX, MenuPositionY } from '@angular/material/menu';
import { NgxMaterialPopoverComponent } from 'app/modules/ngx-material-popover/components';
import { MatOption } from '@angular/material/core';

import * as models from 'app/models';

@Component({
  selector: 'app-multi-select-dropdown-btn',
  templateUrl: './multi-select-dropdown-btn.component.html',
  styleUrls: ['./multi-select-dropdown-btn.component.scss']
})
export class MultiSelectDropdownBtnComponent implements OnInit {
  @ViewChild('popover', { static: true })
  readonly popover!: NgxMaterialPopoverComponent;

  @ViewChild('allSelected') private allSelected: MatOption;

  xPosition: MenuPositionX = 'after';
  yPosition: MenuPositionY = 'below';

  get title() {
    if(this.selectedItems.length == 0) {
      return this.label;
    }
    if(this.selectedItems.length == 1) {
      return this.selectedItems[0].name;
    }
    return this.selectedItems[0].name + ' (+ ' + (this.selectedItems.length - 1) + ' more)';
    //return this.selectedItems.length > 0 ? this.selectedItems.map(i => i.name).join(', ') : this.label;
  }

  @Input() label: string = 'Select';
  @Input() items: models.GenericItem[] = [];

  selectedItemsValue: models.GenericItem[];
  @Input() 
  get selectedItems(): models.GenericItem[] {
    return this.selectedItemsValue;
  };

  @Output() selectedItemsChange = new EventEmitter();
  set selectedItems(val: models.GenericItem[]) {
    if(val && val != null){
      this.selectedItemsValue = val;  
      this.selectedItemsChange.emit(this.selectedItemsValue);
    }
  }

  @Output() onClosed = new EventEmitter();

  isSelectOpen: boolean = false;
  
  constructor(
  ) { }
  
  ngOnInit(): void {
    
  }

  ngOnDestroy(): void {

  }

  closedEvent(event: any) {
    this.onClosed.emit();
  }

  toggleAllSelection(all: boolean) {
    if (all) {
      this.selectedItems = [...this.items];
    } else {
      this.selectedItems = [];
    }
  }
}
