import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import * as models from 'app/models';
import * as services from 'app/services';
import * as viewmodels from 'app/viewmodels';

@Component({
  selector: 'app-filter-set-save-dialog',
  templateUrl: './filter-set-save-dialog.component.html',
  styleUrls: ['./filter-set-save-dialog.component.scss']
})
export class FilterSetSaveDialogComponent implements OnInit {

  filterSet: models.FilterSet;
  saving: boolean = false;

  includeHarvests: boolean = false;
  includeGroups: boolean = false;

  groups: models.Group[] = [];
  zones: models.Zone[] = [];
  teams: models.Team[] = [];
  users: models.User[] = [];

  forAnalytics: boolean = false;

  constructor(
    private dialogRef: MatDialogRef<FilterSetSaveDialogComponent>
    , @Inject(MAT_DIALOG_DATA) public data: any
    , private filterSetService: services.FilterSetService
    , private helperService: services.HelperService
    , private groupService: services.GroupService
    , private zoneService: services.ZoneService
    , private teamService: services.TeamService
    , private userService: services.UserService
  ) { 
    this.filterSet = data;
  }

  ngOnInit(): void {
    this.loadGroups();
    this.loadZones();
    this.loadTeams();
    this.loadUsers();
  }

  async loadGroups() {
    this.groups = await this.groupService.getAllModels();
  }

  async loadZones() {
    this.zones = await this.zoneService.getAll();
  }

  async loadTeams() {
    this.teams = await this.teamService.getAllModels();
  }

  async loadUsers() {
    const companyId: string = this.helperService.currentCompanyId;
    this.users = await this.userService.getUsersForCompanyWithCaching(companyId, true, true);
  }

  groupShowmore: boolean = false;
  get groupSummary() {
    if(this.groups.length == 0 || !this.filterSet.groupIds?.length) {
      return 'None';
    }
    return this.groups.filter(i => this.filterSet.groupIds.includes(i.uid)).map(i => i.name).join(', ');
  }

  zoneShowmore: boolean = false;
  get zoneSummary() {
    if(this.zones.length == 0 || !this.filterSet.zoneIds?.length) {
      return 'All';
    }
    return this.zones.filter(i => this.filterSet.zoneIds.includes(i.id)).map(i => i.name).join(', ');
  }

  phaseShowmore: boolean = false;
  get phaseSummary() {
    if(this.filterSet.phases.length == 0) {
      return 'All';
    }
    return this.filterSet.phases.join(', ');
  }

  teamShowmore: boolean = false;
  get teamSummary() {
    if(this.teams.length == 0 || !this.filterSet.teamIds?.length) {
      return 'All';
    }
    return this.teams.filter(i => this.filterSet.teamIds.includes(i.uid)).map(i => i.name).join(', ');
  }

  userShowmore: boolean = false;
  get userSummary() {
    if(this.users.length == 0 || !this.filterSet.userIds?.length) {
      return 'All';
    }
    return this.users.filter(i => this.filterSet.userIds.includes(i.id)).map(i => i.displayName).join(', ');
  }

  tagShowmore: boolean = false;
  get tagSummary() {
    if(this.filterSet.tags.length == 0) {
      return 'All';
    }
    return this.filterSet.tags.join(', ');
  }

  isEllipsisActive(e) {
    return (e.offsetWidth < e.scrollWidth);
  }

  async save() {
    // if(!this.includeHarvests){
    //   delete this.filterSet.harvestIds
    // }
    // if(!this.includeGroups){
    //   delete this.filterSet.groupIds
    // }

    this.saving = true;
    let id = await this.filterSetService.save(this.filterSet)
    this.dialogRef.close(id);
    this.saving = false;
  }
}