<div id="eventUpdate">

  <h2 mat-dialog-title class="dialog-title">
    Update Event
    <span style="float: right; font-size: 15px; color: rgb(96, 96, 96);">{{event.startDateTime.seconds * 1000 |
      date:'EEEE, MMMM d, y'}}</span>

    <button mat-icon-button aria-label="close dialog" mat-dialog-close>
      <mat-icon>close</mat-icon>
    </button>
  </h2>
  <mat-dialog-content>
    <div class="row" style="padding-bottom: 10px; padding-top: 11px;">
      <div class="col-12">
        <button class="right" [disabled]="!completeEvent" [matBadge]="assignedTeamsUsers" *ngIf="assignUserAbility" style="z-index: 10000"
          mat-raised-button (click)="selectTeamsUsers()">Assign Teams/Users</button>
      </div>
    </div>

    <form id="updateEvent" class="user-form" (ngSubmit)="saveEvent()">
      <div class="row">
        <div class="col-sm-6">
          <table class="detail-table">
              <tr *ngFor="let i of detailTableItems">
                <td>{{i.label}}</td>
                <td>
                  <mat-chip *ngIf="i.value" [routerLink]="[i.link]" [class.clickable]="i.link != null" [ngStyle]="{'background-color': i.color}">
                    {{i.value}}
                  </mat-chip>
                </td>
              </tr>
          </table>

          <ng-container *ngIf="!assignToGroup">
            <app-phase-select [disabled]="!completeEvent" [harvestId]="harvestId" [(phase)]="event.phase"></app-phase-select>
          </ng-container>

          <mat-form-field class="user-full-width" [class.mat-form-field-invalid]="descriptionHasError">
            <input matInput placeholder="Event Description" type="text" name="description" [disabled]="!editEventAbility"
              [(ngModel)]="event.description">
            <mat-hint class="mat-error" *ngIf="descriptionHasError">
              Event Description cannot be empty
            </mat-hint>
          </mat-form-field>

          <!-- <mat-form-field *ngIf="!restrictAccess">
            <mat-label>Assigned User</mat-label>
            <mat-select [(ngModel)]="event.assignedUserId" name="user" (ngModelChange)="userChange($event)">
              <mat-option value="">Unassigned</mat-option>
              <mat-option [value]="u.uid" *ngFor="let u of users">{{u.displayName}}</mat-option>
            </mat-select>
          </mat-form-field> -->

          <mat-checkbox name="highPriority" [disabled]="!completeEvent" *ngIf="editEventAbility" [(ngModel)]="event.highPriority">High Priority
          </mat-checkbox>

          <mat-form-field>
            <input matInput [matDatepicker]="startDatePicker" placeholder="Event Date" [formControl]="startDate"
              [disabled]="true">
            <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #startDatePicker [disabled]="!completeEvent"></mat-datepicker>
          </mat-form-field>

          <div class="row">
            <div class="col-5">
              <mat-checkbox name="anyTime" [disabled]="!completeEvent" [(ngModel)]="event.anyTime" [disabled]="!editEventAbility">Anytime
              </mat-checkbox>
            </div>
            <div class="col-7">
              <mat-form-field class="user-full-width" *ngIf="!event.anyTime && editEventAbility">
                <input matInput name="startTime" type="time" [disabled]="!completeEvent" [(ngModel)]="eventTime" placeholder="Start Time">
              </mat-form-field>
            </div>
          </div>

          <div class="row" *ngIf="event.slidingEnd != null">
            <div class="col-12">
              <mat-form-field class="user-full-width">
                <input matInput name="slidingEndDate" [matDatepicker]="slidingEndDatePicker" placeholder="Sliding End Date" [formControl]="slidingEndDate"
                  [disabled]="true">
                <mat-datepicker-toggle matSuffix [for]="slidingEndDatePicker"></mat-datepicker-toggle>
                <mat-datepicker #slidingEndDatePicker [disabled]="!editEventAbility"></mat-datepicker>
                <mat-hint>Must complete Event by {{slidingEndDate.value | date:'MM/dd/yyyy'}}. It will automatically move until it is completed.</mat-hint>
              </mat-form-field>
            </div>
          </div>

          <!-- <mat-form-field class="user-full-width" *ngIf="!restrictAccess">
            <input matInput placeholder="Precedence" type="number" name="precedence" [(ngModel)]="event.precedence">
          </mat-form-field> -->

          <!-- <mat-form-field>
            <textarea matInput placeholder="Leave a message..." name="notes" [(ngModel)]="notes"></textarea>
          </mat-form-field> -->
        </div>
        <div class="col-sm-6">
          <div class="tooltip-wrapper">
            <mat-checkbox 
              name="completed" 
              [disabled]="!completeEvent || mustCompleteSubtasks"
              [(ngModel)]="event.completed"
              (click)="showTooltip($event)">
              Completed
            </mat-checkbox>
            <span #tooltip="matTooltip"
                  matTooltip="Must complete all subtasks before completing event"
                  matTooltipShowDelay="0"
                  matTooltipHideDelay="3000">
            </span>
          </div>

          <app-chip-list-edit [disabled]="!completeEvent" [label]="'Tags'" [allChips]="allTags" [(chips)]="event.tags"></app-chip-list-edit>

          <h5 *ngIf="event.estimatedTime != null">Estimated Time: {{event.estimatedTime}} (min)</h5>

          <mat-form-field [class.mat-form-field-invalid]="timeSpentHasError">
            <mat-label>Time Spent (minutes)</mat-label>
            <input matInput name="timeSpent" [disabled]="!completeEvent" [(ngModel)]="event.timeSpent" type="number">
            <button type="button" mat-icon-button matSuffix [disabled]="!completeEvent" (click)="stopwatchClick()">
              <mat-icon>timer</mat-icon>
            </button>
            <mat-hint class="mat-error" *ngIf="timeSpentHasError">
              Event must have a Time Spent
            </mat-hint>
          </mat-form-field>

          <ng-container *ngIf="qualityTrackerAbility">
            <h5 style="margin-bottom: 0px;">Quality Tracker</h5>
            <mat-slider style="width: 100%;" thumbLabel [disabled]="!completeEvent" [displayWith]="formatLabel" min="0" max="100" aria-label="units"
              [(ngModel)]="event.efficacy" name="efficacy"></mat-slider>
          </ng-container>

          <h5 style="margin-bottom: 10px;">
            Sub Tasks
            <button mat-icon-button type="button" style="float: right;" *ngIf="editEventAbility" (click)="editSubtasks($event)" [disabled]="event.completed">
              <mat-icon>event_list</mat-icon>
              <!-- <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200" /> -->
            </button>
          </h5>

          <ul *ngIf="!subtasksEmpty" style="padding-inline-start: 10px;">
            <li *ngFor="let subtask of subtasks">
              <mat-checkbox class="subtask_checks" [(ngModel)]="subtask.subtask.completed" [ngModelOptions]="{standalone: true}">
                {{subtask.subtask.title}}
              </mat-checkbox>
              <button mat-icon-button type="button" *ngIf="subtask.subtask.description?.length > 0" (click)="subtaskDescToggle($event, subtask)">
                <mat-icon *ngIf="!subtask.displayDescription">arrow_drop_down</mat-icon>
                <mat-icon *ngIf="subtask.displayDescription">arrow_drop_up</mat-icon>
              </button>

              <p *ngIf="subtask.displayDescription" style="padding-inline-start: 20px;">{{subtask.subtask.description}}</p>
            </li>
          </ul>
          <div *ngIf="subtasksEmpty" class="none-set">No subtasks set</div>


          <h5 style="margin-bottom: 10px;">
            Data Collection
            <div style="float: right;">
              <button mat-icon-button type="button" (click)="submitData($event)" *ngIf="event.dataCollectionDefinitions?.length > 0" [disabled]="event.completed">
                <mat-icon>trending_up</mat-icon>
              </button>
              <!-- <button mat-icon-button type="button" *ngIf="viewTemplateAbility" (click)="editDataCollectionDefinitions($event)" [disabled]="event.completed">
                <mat-icon>query_stats</mat-icon>
              </button> -->
            </div>
          </h5>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-6">
          <h3>Notes</h3>
          <app-messaging 
            [disabled]="!editEventAbility" 
            [discussions]="event.discussions" 
            [(notes)]="notes" 
            (deleteMessage)="deleteMessage($event)"
            [assignToGroup]="assignToGroup"
            [parentId]="parentId"
            [eventId]="event.id"
            [(sending)]="sendingNote">
          </app-messaging>
        </div>
        <div class="col-sm-6">
          <app-discussion-panel [discussions]="event.discussions"></app-discussion-panel>
        </div>
      </div>
    </form>
  </mat-dialog-content>
  <mat-dialog-actions>
      <div class="footer-left">
        <button mat-button *ngIf="completeEvent" [matMenuTriggerFor]="menu">Add Attachment</button>
        <mat-menu #menu="matMenu">
          <input #file type="file" id="selectedFile" style="display: none;" (change)="fileChange(file.files)" />
          <button type="button" style="display:block;" mat-menu-item (click)="browseForFile()"
            title="From Computer">From
            Computer</button>
          <button mat-menu-item (click)="cloudStorageClick()"
            title="Cloud Storage (Google Drive, iCloud, Dropbox, etc..)">Cloud Storage (Google Drive, iCloud, Dropbox,
            etc..)</button>
        </mat-menu>
        <ng-container *ngFor="let d of event.cloudAttachments">
          <a style="margin-right: 10px;" href="{{d}}" target="_blank" [contextMenu]="cloudMenu"
            [contextMenuSubject]="d"><i class="" style="font-size: 20px;" [ngClass]="getClass(d)"></i></a>
          <!-- <button *ngIf="d.contains('google')" mat-icon-button><i class="fab fa-google-drive" [ngClass]="getClass()"></i></button>
          <button *ngIf="d.contains('dropbox')" mat-icon-button><i class="fab fa-dropbox"></i></button>
          <button *ngIf="d.contains('icloud')" mat-icon-button><i class="fab fa-apple"></i></button>
          <button *ngIf="!d.contains('icloud') && !d.contains('icloud') && " mat-icon-button><i class="fab fa-apple"></i></button> -->
        </ng-container>
        <ng-container *ngFor="let d of event.attachments">
          <app-attachment-btn [id]="d"></app-attachment-btn>
          <!-- <a style="margin-right: 10px; cursor: pointer;" target="_blank" [contextMenu]="attMenu"
            [contextMenuSubject]="d"><i class="" style="font-size: 20px;" [ngClass]="getClass(d)"
              (click)="getUrl(d)"></i></a> -->
        </ng-container>

        <br/>
        <span *ngIf="fileToUpload != null">Will upload {{fileToUpload.name}} on save.</span>

        <context-menu #cloudMenu>
          <ng-template contextMenuItem (execute)="removeCloudAttachment($event.item)">
            Remove
          </ng-template>
        </context-menu>
        <context-menu #attMenu>
          <ng-template contextMenuItem (execute)="removeAttachment($event.item)">
            Remove
          </ng-template>
        </context-menu>
      </div>
      <!-- <div class="footer-center">
      </div> -->
      <div class="footer-right" align="end">
        <button mat-icon-button color="warn" *ngIf="deleteEventAbility" [disabled]="saving || deleting" (click)="deleteClick()">
          <mat-spinner *ngIf="deleting" diameter=25 strokeWidth=4></mat-spinner>
          <mat-icon style="margin-right: 8px" *ngIf="!deleting">delete</mat-icon>
        </button>
        <button mat-button mat-dialog-close *ngIf="completeEvent" id="eventUpdate-Cancel" [disabled]="saving || deleting">Cancel</button>
        <!-- The mat-dialog-close directive optionally accepts a value as a result for the dialog. -->
        <button mat-button *ngIf="completeEvent" type="submit" form="updateEvent" [disabled]="saving || deleting || sendingNote">
          <mat-spinner *ngIf="saving" diameter=25 strokeWidth=4></mat-spinner>
          <span *ngIf="!saving">Save</span>
        </button>
      </div>
    <!-- </div> -->


  </mat-dialog-actions>

</div>