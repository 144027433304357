<div class="logo">
    <a id="corp_logo" href="/" class="simple-text">
        <div class="logo-img">
            <img [src]="companyImageUrl"/>
        </div>
        PlanaCan
    </a>
    <app-company-view-and-dropdown></app-company-view-and-dropdown>
</div>
<div class="sidebar-wrapper">
  <div *ngIf="isMobileMenu()">
    <!-- <form class="navbar-form">
      <span class="bmd-form-group">
        <div class="input-group no-border">
          <input type="text" value="" class="form-control" placeholder="Search...">
          <button mat-raised-button type="submit" class="btn btn-white btn-round btn-just-icon">
            <i class="material-icons">search</i>
            <div class="ripple-container"></div>
          </button>
        </div>
      </span>
    </form> -->
    <ul class="nav navbar-nav nav-mobile-menu">
        <!-- <li class="nav-item">
            <a class="nav-link" href="#pablo">
                <i class="material-icons">dashboard</i>
                <p>
                    <span class="d-lg-none d-md-block">Stats</span>
                </p>
            </a>
        </li>
        <li class="nav-item dropdown">
            <a class="nav-link" href="#pablo" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <i class="material-icons">notifications</i>
                <span class="notification">5</span>
                <p>
                    <span class="d-lg-none d-md-block">Some Actions</span>
                </p>
            </a>
            <div class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdownMenuLink">
                <a class="dropdown-item" href="#">Mike John responded to your email</a>
                <a class="dropdown-item" href="#">You have 5 new tasks</a>
                <a class="dropdown-item" href="#">You're now friend with Andrew</a>
                <a class="dropdown-item" href="#">Another Notification</a>
                <a class="dropdown-item" href="#">Another One</a>
            </div>
        </li> -->
        <li>
            <button class="upgrade-fab" mat-fab color="primary" (click)="docs()">
                <span class="extended-fab-button__text">Documentation</span>
            </button>
        </li>
        <li>
            <button class="upgrade-fab" mat-fab color="warn" (click)="askQuestion()">
                <!-- <mat-icon>upgrade</mat-icon>  *ngIf="visible" (click)="upgrade()"-->
                <span class="extended-fab-button__text">Questions?</span>
            </button>
        </li>
        <li class="text-center mt-2">
            <a class="nav-item userprofile" routerLink="/console/profile">{{ user?.displayName }}</a>
        </li>
        <li class="nav-item dropdown">
            <a class="nav-link" href="#pablo" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <i class="material-icons">settings</i>
                <p>
                    <span matBadge="0" [matBadgeHidden]="!showReleaseNotification" class="d-lg-none d-md-block hide-text">Settings</span>
                </p>
            </a>
            <div class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdownMenuLink">
                <!-- <a class="dropdown-item" href="#">Mike John responded to your email</a> -->
                <a *ngIf="canSelectOtherCompany" class="dropdown-item" routerLink="/company-select">Select other Company</a>
                <a *ngIf="canSeeCompanyManagement" class="dropdown-item" routerLink="/console/company-settings">Edit Company Settings</a>
                <a class="dropdown-item" routerLink="/console/processes">Processes</a>
                <!-- <a class="dropdown-item" (click)="signOut()">Sign Out</a> -->
                <a class="dropdown-item" routerLink="/console/profile">Profile</a>
                <a class="dropdown-item" href="https://planacan.io/release-notes/" target="_blank">Release Notes <span class="new-flag" *ngIf="showReleaseNotification">{{version}}</span></a>
            </div>
        </li>
    </ul>
  </div>
    <ul class="nav">
        <li routerLinkActive="active" *ngFor="let menuItem of menuItems" class="{{menuItem.class}} nav-item">
            <a [id]="menuItem.id" class="nav-link" [routerLink]="[menuItem.path]" (click)="menuItemClicked($event)"><!--  queryParamsHandling="merge" -->
                <i class="material-icons">{{menuItem.icon}}</i>
                <p>{{menuItem.title}}</p>
            </a>
            <ng-container *ngIf="menuItem.children && menuItem.children.length > 0">
                <ul class="nav sub-menu" [ngStyle]="{ 'display': isCurrentRoute(menuItem.path) ? 'block' : 'none' }">
                   <li *ngFor="let childMenu of menuItem.children; first as isFirst;" [routerLinkActive]="isFirst ? 'active' : ''" class="{{childMenu.class}} nav-item sub-item">
                     <a *ngIf="canSeeSensor || childMenu.id != 'menu_sensors'" [id]="childMenu.id" class="nav-link" [routerLink]="[childMenu.path]" (click)="childMenuClicked($event)">
                       <i class="material-icons">{{childMenu.icon}}</i>
                       <p>{{childMenu.title}}</p>
                      </a>
                   </li>
                </ul>
            </ng-container>
        </li>
        <!-- <li routerLinkActive="active" class="active-pro nav-item" *ngIf="canSeeSystemManagement">
            <a class="nav-link" [routerLink]="['/console/system-management']" queryParamsHandling="merge">
                <i class="material-icons">settings_system_daydream</i>
                <p>System Management</p>
            </a>
        </li> -->
        <li class="active-pro nav-item">
            <a class="action-sidebar-btn nav-link" href="https://planacan.io/referrals/">
                <i class="material-icons">forward_to_inbox</i>
                <p>Refer a Friend - Earn $500</p>
            </a>
        </li>
    </ul>
</div>
