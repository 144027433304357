<ngx-material-popover [popoverContent]="popoverContent" [clickOutToClose]="true" [xPosition]="xPosition"
    [yPosition]="yPosition" [clickOutToClose]="true" #popover="ngxMaterialPopover" (onClose)="closedEvent($event)"
    mode="toggle">
    <button mat-raised-button>
        {{ dateRangeTitle }}
        <mat-icon class="expand">expand_more</mat-icon>
    </button>
</ngx-material-popover>

<ng-template #popoverContent>
    <mat-form-field style="width: 100%;">
        <mat-label>Select duration</mat-label>
        <mat-select 
          [(ngModel)]="selectedDateFilterOption" 
          (ngModelChange)="onDateFilterOptionChange()" 
          >
            <mat-option value="last_week">
                Last Week
            </mat-option>
            <mat-option value="last_month">
                Last Month
            </mat-option>
            <mat-option value="last_quarter">
                Last Quarter
            </mat-option>
            <mat-option value="last_year">
                Last Year
            </mat-option>
            <mat-option value="all_time">
                All time
            </mat-option>
            <mat-option value="custom">
                Custom Range
            </mat-option>
        </mat-select>
    </mat-form-field>
    <mat-form-field *ngIf="selectedDateFilterOption === 'custom'">
        <mat-label>Select a date range</mat-label>
        <div class="date-range-class">
            <mat-date-range-input [rangePicker]="picker" name="dateRange">
                <input matStartDate placeholder="Start date" #dateRangeStart>
                <input matEndDate placeholder="End date" #dateRangeEnd (dateChange)="onCustomDateRangeChange(dateRangeStart.value, dateRangeEnd.value)">
            </mat-date-range-input>
            <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-date-range-picker #picker></mat-date-range-picker>
        </div>
    </mat-form-field>
</ng-template>
