import { Component, OnInit, OnDestroy, NgZone } from '@angular/core';

import * as services from '../../../services';
import * as models from '../../../models';
import { SessionStorageService } from 'angular-web-storage';

import * as introJs from 'intro.js/intro.js';
import { GuidedTour, TourStep, GuidedTourService, Orientation } from 'ngx-guided-tour';
import { DocumentationRedirectDialogComponent, HelpComponent } from 'app/dialogs';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

declare const $: any;
declare interface RouteInfo {
    path: string;
    title: string;
    icon: string;
    class: string;
    id: string;
    children?: RouteInfo[];
}
export const SBROUTES: RouteInfo[] = [
    //{ path: '/console/dashboard', title: 'Dashboard',  icon: 'dashboard', class: '' },
    // { path: '/user-profile', title: 'User Profile',  icon:'person', class: '' },
    // { path: '/table-list', title: 'Table List',  icon:'content_paste', class: '' },
    // { path: '/typography', title: 'Typography',  icon:'library_books', class: '' },
    // { path: '/icons', title: 'Icons',  icon:'bubble_chart', class: '' },
    { path: '/console/calendar', title: 'Calendar',  icon:'calendar_today', class: '', id: 'sidebar_calendar' },
    { path: '/console/harvests', title: 'Harvests',  icon: 'list', class: '', id: 'sidebar_harvests' },
    { path: '/console/task-groups', title: 'Task Groups', icon: 'assignment', class: '', id: 'sidebar_taskGroups'}
    // { path: '/notifications', title: 'Notifications',  icon:'notifications', class: '' },
    // { path: '/upgrade', title: 'Upgrade to PRO',  icon:'unarchive', class: 'active-pro' },
    //{ path: '/console/city-codes', title: 'City Codes', icon:'location_city', class: '' },
];


export const ROUTES: RouteInfo[] = [
  { path: '/no-permissions', title: 'Not Allowed', icon:'location_city', class: '', id: 'sidebar_nopermissions' },
  { path: '/company-select', title: 'Select Company', icon:'location_city', class: '', id: 'sidebar_companySelect' },
  { path: '/console/system-management', title: 'System Management', icon:'settings_system_daydream', class: '', id: 'sidebar_systemManagement' },
  { path: '/home', title: 'Home', icon:'location_city', class: '', id: 'sidebar_home' },
  { path: '/console/processes', title: 'Processes', icon:'location_city', class: '', id: 'process' },
]

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit, OnDestroy {
  menuItems: any[];

  user: models.User;

  userRole: models.Role;
  //companyIds: Array<string> = [];

  companyImageUrl: string;

  introJS = introJs();

  canSeeSensor: boolean = true;
  companyIdSub: Subscription

  get showReleaseNotification(): boolean {
    let releaseDate: Date = new Date(this.appStorage.releaseDate.getTime());
    releaseDate.setDate(releaseDate.getDate() + 7) 
    return new Date() <= releaseDate;
  }
  version: string;

  constructor(
    private authService: services.AuthService
    , private helperService: services.HelperService
    , private claimsService: services.ClaimsService
    , private userService: services.UserService
    , private storage: SessionStorageService
    , private fileService: services.FileService
    , private guidedTourService: GuidedTourService
    , private dialog: MatDialog
    , private snackBar: MatSnackBar
    , private appStorage: services.AppStorage
    , private router: Router) { }

  async ngOnInit() {
    this.getCompanyImage();

    this.getSensor(this.helperService.currentCompany);
    this.companyIdSub = this.helperService.getCurrentCompany().subscribe(data => {
      this.getSensor(data);
    })

    this.userRole = await this.claimsService.getRoleAwait();
    if(this.userRole?.permissions?.includes(models.Permissions.viewTemplate)){
      if(SBROUTES.find(i => i.title == 'Templates') == null){ //only add if it's not there
        SBROUTES.push({ path: '/console/templates', title: 'Templates', icon:'file_copy', class: '', id: 'sidebar_templates',
          children: [
            { path: '/console/templates', title: 'Templates',  icon: 'file_copy', class: '', id: 'menu_templates'},
            { path: '/console/templates', title: 'Workflows',  icon: 'account_tree', class: '', id: 'menu_workflows'},
            { path: '/console/templates', title: 'Task Library',  icon: 'task', class: '', id: 'menu_tasklibrary'}
            ]        
         })
      }
    }
    if(this.userRole?.permissions?.includes(models.Permissions.viewGrowSettings)){
      if(SBROUTES.find(i => i.title == 'Grow Settings') == null){ //only add if it's not there
        SBROUTES.push({ path: '/console/grow-settings', title: 'Grow Settings', icon:'settings', class: '', id: 'sidebar_grow_settings', 
          children: [
            { path: '/console/grow-settings', title: 'Strains',  icon: 'grass', class: '', id: 'menu_strains'},
            { path: '/console/grow-settings', title: 'Sensors',  icon: 'sensors', class: '', id: 'menu_sensors'},
            { path: '/console/grow-settings', title: 'Zones',  icon: 'workspaces', class: '', id: 'menu_zones'},
            { path: '/console/grow-settings', title: 'Data Library',  icon: 'folder', class: '', id: 'menu_datalibrary'}
            ]        
        })
      }
    }
    if(this.userRole?.permissions?.includes(models.Permissions.viewUsers)){
      if(SBROUTES.find(i => i.title == 'Users and Teams') == null){ //only add if it's not there
        SBROUTES.push({ path: '/console/user', title: 'Users and Teams', icon:'person_outline', class: '', id: 'sidebar_users',
          children: [
            { path: '/console/user', title: 'Users',  icon: 'person', class: '', id: 'menu_users' },
            { path: '/console/user', title: 'Teams',  icon: 'group', class: '', id: 'menu_teams'}
            ]        
         })
      }
    }
    if(this.userRole?.permissions?.includes(models.Permissions.viewAnalytics)){
      if(SBROUTES.find(i => i.title == 'Analytics') == null){ //only add if it's not there
        SBROUTES.push({ path: '/console/analytics', title: 'Analytics', icon:'analytics', class: '', id: 'analytics',
          children: [
            { path: '/console/analytics', title: 'Labor', icon: 'person', class: '', id: 'menu_labor' },
            { path: '/console/analytics', title: 'Data Submission', icon: 'group', class: '', id: 'menu_data'}
          ]
         })
      }
    }

    this.menuItems = SBROUTES.filter(menuItem => menuItem);

    this.startTour();

    // get whole user
    let userId = this.claimsService.currentUserId();
    this.user = await this.userService.getUserById(userId);
    
    //this.companyIds = await this.helperService.getCompanyIds();
    this.version = this.appStorage.releaseVersion;
  }

  ngOnDestroy(): void {
    this.companyIdSub.unsubscribe();
  }

  isMobileMenu() {
      if ($(window).width() > 991) {
          return false;
      }
      return true;
  };

  getSensor(company: models.Company) {
    this.canSeeSensor = company?.enableSensors;
  }

  isCurrentRoute(route: string) {
    return this.router.url.startsWith(route);
  }

  menuItemClicked(ev) {
    let subMenuContainer = $(ev.target).closest('li.nav-item').find('ul.sub-menu');
    if (subMenuContainer.css('display') == 'none') {
      subMenuContainer.slideDown({duration: 500});
    }

    let parentContainer = subMenuContainer.closest('ul.sub-menu');
    parentContainer.find('li.sub-item').removeClass('active');
    parentContainer.find('li.sub-item').first().addClass('active');
  }

  childMenuClicked(ev) {
    let parentButton = $(ev.target).closest('li.sub-item');
    let parentContainer = parentButton.closest('ul.sub-menu');

    parentContainer.find('li.sub-item').removeClass('active');
    parentButton.addClass('active');

    let subMenuId = parentButton.find('a').attr('id');
    let tabMenuItem = $('.mat-tab-links').find(`[data-xrefid=${subMenuId}]`)[0];
    tabMenuItem.click();
  }

  get canSelectOtherCompany() {
      // if((this.userRole != null && this.userRole.precedence === 0) || this.companyIds.length > 1){
      //     return true;
      // } else {
      //     return false;
      // }
      return false;
  }

  get canSeeCompanyManagement() {
    return this.userRole?.permissions?.includes(models.Permissions.viewCompanySettings);
  }

  get canSeeSystemManagement() {
    return this.userRole?.permissions?.includes(models.Permissions.adminOnly);
  }

  async signOut() {
      await this.authService.signOut();
  }

  async getCompanyImage() {
    //get company image from storage if it is there
    this.fileService.get(`companyImage/{companyId}`).subscribe(url => {
      this.companyImageUrl = url;
    }, error => {
      this.companyImageUrl = 'assets/img/Logo1.png'
    });
  }

  async startTour() {
    //go check to see if they need to do intro

    // if(completedDemo === true){
    //   return;
    // }

    // await this.helperService.wait(500);

    // this.introJS.setOptions({
    //   steps: [
    //     {
    //       intro: "We would like to take you through the setup of your system."
    //     },
    //     {
    //       element: document.getElementById('sidebar_templates'),
    //       intro: "The first step is to go to your Templates to set up your first Grow Template.  Click on the Template menu item to go to the Template page.",
    //       showButtons: false
    //     }
    //   ]
    // });

    // this.introJS.setOption("nextLabel", " > ");
    // this.introJS.setOption("disableInteraction", false)
    // this.introJS.setOption("showStepNumbers", false)
    // this.introJS.setOption("showBullets", false)
    // this.introJS.setOption("showProgress", false)
    // this.introJS.setOption("exitOnOverlayClick", false)
    // this.introJS.setOption("exitOnEsc", false)
    
    // this.introJS.start();

    //debugger;

    // let stepOne: TourStep = {
    //   selector: '#corp_logo',
    //   title: 'Introduction',
    //   content: 'We would like to take you through the setup of your system.',
    //   orientation: Orientation.BottomRight
    // }
    // let stepTwo: TourStep = {
    //   selector: '#sidebar_templates',
    //   title: 'Templates',
    //   content: 'The first step is to go to your Templates to set up your first Grow Template.  Click on the Template menu item to go to the Template page.',
    //   orientation: Orientation.BottomRight
    // }

    // let tour: GuidedTour = {
    //   tourId: 'Intro',
    //   useOrb: false,
    //   steps: [
    //     stepOne, stepTwo
    //   ],
    //   preventBackdropFromAdvancing: true
    // }

    // this.guidedTourService.startTour(tour);
  }

  askQuestion() {
    let dialogRef = this.dialog.open(HelpComponent, {
        width: '600px',
    });

    dialogRef.afterClosed().subscribe(result => {
        //reload list
        if(result){
            this.snackBar.open('We have recieved your question and someone from our team will be getting back to you as soon as we can.');
        }
        console.log(JSON.stringify(result));
      })
  }

  docs() {
      //open dialog
      this.dialog.open(DocumentationRedirectDialogComponent);
  }
}
