import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import * as models from 'app/models';
import * as viewmodels from 'app/viewmodels';
import * as services from 'app/services';

@Component({
  selector: 'app-task-type-select-multiple-btn',
  templateUrl: './task-type-select-multiple-btn.component.html',
  styleUrls: ['./task-type-select-multiple-btn.component.scss']
})
export class TaskTypeSelectMultipleBtnComponent  implements OnInit {

  templatedTasks: models.TemplatedTask[] = [];
  tasksInTemplate: models.Step[] = [];
  templatedTaskItems: viewmodels.MultiSelect[] = [];

  //@Input() selected: Array<string> = []
  selectedValue: Array<string>;
  @Input()
  get selected() {
    return this.selectedValue;
  }
  @Output() selectedChange = new EventEmitter();
  set selected(val) {
    if(val == null){
      val = [];
    }
    this.selectedValue = val;
    this.selectedChange.emit(this.selectedValue);

    this.selectedUsers = this.templatedTaskItems.filter(i => this.selected.includes(i.id));
  }
  @Output() onClosed = new EventEmitter();

  selectedUsersValue: Array<viewmodels.MultiSelect>;
  @Input()
  get selectedUsers() {
    return this.selectedUsersValue;
  }
  @Output() selectedUsersChange = new EventEmitter();
  set selectedUsers(val) {
    if (val == null) {
      val = [];
    }
    this.selectedUsersValue = val;
    this.selectedUsersChange.emit(this.selectedUsersValue);
  }

  templates: models.Template[] = [];
  allSteps: models.Step[] = [];

  constructor(
    private templatedTasksService: services.TemplatedTasksService
    , private templateService: services.TemplateService
    , private helperService: services.HelperService
  ) { }

  ngOnInit(): void {
    this.load();
  }

  async load() {
    this.templatedTaskItems = [];
    await this.primeTemplateSteps();
    await this.getTaskLibrary();
    await this.getTasksFromTemplates();
  }

  async primeTemplateSteps() {
    this.templates = await this.templateService.getAll();

    // Flatten all steps from all templates and phases
    this.allSteps = this.templates.flatMap(template => 
      template.phases.flatMap(phase => phase.steps)
    );
  }

  async getTaskLibrary() {
    let templatedTasks = await this.templatedTasksService.getAll();
    templatedTasks = templatedTasks.filter(task => task.dataCollectionDefinitions?.length > 0);
    this.templatedTasks = this.helperService.sortArrayByStringField(templatedTasks, 'name');
    this.templatedTaskItems.push(...this.templatedTasks.map(task => {
      let h: viewmodels.MultiSelect = {
        id: task.id,
        displayValue: task.name,
        selected: false,
        dataCollectionDefinitions: task.dataCollectionDefinitions,
        templatedTaskId: task.id,
        stepId: null,
        subStepIds: this.allSteps.filter(i => i.templatedTaskId == task.id).map(i => i.uid)
      };
      return h;
    }));
  }

  async getTasksFromTemplates() {
    const tasks: models.Step[] = [];

    // Process each step
    let filteredSteps = this.allSteps.filter(step => step.dataCollectionDefinitions?.length > 0);
    filteredSteps.forEach(step => {

      //only include steps that are NOT in the task library
      const isInTaskLibrary = this.templatedTasks.some(task => task.id === step.templatedTaskId);
      
      if (!isInTaskLibrary) {
        tasks.push(step);
        // const isDuplicate = tasks.some(existingTask => existingTask.description === step.description);
        
        // if (!isDuplicate) {
        //   tasks.push(step);
        // }
      }
    });

    this.tasksInTemplate = this.helperService.sortArrayByStringField(tasks, 'description');
    this.templatedTaskItems.push(...this.tasksInTemplate.map(task => ({
      id: task.uid,
      displayValue: task.description,
      selected: false,
      dataCollectionDefinitions: task.dataCollectionDefinitions,
      templatedTaskId: task.templatedTaskId,
      stepId: task.uid
    })));
  }

  closedEvent(event: any) {
    if(JSON.stringify(this.selected) != JSON.stringify(event)){
      this.selected = event;
      this.onClosed.emit(event);
    }
  }
}
