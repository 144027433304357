import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';

import * as models from '../../models';
import * as services from '../../services';
import * as viewmodels from 'app/viewmodels';

import {
  UserSelectComponent,
} from '../../dialogs/user-select/user-select.component';
import {
  EventRepeatComponent
} from '../../dialogs/event-repeat/event-repeat.component';
import { FormControl } from '@angular/forms';
import { DiscussionType } from '../../models';
import { Timestamp } from '@angular/fire/firestore';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { SubtasksDialogComponent } from '../subtasks-dialog/subtasks-dialog.component';

@Component({
  selector: 'app-event-add',
  templateUrl: './event-add.component.html',
  styleUrls: ['./event-add.component.scss']
})
export class EventAddComponent implements OnInit {
  saving: boolean = false;
  saveInDialog: boolean = false;
  assignToGroup: boolean = false;

  harvest: models.Harvest;
  harvestId: string;
  needToPickHarvest: boolean = false;

  group: models.Group;
  groupId: string;
  needToPickGroup: boolean = false;

  eventDate: Date;
  eventTime: string = '';

  startDate: FormControl;
  needToPickDate: boolean = false;

  users: Array<models.User> = [];

  repeatEvent: boolean = false;
  number: number;
  interval: string;
  repeatEndDate: Date;
  daysOfWeek: string[] = [];

  event: models.Event = {
    completed: false,
    precedence: 0
  };
  notes: string = '';

  descriptionHasError: boolean = false;

  subtasks: models.SubtaskDisplay[] = [];

  get editEventAbility(): boolean {
    return this.claimsService.userRole?.permissions?.includes(models.Permissions.editEvent);
  }

  constructor(
    private dialogRef: MatDialogRef<EventAddComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    private harvestService: services.HarvestService,
    private groupService: services.GroupService,
    private helperService: services.HelperService,
    private claimsService: services.ClaimsService,
    private firestore: AngularFirestore,
    private notifierService: services.NotifierService,
    private eventService: services.EventService
  ) { 
    this.saveInDialog = data.saveInDialog || false;
    if(data.date != null){
      this.event.startDateTime = Timestamp.fromDate(data.date);
      this.eventDate = data.date;
      this.startDate = new FormControl(data.date);
      this.eventTime = data.date.toTimeString().substring(0, 5);
    } else {
      this.needToPickDate = true;
      this.startDate = new FormControl(data.date);
      this.eventTime = '12:00';
    }
    if(data.harvestId != null){
      this.harvestId = data.harvestId;
    } else {
      this.needToPickHarvest = true;
    }
    if(data.groupId != null){
      this.groupId = data.groupId;
    } else {
      this.needToPickGroup = true;
    }
    if(data.addFromGroupDetail) {
      this.groupId = data.groupId
      this.assignToGroup = true;
      this.needToPickHarvest = false;
    }
    this.event.assignedUserIds = [];
    this.notes = '';
    this.event.anyTime = true;
    this.event.discussions = [];
    this.event.highPriority = false;
    this.event.cloudAttachments = [];
    this.event.tags = [];

    if(data.templatedTask != null){
      this.event = this.setEventFromTemplatedTask(this.event, data.templatedTask);
    }

    this.organizeSubtaskDisplays();
  }


  ngOnInit() {
  }

  selectUsers() {
    let dialogRef = this.dialog.open(UserSelectComponent, {
      //panelClass: 'med-width-dialog',
      id: 'user-select-dialog',
      data: {
        userIds: this.event.assignedUserIds,
        teamIds: this.event.assignedTeamIds
      }
      //autoFocus: true
    });

    dialogRef.afterClosed().subscribe(async result => {
      if(result && result.submitted){
        this.event.assignedUserIds = result.userIds;
        this.event.assignedTeamIds = result.teamIds;
      }
      console.log(JSON.stringify(result));
    });
  }

  /**
   * Repeat button hit
   */
  repeatBtn() {
    if(this.needToPickDate){
      this.eventDate = this.startDate.value;
    }

    let inData = {
      repeatEvent: this.repeatEvent,
      interval: this.interval,
      repeatEndDate: this.repeatEndDate,
      dayOfWeek: this.helperService.getDayOfWeek(this.eventDate),
      daysOfWeek: this.daysOfWeek
    }
    let dialogRef = this.dialog.open(EventRepeatComponent, {
      id: 'event-repeat-dialog',
      data: inData,
      //autoFocus: true
    });

    dialogRef.afterClosed().subscribe(async result => {
      if(result && result.submitted){
        this.repeatEvent = result.repeat;
        if(this.repeatEvent){
          this.number = result.number
          this.interval = result.interval;
          this.repeatEndDate = result.endDate;
          this.daysOfWeek = result.daysOfWeek;
        }
      }
      console.log(JSON.stringify(result));
    });
  }

  async saveEvent() {
    this.descriptionHasError = false;
    if(this.event.description == null || this.event.description == '') {
      this.descriptionHasError = true;
      this.notifierService.error('Cannot save an Event with an empty Event Description field');
      return;
    }

    if(this.needToPickDate){
      this.eventDate = this.startDate.value;
    }

    if(this.eventDate == null) {
      this.notifierService.error('Cannot save an Event without a Date');
      return;
    }

    let split = this.eventTime.split(':');
    let hour = +split[0];
    let min = +split[1];
    this.eventDate.setHours(hour);
    this.eventDate.setMinutes(min);
    this.event.startDateTime = Timestamp.fromDate(this.eventDate);
    this.event.companyId = this.helperService.currentCompanyId;
    this.event.parentId = this.assignToGroup ? this.groupId : this.harvestId;

    this.updateDiscussion();

    let events: Array<models.Event> = [];
    
    //if we want to repeat the event
    if(this.repeatEvent){
      let eventDateTime = this.event.startDateTime.toDate();
      this.event.repeatedEventId = this.firestore.createId();

      events.push(this.event);

      if(this.interval == 'weekly'){
        eventDateTime = this.helperService.addDaysToDate(eventDateTime, 1);
      }

      while(eventDateTime < this.repeatEndDate){
        let event: models.Event = this.helperService.deepCopy(this.event);

        switch(this.interval){
          case 'daily':
            eventDateTime = this.helperService.addDaysToDate(eventDateTime, 1*this.number);
            event.startDateTime = Timestamp.fromDate(eventDateTime);
            events.push(event);
            break;
          case 'weekly':
            let ret = this.addWeeklyEvents(event, eventDateTime);
            eventDateTime = ret.eventDateTime;
            events.push(...ret.events);
            break;
          case 'monthly':
            eventDateTime = this.helperService.addMonthToDate(eventDateTime, 1*this.number);
            event.startDateTime = Timestamp.fromDate(eventDateTime);
            events.push(event);
            break;
        }
      }
    } else {
      events.push(this.event);
    }
    
    let dialogResult: viewmodels.EventAddResponse = {
      save: true,
      events: events,
      assignToGroup: this.assignToGroup
    }

    if(this.assignToGroup){
      dialogResult.group = this.group;
    } else {
      dialogResult.harvest = this.harvest;
    }

    //if company is set up to have split events - save in dialog component
    if(this.saveInDialog){
      dialogResult.save = false;
      this.saving = true;

      events.forEach(ev => {
        ev.color = this.assignToGroup ? this.group.color : this.harvest.color;
        if (this.assignToGroup) {
          delete ev.harvestName;
          ev.groupName = this.group.name;
        } else {
          delete ev.groupName;
          ev.harvestName = this.harvest.name;
        }
      });

      await this.eventService.saveListSplit(this.assignToGroup, events);
      this.saving = false;
      this.notifierService.success(`Successfully added ${events.length} events`)
    }

    this.dialogRef.close(dialogResult)
  }

  addWeeklyEvents(inEvent: models.Event, eventDateTime: Date): any {
    let events: Array<models.Event> = [];

    let continueProcessing: boolean = true;
    while(continueProcessing){
      let dayOfWeek = this.helperService.getDayOfWeek(eventDateTime);
      if(this.daysOfWeek.includes(dayOfWeek) && eventDateTime < this.repeatEndDate){
        let event: models.Event = this.helperService.deepCopy(inEvent);
        event.startDateTime = Timestamp.fromDate(eventDateTime);
        events.push(event);
      }
      
      eventDateTime = this.helperService.addDaysToDate(eventDateTime, 1);
      let newDayOfWeek = this.helperService.getDayOfWeek(eventDateTime);
      if(newDayOfWeek == 'sunday'){
        continueProcessing = false;
      }
    }

    if(this.number > 1){
      eventDateTime = this.helperService.addDaysToDate(eventDateTime, 7*(this.number-1));
    }

    return {
      events: events,
      eventDateTime: eventDateTime
    };
  }

  toggleChange(newVal) {
    if(newVal && newVal.checked){
      this.assignToGroup = true;
    } else {
      this.assignToGroup = false;
    }
  }

  updateDiscussion() {
    //adding creation discussion point
    let creationDis: models.Discussion = {
      discussionType: DiscussionType.Created,
      userId: this.claimsService.currentUserId(),
      timestamp: Timestamp.now(),
      //content: this.event.notes,
    }

    this.event.discussions.push(creationDis);

    //adding notes to discussion
    if(this.notes != null && this.notes != ''){
      let newDis: models.Discussion = {
        discussionType: DiscussionType.Note,
        userId: this.claimsService.currentUserId(),
        timestamp: Timestamp.now(),
        content: this.notes,
      }

      this.event.discussions.push(newDis);
      //this.event.notes = '';
    }

    //add completed status
    if(this.event.completed){
      let newDis: models.Discussion = {
        discussionType: DiscussionType.CompletedStatus,
        userId: this.claimsService.currentUserId(),
        timestamp: Timestamp.now(),
        content: this.event.completed ? 'true' : 'false',
      }

      this.event.discussions.push(newDis);
    }
  }

  setEventFromTemplatedTask(event: models.Event, templatedTask: models.TemplatedTask): models.Event {
    event.templatedTaskId = templatedTask.id;
    if (templatedTask.description != null) {
      event.description = templatedTask.description;
    }
    if (templatedTask.assignedTeamIds != null) {
      event.assignedTeamIds = templatedTask.assignedTeamIds;
    }
    if (templatedTask.highPriority != null) {
      event.highPriority = templatedTask.highPriority;
    }
    if (templatedTask.cloudAttachments != null) {
      event.cloudAttachments = templatedTask.cloudAttachments;
    }
    if (templatedTask.tags != null) {
      event.tags = templatedTask.tags;
    }
    if (templatedTask.anyTime != null) {
      event.anyTime = templatedTask.anyTime;
    }
    if (templatedTask.estimatedTime != null) {
      event.estimatedTime = templatedTask.estimatedTime;
    }
    if (templatedTask.timeSpentRequired != null) {
      event.timeSpentRequired = templatedTask.timeSpentRequired;
    }
    if (templatedTask.subtasks != null) {
      event.subtasks = templatedTask.subtasks;
    }
    if (templatedTask.precedence != null) {
      event.precedence = templatedTask.precedence;
    }
    if (templatedTask.dataCollectionDefinitions != null) {
      event.dataCollectionDefinitions = templatedTask.dataCollectionDefinitions;
    }
    if (templatedTask.startTime != null) {
      this.eventTime = templatedTask.startTime;
    }
    if (templatedTask.notes != null) {
      templatedTask.notes.forEach(note => {
      let newDis: models.Discussion = {
        discussionType: DiscussionType.InitialNote,
        userId: this.claimsService.currentUserId(),
        timestamp: Timestamp.now(),
        content: note,
      }
      event.discussions.push(newDis)
      });
    }

    return event;
  }

  editSubtasks(event) {
    event.stopPropagation();
    event.preventDefault();
    const doc = document.documentElement;
    const top = (window.scrollX || doc.scrollTop) - (doc.clientTop || 0);
    const left = (window.scrollY || doc.scrollLeft) - (doc.clientLeft || 0);
    if(top != 0 || left != 0){
      window.scrollTo(0, 0);
    }
    let notesDialog = this.dialog.open(SubtasksDialogComponent, {
      data: {
        subtasks: this.event.subtasks || []
      }
    });

    notesDialog.afterClosed().subscribe(data => {
      if (data?.accept) {
        this.event.subtasks = data.subtasks;
        this.organizeSubtaskDisplays();
      }
      if(top != 0 || left != 0){
        window.scrollTo({ top: top, left: left, behavior: 'auto' });
      }
    })
  }

  organizeSubtaskDisplays() {
    this.subtasks = this.event.subtasks?.map(i => {return {displayDescription: false, subtask: i}}) ?? [];
  }

  subtaskDescToggle(event, subtask: models.SubtaskDisplay) {
    event.stopPropagation();
    event.preventDefault();
    subtask.displayDescription = !subtask.displayDescription;
  }
}
