import { Injectable } from '@angular/core';

@Injectable()
export class SidebarHelperService {

  constructor() { }

  synchronizeTabToSubmenu(event: Event) {
    let xrefId = $(event.target).attr('data-xrefid');
    let menuLink = $('.sub-menu').find(`[id=${xrefId}]`);
    let parentContainer = menuLink.closest('ul.sub-menu');

    parentContainer.find('li.sub-item').removeClass('active');
    menuLink.closest('li.sub-item').addClass('active');
  }

  initMenuToSelectedTab(waitCount: number = 0) {
    // max waitcount
    if (waitCount < 20) {
      // wait till both tab and menu eleemnts are available
      if ($('.mat-tab-links').find('.mat-tab-label-active').length == 0 || $('.nav-item.sub-item.active').length == 0) {
        setTimeout(()=>{this.initMenuToSelectedTab(waitCount + 1); }, 100);
      } else {
        $('.mat-tab-links').find('.mat-tab-label-active')[0].click();
      }
    }
  }
}
