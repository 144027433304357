import { Injectable } from '@angular/core';
import { SessionStorageService } from 'angular-web-storage';

import * as models from '../models';
import * as viewmodels from 'app/viewmodels';
import { HelperService } from './helper.service';
import { Observable } from 'rxjs';
import { FileService } from './file.service';

import { Firestore, CollectionReference, Timestamp, collection, writeBatch, doc, setDoc, where, query, getDocs, orderBy, startAt, endAt, addDoc, updateDoc, getDoc, Query, DocumentData } from '@angular/fire/firestore';
import { AngularFirestore, DocumentReference } from '@angular/fire/compat/firestore';
import { FieldPath, collectionGroup } from 'firebase/firestore';
import { Functions, httpsCallable } from '@angular/fire/functions';

@Injectable()
export class EventService {

    companiesRef: CollectionReference;

    constructor(
        private firestore: Firestore,
        private store: AngularFirestore,
        private helperService: HelperService,
        private storage: SessionStorageService,
        private fileService: FileService,
        private functions: Functions
    ) {
            
        this.companiesRef = collection(this.firestore, 'companies');
    }

    //SPLIT functions
    async saveListSplit(toGroup: boolean, events: Array<models.Event>) {
        let companyId = this.helperService.currentCompanyId;

        //get parent table name
        let parentTableName: string;
        if (toGroup) {
            parentTableName = "groups";
        } else {
            parentTableName = "harvests";
        }

        //create batch
        let batch = writeBatch(this.firestore)

        events.forEach(event => {
            //delete event id from object
            let id: string;
            if(event.uid){
                id = event.uid;
                delete event.uid;
            } else {
                id = this.store.createId()
            }
    
            let eventDocRef = doc(this.companiesRef, companyId, ...[parentTableName, event.parentId, 'events-split', id]);

            event.filters = this.getEventFilters(event);
            event = this.helperService.removeEmpty(event);
            batch.set(eventDocRef, event)
        })

        await batch.commit();
    }

    async saveSplit(toGroup: boolean, event: models.Event) {
        let parentTableName: string;
        if (toGroup) {
            parentTableName = "groups";
        } else {
            parentTableName = "harvests";
        }

        let companyId = this.helperService.currentCompanyId;
        let parentId: string;
        if(event.parentId){
            parentId = event.parentId;
        }
        let id: string;
        if(event.uid){
            id = event.uid;
            delete event.uid;
        } else {
            id = this.store.createId()
        }

        event.filters = this.getEventFilters(event);

        event = this.helperService.removeEmpty(event);
        let eventDoc = doc(this.companiesRef, companyId, ...[parentTableName, parentId, 'events-split', id])
        await setDoc(eventDoc, event);
    }
    
    public getEventFilters(event: models.Event): Array<string> {
        let filters: Array<string> = [];

        let parentIds = ['any', event.parentId]
        let phases: Array<string> = ['any']
        if(event.phase != null && event.phase != ''){
            phases.push(event.phase);
        }
        let assignedUserIds: Array<string> = ['any', ...event.assignedUserIds || []];
        let assignedTeamIds: Array<string> = ['any', ...event.assignedTeamIds || []];
        let tags: Array<string> = ['any', ...event.tags || []];
        
        parentIds.forEach(parentId => {
            phases.forEach(phase => {
                assignedUserIds.forEach(userId => {
                    assignedTeamIds.forEach(teamId => {
                        tags.forEach(tag => {
                            filters.push(`${event.companyId}_${parentId}_${phase}_${userId}_${teamId}_${tag}`);
                        });
                    });
                });
            })
        });

        return filters;
    }

    async getRepeatingEvents(toGroup: boolean, parentId: string, repeatedEventId: string, afterDate: Timestamp = null) {
        let companyId = this.helperService.currentCompanyId;

        let table: string = toGroup ? "groups" : "harvests";

        let eventsListRef = collection(this.companiesRef, companyId, ...[table, parentId, 'events-split'])
        const constraints = []
        constraints.push(where('repeatedEventId', '==', repeatedEventId))

        if(afterDate){
            constraints.push(where('startDateTime', '>=', afterDate))
        }

        let repeatingEventsQuery = query(eventsListRef, ...constraints);

        let snapShot = await getDocs(repeatingEventsQuery);

        let events: models.Event[] = [];
        snapShot.docs.forEach(doc => {
            let event: models.Event = doc.data();
            event.uid = doc.id
            events.push(event);
        })

        return events;
    }

    getFilteredListener(request: viewmodels.GetEventsRequest) : Array<viewmodels.ColGroupQuery> {

        //need to figure out how to filter for all these and more...
        let companyId = this.helperService.currentCompanyId;
        //let companyRef = this.companyRef.doc(companyId).ref;

        let colGroup = collectionGroup(this.firestore, 'events-split')

        const constraints = []
        constraints.push(orderBy('startDateTime'))
        if(request.start){
            constraints.push(where('startDateTime', '>=', request.start))
        }
        if(request.end){
            constraints.push(where('startDateTime', '<=', request.end))
        }
        //constraints.push(where('companyId', '==', companyId))

        if(request.completeStatus == models.CompleteStatus.complete){
            constraints.push(where('completed', '==', true))
        }
        if(request.completeStatus == models.CompleteStatus.incomplete){
            constraints.push(where('completed', '==', false))
        }

        let parentIds = request.harvestIds.concat(request.groupIds);

        //find possible filter strings from filter request
        let filters: Array<string> = [];
        let phases: Array<string> = [];
        if(request.phases == null || request.phases.length == 0) {
            phases.push('any');
        } else {
            phases = request.phases;
            phases.push('ingroup')
        }
        let userIds: Array<string> = [];
        if(request.userIds == null || request.userIds.length == 0) {
            userIds.push('any');
        } else {
            userIds = request.userIds;
        }
        let teamIds: Array<string> = [];
        if(request.teamIds == null || request.teamIds.length == 0) {
            teamIds.push('any');
        } else {
            teamIds = request.teamIds;
        }
        let tags: Array<string> = [];
        if(request.tags == null || request.tags.length == 0) {
            tags.push('any');
        } else {
            tags = request.tags;
        }
        
        parentIds.forEach(parentId => {
            phases.forEach(phase => {
                userIds.forEach(userId => {
                    teamIds.forEach(teamId => {
                        tags.forEach(tag => {
                            filters.push(`${companyId}_${parentId}_${phase}_${userId}_${teamId}_${tag}`);
                        });
                    });
                });
            })
        })

        let retList: Array<viewmodels.ColGroupQuery> = [];

        const chunkSize = 10;
        for (let i = 0; i < filters.length; i += chunkSize) {
            const chunk = filters.slice(i, i + chunkSize);
            let chunkContraints = [...constraints];
            
            chunkContraints.push(where('filters', 'array-contains-any', chunk));
            let repeatingEventsQuery = query(colGroup, ...chunkContraints);

            let result: viewmodels.ColGroupQuery = {
                filters: chunk,
                query: repeatingEventsQuery
            }
            retList.push(result)
        }
        return retList;
    }

    // getFilteredListener(request: viewmodels.GetEventsRequest) {

    //     //need to figure out how to filter for all these and more...
    //     let companyId = this.helperService.currentCompanyId;
    //     //let companyRef = this.companyRef.doc(companyId).ref;

    //     let colGroup = collectionGroup(this.firestore, 'events-split')

    //     const constraints = []
    //     constraints.push(orderBy('startDateTime'))
    //     if(request.start){
    //         constraints.push(where('startDateTime', '>=', request.start))
    //     }
    //     if(request.end){
    //         constraints.push(where('startDateTime', '<=', request.end))
    //     }
    //     constraints.push(where('companyId', '==', companyId))

    //     let parentIds = request.harvestIds.concat(request.groupIds);
    //     constraints.push(where('parentId', 'in', parentIds));

    //     let repeatingEventsQuery = query(colGroup, ...constraints);
    //     return repeatingEventsQuery;
    // }

    // getFilteredListener(request: viewmodels.GetEventsRequest) {

    //     //need to figure out how to filter for all these and more...
    //     let companyId = this.helperService.currentCompanyId;
    //     //let companyRef = this.companyRef.doc(companyId).ref;

    //     let colGroup = collectionGroup(this.firestore, 'events-split')

    //     const constraints = []
    //     constraints.push(orderBy('startDateTime'))
    //     if(request.start){
    //         constraints.push(where('startDateTime', '>=', request.start))
    //     }
    //     if(request.end){
    //         constraints.push(where('startDateTime', '<=', request.end))
    //     }
    //     constraints.push(where('companyId', '==', companyId))

    //     let parentIds = request.harvestIds.concat(request.groupIds);
    //     constraints.push(where('parentId', 'in', parentIds));

    //     if(request.phases?.length > 0){
    //         constraints.push(where('phase', 'in', request.phases));
    //     }

    //     if(request.teamIds?.length > 0){
    //         constraints.push(where('assignedTeamIds', 'in', request.teamIds));
    //     }

    //     if(request.userIds?.length > 0){
    //         constraints.push(where('assignedUserIds', 'in', request.userIds));
    //     }

    //     if(request.tags?.length > 0){
    //         constraints.push(where('tags', 'in', request.tags));
    //     }

    //     let repeatingEventsQuery = query(colGroup, ...constraints);
    //     return repeatingEventsQuery;

    //     //let snapShot = await getDocs(repeatingEventsQuery);



    //     // let response = this.firestore.collectionGroup('events-split', ref => {

    //     //     let query = ref.orderBy('startDateTime');

    //     //     if(request.start){
    //     //         query = query.where('startDateTime', '>=', request.start);
    //     //     }

    //     //     if(request.end){
    //     //         query = query.where('startDateTime', '<=', request.end);
    //     //     }

    //     //     // if(request.start == null && request.end == null) {
    //     //     //     let min: firebase.firestore.Timestamp = new firebase.firestore.Timestamp(0, 0);
    //     //     //     query = query.where('startDateTime', '>=', min);
    //     //     // }

    //     //     query = query.where('companyId', '==', companyId);

    //     //     let parentIds = request.harvestIds.concat(request.groupIds);

    //     //     query = query.where('parentId', 'in', parentIds);

    //     //     if(request.phases?.length > 0){
    //     //         query = query.where('phase', 'in', request.phases);
    //     //     }

    //     //     if(request.teamIds?.length > 0){
    //     //         query = query.where('assignedTeamIds', 'in', request.teamIds);
    //     //     }

    //     //     if(request.userIds?.length > 0){
    //     //         query = query.where('assignedUserIds', 'in', request.userIds);
    //     //     }

    //     //     if(request.tags?.length > 0){
    //     //         query = query.where('tags', 'in', request.tags);
    //     //     }

    //     //     return query;
    //     // })
    //     // return response;
    // }

    getSplit(harvestId: string, collectionType: string = 'harvests'): CollectionReference {
        let companyId = this.helperService.currentCompanyId;
        let harvestResponse = collection(this.companiesRef, companyId, ...[collectionType, harvestId, 'events-split'])

        return harvestResponse;
    }

    async deleteSplit(event: models.Event, collectionType: string = 'harvests', extraEventsToDelete: Array<string> = []) {
        let companyId = this.helperService.currentCompanyId;

        let batch = writeBatch(this.firestore);

        let eventCol = collection(this.companiesRef, companyId, ...[collectionType, event.parentId, 'events-split'])
        let eventDoc = doc(eventCol, event.uid)

        batch.delete(eventDoc);
        
        extraEventsToDelete.forEach(id => {
            batch.delete(doc(eventCol, id));
        });

        await batch.commit()
    }

    //END Split functions


    getColGroup() {
        let companyId = this.helperService.currentCompanyId;
        let companyRef = doc(this.companiesRef, companyId);

        collectionGroup(this.firestore, 'events')
        
        const eventsCollection = collectionGroup(this.firestore, 'events');
        const q = query(eventsCollection, orderBy('__name__'), startAt(companyRef.path), endAt(companyRef.path + "\uf8ff"));
        return q;
    }

    get(harvestId: string, collectionType: string = 'harvests'): CollectionReference {
        let companyId = this.helperService.currentCompanyId;
        let harvestResponse = collection(this.companiesRef, companyId, ...[collectionType, harvestId, 'events'])

        return harvestResponse;
    }

    async getByHarvestIdList(harvestIds: Array<string>): Promise<Array<models.Event>> {
        let companyId = this.helperService.currentCompanyId;

        let response: Array<models.Event> = [];

        let requests: Promise<any>[] = [];
        for (let i = 0; i < harvestIds.length; i++) {
            const harvestId = harvestIds[i];
            let eventsCol = collection(this.companiesRef, companyId, ...['harvests', harvestId, 'events'])
            requests.push(getDocs(eventsCol));
            //let eventResponse = await getDocs(eventsCol);
        }

        let eventResponses = await Promise.all(requests);

        eventResponses.forEach(harvestEventList => {
            harvestEventList.forEach(eventData => {
                let dbEvent: models.DbEvent = eventData.data();
                dbEvent.uid = eventData.id;

                response.push(...dbEvent.list);
            })
        })

        // for (let i = 0; i < requests.length; i++) {
        //     let eventResponse = await requests[i];
        //     eventResponse.forEach(eventData => {
        //         let dbEvent: models.DbEvent = eventData.data();
        //         dbEvent.uid = eventData.id;

        //         response.push(...dbEvent.list);
        //     })
        // }

        return response;
    }

    async getByGroupIdList(groupIds: Array<string>): Promise<Array<models.Event>> {
        let companyId = this.helperService.currentCompanyId;

        let response: Array<models.Event> = [];

        let requests: Promise<any>[] = [];
        for (let i = 0; i < groupIds.length; i++) {
            const groupId = groupIds[i];
            let eventsCol = collection(this.companiesRef, companyId, ...['groups', groupId, 'events'])
            requests.push(getDocs(eventsCol));
            //let eventResponse = await getDocs(eventsCol);
        }

        let eventResponses = await Promise.all(requests);

        eventResponses.forEach(harvestEventList => {
            harvestEventList.forEach(eventData => {
                let dbEvent: models.DbEvent = eventData.data();
                dbEvent.uid = eventData.id;

                response.push(...dbEvent.list);
            })
        })

        // for (let i = 0; i < groupIds.length; i++) {
        //     const groupId = groupIds[i];
        //     let eventsCol = collection(this.companiesRef, companyId, ...['groups', groupId, 'events'])
        //     let eventResponse = await getDocs(eventsCol);

        //     eventResponse.forEach(eventData => {
        //         let dbEvent: models.DbEvent = eventData.data();
        //         dbEvent.uid = eventData.id;

        //         response.push(...dbEvent.list);
        //     })
        // }

        return response;
    }

    getFromGroup(groupId: string): CollectionReference {
        let companyId = this.helperService.currentCompanyId;
        let eventResponse = collection(this.companiesRef, companyId, ...['groups', groupId, 'events'])

        return eventResponse;
    }

    // async get(harvestId: string): Promise<any> {
    //     let eventResponse = await this.firestore.collection('harvests').doc(harvestId).collection('events').get();

    //     return eventResponse;
    // }

    async saveDiscussions(toGroup: boolean, parentId: string, eventId: string, discussions: Array<models.Discussion>) {
        let eventDataList = await getDocs(this.get(parentId, toGroup ? 'groups' : 'harvests'));
        for (let i = 0; i < eventDataList.docs.length; i++) {
            const eventData = eventDataList.docs[i];
            let dbEvent: models.DbEvent = eventData.data();
            dbEvent.uid = eventData.id;

            let index = dbEvent.list.findIndex(obj => obj.id === eventId);

            // Check if a matching object is found
            if (index !== -1) {
                // Replace the matching object with a new object
                dbEvent.list[index].discussions = discussions;
                await this.save(toGroup, parentId, dbEvent);
            }
        };
    }

    async save(toGroup: boolean, id: string, events: models.DbEvent) {
        events.list = this.helperService.removeEmptyFromList(events.list);
        events.list.forEach(event => {
            if(event.id == null){
                event.id = this.store.createId()
            }
        });
        if (toGroup) {
            await this.saveToDb("groups", id, events);
        } else {
            await this.saveToDb("harvests", id, events);
        }
    }

    async saveToDb(table: string, id: string, events: models.DbEvent) {
        let companyId = this.helperService.currentCompanyId;
        let dataToSave = {
            list: events.list
        }
        let eventDoc = doc(this.companiesRef, companyId, ...[table, id, 'events', events.uid])
        await setDoc(eventDoc, dataToSave)
    }

    async add(toGroup: boolean, id: string, events: Array<models.Event>) {
        events.forEach(event => {
            if(event.id == null){
                event.id = this.store.createId()
            }
        });
        if (toGroup) {
            events.forEach(event => {
                delete event.phase;
            });
            await this.addToDb("groups", id, events);
        } else {
            await this.addToDb("harvests", id, events);
        }
    }

    async addToDb(table: string, id: string, events: Array<models.Event>) {
        events = this.helperService.removeEmptyFromList(events);
        let companyId = this.helperService.currentCompanyId;

        let tableRef = doc(this.companiesRef, companyId, ...[table, id])
        let eventsCol = collection(tableRef, 'events')
        let snapshot = await getDocs(eventsCol);

        let eventObj: models.DbEvent;
        let eventId: string;
        snapshot.forEach(element => {
            eventObj = element.data();
            eventId = element.id;
        });

        let needsToAddList: boolean = false;
        if (eventObj == null) {
            needsToAddList = true;
            eventObj = {
                list: []
            }
        }

        eventObj.list = eventObj.list.concat(events);

        if (needsToAddList) {
            await addDoc(eventsCol, eventObj)
        } else {
            let uDoc = doc(eventsCol, eventId);
            await updateDoc(uDoc, { ...eventObj })
        }
    }

    async delete(toGroup: boolean, id: string, event: models.Event) {
        if (toGroup) {
            await this.deleteFromDb("groups", id, event);
        } else {
            await this.deleteFromDb("harvests", id, event);
        }
    }

    async deleteFromDb(table: string, id: string, event: models.Event) {
        let realUid: string = event.uid.split("::")[0];
        let index: number = parseInt(event.uid.split("::")[1]);

        let companyId = this.helperService.currentCompanyId;
        let harvestRef =  doc(this.companiesRef, companyId, ...[table, id]);

        let eventDoc = doc(harvestRef, 'events', ...[realUid]);

        let snapshot = await getDoc(eventDoc);
        let eventObj: models.DbEvent = snapshot.data();

        eventObj.list.splice(index, 1)

        await updateDoc(eventDoc, { ...eventObj });
        //harvestRef.collection("events").doc(realUid).update(eventObj);

        let attachments = event.discussions.filter(i => i.discussionType == models.DiscussionType.AttachedFile);
        for (let i = 0; i < attachments.length; i++) {
            const attachment = attachments[i];

            this.fileService.delete(attachment.content.fullPath);
        }
    }

    async assignUsersToEvents(
        harvestIds: Array<string>, 
        groupIds: Array<string>,
        userIds: Array<string>,
        teamIds: Array<string>,
        assign: boolean,
        startDate: Timestamp, endDate: Timestamp,
        phases: Array<string>,
        tags: Array<string>,
        taskType: string) {

        let companyId = this.helperService.currentCompanyId;

        let inObj: any = {
            companyId: companyId,
            harvestIds: harvestIds,
            groupIds: groupIds,
            userIds: userIds,
            teamIds: teamIds,
            assign: assign,
            startDate: startDate,
            endDate: endDate,
            phases: phases,
            tags: tags,
            taskType: taskType
        }

        let assignUsersToHarvestsAndGroupsMethod = httpsCallable(this.functions, 'assignUsersToHarvestsAndGroups');
        var assignUsersToHarvestsAndGroupsResponse = await assignUsersToHarvestsAndGroupsMethod(inObj);

        return assignUsersToHarvestsAndGroupsResponse;
    }

    async removeReferenceToAttachment(attachmentId: string) {
        let companyId = this.helperService.currentCompanyId;

        let harvestCol = collection(this.companiesRef, companyId, 'harvests');
        let query = await getDocs(harvestCol)
        
        query.forEach(async harvestRef => {
            let eventsCol = collection(harvestRef.ref, 'events')
            let listSS = await getDocs(eventsCol);

            listSS.forEach(async eventData => {
                let dbEvent: models.DbEvent = eventData.data();
                dbEvent.uid = eventData.id;

                let allAttachments = dbEvent.list.flatMap(i => i.attachments);

                if (!allAttachments.includes(attachmentId)) {
                    return;
                }
                dbEvent.list.forEach(event => {
                    if(event.attachments != null){
                        event.attachments = event.attachments.filter(i => i != attachmentId);
                    }
                });
                await updateDoc(eventData.ref, { ...dbEvent });
            })
        })

        // let query = await this.firestore.collectionGroup('events').get().toPromise();

        // query.docs.forEach(async doc => {
        //     let list: models.DbEvent = doc.data();
        //     let allAttachments = list.list.flatMap(i => i.attachments);

        //     if (!allAttachments.includes(attachmentId)) {
        //         return;
        //     }
        //     list.list.forEach(event => {
        //         if(event.attachments != null){
        //             event.attachments = event.attachments.filter(i => i != attachmentId);
        //         }
        //     });
        //     await doc.ref.update(list);
        // });
    }
}