import { NgModule, Pipe } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MaterialModule } from '../app.material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { MatSelectFilterModule } from 'mat-select-filter';
import { QuillModule } from 'ngx-quill'
import { NgxTippyModule } from 'ngx-tippy-wrapper';

import { ButtonsModule } from '../buttons/buttons.module'
import { PipeModule } from '../pipes/pipe.module';

// for FullCalendar!
import { FullCalendarModule } from '@fullcalendar/angular'; 

import { HeaderComponent } from './layout/header/header.component';
import { FooterComponent } from './layout/footer/footer.component';
import { NavbarComponent } from './layout/navbar/navbar.component';
import { SidebarComponent } from './layout/sidebar/sidebar.component';
import { CalendarComponent } from './calendar/calendar.component';
import { HarvestListComponent } from './harvest/harvest-list/harvest-list.component';
import { HarvestDetailComponent } from './harvest/harvest-detail/harvest-detail.component';
import { UserManagementComponent } from './user/user-management/user-management.component';

import { GridModule } from '../grid.module';

//TimeAgoPipe is not updated for angular 9 yet, this is a workaround
import { TimeAgoPipe } from 'time-ago-pipe';
//import { SafeHtmlPipe } from "../pipes/safeHtml.pipe";

import { DiscussionPanelComponent } from './discussion-panel/discussion-panel.component';
import { CompanyViewAndDropdownComponent } from './company/company-view-and-dropdown/company-view-and-dropdown.component';
import { UpgradeFabComponent } from './layout/upgrade-fab/upgrade-fab.component';
import { PhaseSelectComponent } from './phase/phase-select/phase-select.component';
import { CompanyManagementComponent } from './company/company-management/company-management.component';
import { NotificationPanelComponent } from './notification-panel/notification-panel.component';
import { UserListComponent } from './user/user-list/user-list.component';
import { TeamListComponent } from './user/team-list/team-list.component';
import { UserSelectMultipleComponent } from './user/user-select-multiple/user-select-multiple.component';
import { HarvestSelectMultipleComponent } from './harvest/harvest-select-multiple/harvest-select-multiple.component';
import { GroupSelectMultipleComponent } from './group/group-select-multiple/group-select-multiple.component';
import { TeamSelectMultipleComponent } from './user/team-select-multiple/team-select-multiple.component';
import { PhaseSelectMultipleComponent } from './phase/phase-select-multiple/phase-select-multiple.component';
import { SystemConfigsComponent } from './system-configs/system-configs.component';
import { CompanySettingsDetailsComponent } from './company/company-settings/company-settings-details/company-settings-details.component';
import { CompanySettingsWarehouseLayoutComponent } from './company/company-settings/company-settings-warehouse-layout/company-settings-warehouse-layout.component';
import { StrainSetupComponent } from './strains/strain-setup/strain-setup.component';
import { StrainSelectComponent } from './strains/strain-select/strain-select.component';
import { ZoneSetupComponent } from './zones/zone-setup/zone-setup.component';
import { StrainSelectMultipleComponent } from './strains/strain-select-multiple/strain-select-multiple.component';
import { ZoneIdentifiersComponent } from './zones/zone-identifiers/zone-identifiers.component';
import { ZoneIdentifiersDetailComponent } from './zones/zone-identifiers-detail/zone-identifiers-detail.component';
import { ChipListEditComponent } from './form/chip-list-edit/chip-list-edit.component';
import { MultipleSelectComponent } from './form/multiple-select/multiple-select.component';
import { StrainSelectMultipleForHarvestComponent } from './strains/strain-select-multiple-for-harvest/strain-select-multiple-for-harvest.component';
import { EventListComponent } from './event/event-list/event-list.component';
import { PhaseAccordionComponent } from './phase/phase-accordion/phase-accordion.component';
import { ZoneExplanationComponent } from './zones/zone-explanation/zone-explanation.component';
import { CalendarFiveComponent } from './calendar-five/calendar-five.component';
import { CalendarColGroupComponent } from './calendar-colGroup/calendar-colGroup.component';
import { TaskGroupListComponent } from './task-group/task-group-list/task-group-list.component';
import { TaskGroupDetailComponent } from './task-group/task-group-detail/task-group-detail.component';
import { PostHarvestDataPointsComponent } from './post-harvest/post-harvest-data-points/post-harvest-data-points.component';
import { PostHarvestDataComponent } from './harvest/post-harvest-data/post-harvest-data.component';
import { SensorSetupComponent } from './sensors/sensor-setup/sensor-setup.component';
import { AutocompleteComponent } from './form/autocomplete/autocomplete.component';
import { SensorsSelectMultipleComponent } from './sensors/sensors-select-multiple/sensors-select-multiple.component';
import { CheckboxHeaderComponent } from './grid/header-checkbox.component';
import { HarvestSelectComponent } from './harvest/harvest-select/harvest-select.component';
import { GroupSelectComponent } from './group/group-select/group-select.component';
import { ZoneSelectComponent } from './zones/zone-select/zone-select.component';
import { HarvestPhaseTimelineComponent } from './harvest/harvest-phase-timeline/harvest-phase-timeline.component';
import { TimelineComponent } from './timeline/timeline.component';
import { GoogleChartsModule } from 'angular-google-charts';
import { MessagingComponent } from './messaging/messaging.component';
import { TemplateListComponent } from './template/template-list/template-list.component';
import { TemplateDetailComponent } from './template/template-detail/template-detail.component';
import { TemplateStepListComponent } from './template/template-step-list/template-step-list.component';
import { FilterSectionComponent } from './filter-section/filter-section.component';
import { StopwatchPopupComponent } from './layout/stopwatch-popup/stopwatch-popup.component';
import { CalendarSplitComponent } from './calendar-split/calendar-split.component';
import { TemplateGridComponent } from './template/template-grid/template-grid.component';
import { WorkflowSelectComponent } from './workflow/workflow-select/workflow-select.component';
import { AnalyticsComponent } from './analytics/analytics/analytics.component';
import { EventsCompletedStackedChartComponent } from './charts/events-completed-stacked-chart/events-completed-stacked-chart.component';
import { ChartDaterangeFilterComponent } from './chart-filters/chart-daterange-filter/chart-daterange-filter.component';
import { ChartIntervalFilterComponent } from './chart-filters/chart-interval-filter/chart-interval-filter.component';
import { UsersTeamsPerformanceChartComponent } from './charts/users-teams-performance-chart/users-teams-performance-chart.component';
import { CompanySettingsNotificationsComponent } from './company/company-settings/company-settings-notifications/company-settings-notifications.component';
import { CompanySettingsDutchieComponent } from './company/company-settings/company-settings-dutchie/company-settings-dutchie.component';
import { CompanySettingsSecurityComponent } from './company/company-settings/company-settings-security/company-settings-security.component';
import { LoadingPanelComponent } from './layout/loading-panel/loading-panel.component';
import { AlertsIconComponent } from './layout/alerts-icon/alerts-icon.component';
import { MentionTextboxComponent } from './form/mention-textbox/mention-textbox.component';
import { UsersActualAndEstimatedTimeChartComponent } from './charts/users-actual-and-estimated-time-chart/users-actual-and-estimated-time-chart.component';
import { EventVolumeComboChartComponent } from './charts/event-volume-combo-chart/event-volume-combo-chart.component';
import { PerformanceAndLaborLoadAnalyticsComponent } from './performance-and-laborLoad-analytics/performance-and-laborLoad-analytics.component';
import { TemplateSelectMultipleComponent } from './template/template-select-multiple/template-select-multiple.component';
import { ColorPickerComponent } from './form/color-picker/color-picker.component';
import { TaskLibraryListComponent } from './task-library/task-library-list/task-library-list.component';
import { TaskLibraryEditorComponent } from './task-library/task-library-editor/task-library-editor.component';
import { TemplatedTaskSelectComponent } from './task-library/templated-task-select/templated-task-select.component';
import { DataLibrarySetupComponent } from './data-library/data-library-setup/data-library-setup.component';
import { UserSelectComponent } from './user/user-select/user-select.component';
import { BulkEditBtnsComponent } from './form/bulk-edit-btns/bulk-edit-btns.component';
import { TimeInputComponent } from './form/time-input/time-input.component';
import { CompanySelectMultipleComponent } from './company/company-select-multiple/company-select-multiple.component';
import { WorkflowListComponent } from './workflow/workflow-list/workflow-list.component';
import { DataSubmissionAnalyticsComponent } from './analytics/data-submission-analytics/data-submission-analytics.component';
import { ExpandableComponent } from './form/expandable/expandable.component';
import { DataSubmissionAnalyticsGridComponent } from './analytics/data-submission-analytics-grid/data-submission-analytics-grid.component';

@Pipe({
    name: 'timeAgo',
    pure: false
})
export class TimeAgoExtendsPipe extends TimeAgoPipe {}

const components = [
  HeaderComponent,
  FooterComponent,
  NavbarComponent,
  SidebarComponent,
  CalendarComponent,
  HarvestListComponent,
  HarvestDetailComponent,
  UserManagementComponent,
  DiscussionPanelComponent,
  CompanyViewAndDropdownComponent,
  UpgradeFabComponent,
  PhaseSelectComponent,
  CompanyManagementComponent,
  NotificationPanelComponent,
  UserListComponent,
  UserSelectMultipleComponent,
  TeamListComponent,
  HarvestSelectMultipleComponent,
  GroupSelectMultipleComponent,
  TeamSelectMultipleComponent,
  PhaseSelectMultipleComponent,
  SystemConfigsComponent,
  CompanySettingsDetailsComponent,
  CompanySettingsWarehouseLayoutComponent,
  StrainSetupComponent,
  StrainSelectComponent,
  ZoneSetupComponent,
  StrainSelectMultipleComponent,
  ZoneIdentifiersComponent,
  ZoneIdentifiersDetailComponent,
  ChipListEditComponent,
  MultipleSelectComponent,
  StrainSelectMultipleForHarvestComponent,
  EventListComponent,
  PhaseAccordionComponent,
  ZoneExplanationComponent,
  CalendarFiveComponent,
  CalendarColGroupComponent,
  TaskGroupListComponent,
  TaskGroupDetailComponent,
  PostHarvestDataPointsComponent,
  PostHarvestDataComponent,
  SensorSetupComponent,
  AutocompleteComponent,
  SensorsSelectMultipleComponent,
  CheckboxHeaderComponent,
  HarvestSelectComponent,
  GroupSelectComponent,
  ZoneSelectComponent,
  HarvestPhaseTimelineComponent,
  TimelineComponent,
  MessagingComponent,
  TemplateListComponent,
  TemplateDetailComponent,
  TemplateStepListComponent,
  FilterSectionComponent,
  StopwatchPopupComponent,
  CalendarSplitComponent,
  WorkflowSelectComponent,
  AnalyticsComponent,
  CompanySettingsNotificationsComponent,
  CompanySettingsDutchieComponent,
  CompanySettingsSecurityComponent,
  TemplateGridComponent,
  LoadingPanelComponent,
  AlertsIconComponent,
  MentionTextboxComponent,
  TemplateGridComponent,
  EventsCompletedStackedChartComponent,
  ChartDaterangeFilterComponent,
  ChartIntervalFilterComponent,
  UsersTeamsPerformanceChartComponent,
  TemplateSelectMultipleComponent,
  ColorPickerComponent,
  TaskLibraryListComponent,
  TaskLibraryEditorComponent,
  TemplatedTaskSelectComponent,
  DataLibrarySetupComponent,
  UserSelectComponent,
  BulkEditBtnsComponent,
  TimeInputComponent,
  CompanySelectMultipleComponent,
  WorkflowListComponent,
  DataSubmissionAnalyticsComponent,
  ExpandableComponent,
  DataSubmissionAnalyticsGridComponent,
]

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FullCalendarModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    GridModule,
    ButtonsModule,
    PipeModule,
    NgxMatSelectSearchModule,
    MatSelectFilterModule,
    NgxTippyModule,
    GoogleChartsModule,
    QuillModule,
  ],
  declarations: [
    //TimeAgoPipe,
    TimeAgoExtendsPipe,
    //SafeHtmlPipe,
    ...components,
    UsersActualAndEstimatedTimeChartComponent,
    EventVolumeComboChartComponent,
    PerformanceAndLaborLoadAnalyticsComponent,
  ],
  exports: [
    //SafeHtmlPipe,
    ...components,
  ]
})
export class ComponentsModule { }
