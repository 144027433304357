import { Component, Input, OnInit } from '@angular/core';
import { GridOptions } from 'ag-grid-community';

import * as viewmodels from 'app/viewmodels';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'app-data-submission-analytics-grid',
  templateUrl: './data-submission-analytics-grid.component.html',
  styleUrls: ['./data-submission-analytics-grid.component.scss']
})
export class DataSubmissionAnalyticsGridComponent implements OnInit {

  @Input() report: viewmodels.ReportDefinition;
  @Input() columnDefs: any[];
  @Input() rowData: any[];

  public gridOptions: GridOptions;
  
  constructor(
    private deviceService: DeviceDetectorService
  ) { 

  }

  ngOnInit(): void {
    this.setGridOptions();
  }

  setGridOptions() {
    this.gridOptions = <GridOptions>{
      context: {
          componentParent: this
      },
      //rowData: this.rowData,
      columnDefs: this.report.columnDefs,
      rowHeight: 35,
      onGridReady: () => {
        if(!this.deviceService.isMobile()){
          this.gridOptions.api.sizeColumnsToFit();
        }
      },
      frameworkComponents: {
      },
      rowSelection: 'none',
      enableCellTextSelection: true,
      defaultColDef: {
        sortable: true,
      },
      // onRowDoubleClicked: (event: RowDoubleClickedEvent) => {
      //   this.editSensor(event.data);
      // }
    };
  }

}
