<div class="row">
    <div class="col-12">
        <div class="buttons">
            <app-harvest-select-multi-btn
                class="filter-btn" 
                [showUpcomingHarvests]="false"
                [appStorageNeeded]="false"
                [startWithNoneSelected]="false"
                [(selected)]="harvestIds"
                [(selectedHarvests)]="harvests" 
                (onClosed)="filter()"
                (loaded)="filter()">
            </app-harvest-select-multi-btn>
    
            <app-task-group-select-multi-btn 
                class="filter-btn" 
                [startWithNoneSelected]="false"
                [(selected)]="groupIds"
                [(selectedGroups)]="groups" 
                (onClosed)="filter()" 
                (loaded)="filter()">
            </app-task-group-select-multi-btn>
    
            <app-user-select-multi-btn
                class="filter-btn"
                [(selected)]="userIds"
                [(selectedUsers)]="selectedUsers">
            </app-user-select-multi-btn>
    
            <app-date-filters-btn
                class="filter-btn"
                [(selectedDateRange)]="dateRange"
                (onClosed)="filter()">
            </app-date-filters-btn>
    
            <!-- <app-single-select-dropdown-btn
                class="filter-btn"
                [label]="'Templated Task'"
                [items]="templatedTaskItems"
                [(selectedItem)]="selectedTaskTemplate"
                (onClosed)="setDataCols()">
            </app-single-select-dropdown-btn> -->
    
            <!-- <app-multi-select-dropdown-btn
                class="filter-btn"
                [label]="'Task Types'"
                [items]="templatedTaskItems"
                [(selectedItems)]="selectedTaskTemplates">
            </app-multi-select-dropdown-btn> -->

            <app-task-type-select-multiple-btn
                class="filter-btn"
                [(selected)]="selectedTaskTemplateIds"
                [(selectedUsers)]="selectedTaskTemplates">
            </app-task-type-select-multiple-btn>
    
            <!-- <app-multi-select-btn 
                class="filter-btn"
                [label]="'Task Types'"
                [list]="templatedTaskItems"
                [selected]="selectedTaskTemplates"
                (onClosed)="taskTypesClosedEvent($event)">
            </app-multi-select-btn> -->

            <!-- <app-multi-select-dropdown-btn
                class="filter-btn"
                [label]="'Data Collection Definition'"
                [items]="dataCollectionDefinitionTasks"
                [(selectedItems)]="selectedDataCollectionDefinitions"
                (onClosed)="clearTemplatedTask()">
            </app-multi-select-dropdown-btn> -->
    
            <!-- <mat-form-field style="width: 150px; display: inline-block !important;" appearance="outline">
                <mat-select
                    [(ngModel)]="selectedTaskTemplate" 
                    (ngModelChange)="filter()" >
                    <mat-option *ngFor="let templatedTask of templatedTasks" [value]="templatedTask">
                        {{ templatedTask.name }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
    
            <mat-form-field style="width: 150px; display: inline-block !important;" appearance="outline">
                <mat-select
                    [(ngModel)]="selectedDataCollectionDefinitions" 
                    (ngModelChange)="filter()" multiple>
                    <mat-option *ngFor="let dataCollectionDefinition of dataCollectionDefinitions" [value]="dataCollectionDefinition">
                        {{ dataCollectionDefinition.name }} ({{ dataCollectionDefinition.unit }})
                    </mat-option>
                </mat-select>
            </mat-form-field> -->
            <div class="filler"></div>
    
            <button mat-raised-button style="flex: right" class="filter-btn" [disabled]="running" (click)="runReport()">
                <span *ngIf="!running">Run Report</span>
                <mat-icon *ngIf="running"><mat-spinner diameter=25 strokeWidth=4></mat-spinner></mat-icon>
                
            </button>
            <button mat-raised-button *ngIf="displayReport" class="filter-btn" [disabled]="running" [matMenuTriggerFor]="exportMenu">
                Export
                <mat-icon>arrow_drop_down</mat-icon>
            </button>

            <mat-menu #exportMenu="matMenu">
                <button mat-menu-item (click)="export(true)">
                    <span>Combined Export</span>
                </button>
                <button mat-menu-item (click)="export(false)">
                    <span>Multiple Exports</span>
                </button>
            </mat-menu>
        </div>

        <div class="row" style="padding-top: 10px;">
            <div class="col-12">
                <mat-card>
                    <mat-card-content>
                        <app-expandable [title]="'Harvests'" [content]="harvestSummary"></app-expandable>
                        <app-expandable [title]="'Task Groups'" [content]="groupSummary"></app-expandable>
                        <app-expandable [title]="'Users'" [content]="userSummary"></app-expandable>
                        <app-expandable [title]="'Duration'" [content]="duration"></app-expandable>
                        
                        <app-expandable [title]="'Task Types'" [content]="taskTypesSummary"></app-expandable>

                        <!-- <div class="filter-summary-item" *ngIf="selectedTaskTemplate != null">
                            <span><b style="font-weight: bolder;">Templated Task:</b> {{selectedTaskTemplate?.name}} </span>
                        </div>
                        <div class="filter-summary-item" *ngIf="selectedTaskTemplate == null">
                            <span><b style="font-weight: bolder;">Data Definitions:</b></span>
                        </div>
                        <ul style="list-style-type: circle;">
                            <li class="indent" *ngFor="let def of selectedDataCollectionDefinitions"> - {{def.name}}</li>
                        </ul> -->
                    </mat-card-content>
                </mat-card>
            </div>
        </div>
    </div>
</div>
<!-- <p *ngFor="let id of harvestIds">{{id}}</p>
<p *ngFor="let id of groupIds">{{id}}</p>
<p>{{dateRange?.startDate}}</p>
<p>{{dateRange?.endDate}}</p>
<p>{{selectedTaskTemplate?.name}}</p>
<p *ngFor="let id of selectedDataCollectionDefinitions">{{id}}</p> -->

<!-- <div class="row" style="margin-top: 10px;" *ngIf="displayReport">
    <div class="col-12">
        <mat-tab-group>
            <mat-tab *ngFor="let report of reports" [label]="report.name">
                <div class="tab-content">
                    <app-data-submission-analytics-grid
                        [report]="report">
                    </app-data-submission-analytics-grid>
                </div>
            </mat-tab>
        </mat-tab-group>
    </div>
</div> -->

<!-- Show loading state -->
<div *ngIf="running" class="loading-indicator">
  Loading...
</div>

<!-- Show tabs only when we have data -->
<mat-tab-group 
  *ngIf="displayTabs && reports.length > 0"
  [(selectedIndex)]="activeTabIndex"
  class="report-tabs">
  
  <mat-tab *ngFor="let report of reports" [label]="report.task.name">
    <ng-template matTabContent>
      <!-- Debug output -->
      <div *ngIf="!report.dataSubmissions?.length" class="no-data-message">
        No data available for this report
      </div>
      
      <!-- Grid component -->
      <app-data-submission-analytics-grid
        *ngIf="report.dataSubmissions?.length"
        [report]="report"
        [columnDefs]="report.columnDefs"
        [rowData]="report.dataSubmissions">
      </app-data-submission-analytics-grid>
    </ng-template>
  </mat-tab>
</mat-tab-group>

<!-- Show message if no data -->
<!-- <div *ngIf="displayReport && !hasReportData" class="no-data-message">
  No data found for the selected criteria
</div> -->